import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import firebase, { auth } from "services/firebase";
import { getIsSignedIn } from "store/reducers";
import { signOut } from "firebase/auth";
import { resetState } from "store/actions/profile";
class SignOut extends React.Component {
  componentDidMount() {
    signOut(auth);
    this.verifySession();
  }

  componentDidUpdate() {
    this.verifySession();
  }

  verifySession = () => {
    const { signedIn, navigate } = this.props;
    this.props.resetState();
    // go home
    if (!signedIn) navigate("/auth/signin");
  };

  render() {
    return <h1>Signing you out now...</h1>;
  }
}

const mapStateToProps = (state) => ({
  signedIn: getIsSignedIn(state),
});

const mapDispatchToProps = { resetState };
export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
