import { handlePromise } from "./data";

export const LOAD_WARRANTY_PLANS = "LOAD_WARRANTY_PLANS";
export const LOAD_WARRANTY_CLAIMS = "LOAD_WARRANTY_CLAIMS";
export const RESET_STATE = "RESET_STATE";

export const loadWarrantyPlans = (promise) => (dispatch) =>
  handlePromise(
    LOAD_WARRANTY_PLANS,
    promise,
    `unable to load user warranty plans`,
    dispatch
  );

export const loadWarrantyClaims = (promise) => (dispatch) =>
  handlePromise(
    LOAD_WARRANTY_CLAIMS,
    promise,
    `unable to load user warranty claims`,
    dispatch
  );

export const resetState = () => ({
  type: RESET_STATE,
});
