import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      wait: true,
    },

    returnObjects: true,

    // Language Detection
    lookupQuerystring: "lng",
    lookupCookie: "i18next",
    lookupLocalStorage: "i18nextLng",
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ["localStorage", "cookie"],

    whitelist: ["en", "es"],

    defaultNS: "common",
    ns: ["claims", "common", "home", "plans", "services", "maintenance"],

    resources: {
      en: {
        claims: require("translations/en/claims").default,
        common: require("translations/en/common").default,
        home: require("translations/en/home").default,
        plans: require("translations/en/plans").default,
        services: require("translations/en/services").default,
        homeowner: require("translations/en/homeowner").default,
        maintenance: require("translations/en/maintenance").default,
      },
      es: {
        claims: require("translations/es/claims").default,
        common: require("translations/es/common").default,
        home: require("translations/es/home").default,
        plans: require("translations/es/plans").default,
        services: require("translations/es/services").default,
        homeowner: require("translations/es/homeowner").default,
        maintenance: require("translations/es/maintenance").default,
      },
    },
  });

export default i18n;
