export default {
  motto: `We've Got Your Back`,
  why: "Why Choose SilverBack?",
  action1: "Stop paying for expensive home repairs out-of-pocket.",
  action2: "Get a SilverBack Home Warranty today!",
  comparePlans: "Compare Plans",

  getStartedTitle: "Unexpected Breakdowns Will Happen",
  getStartedCTA: "Get Your Home Protected Now",

  benefits: [
    {
      title: "Peace of Mind",
      description: `
        You know major systems and appliances will eventually break in your home;
        with SilverBack Home Warranty coverage, we've got your back and give you
        peace of mind.
      `,
    },
    {
      title: "24/7 Emergency Claim Service",
      description: `
        Always there for you! Need an emergency line to report a serious
        breakdown? We've got that too.
      `,
    },
    {
      title: "No age restrictions on appliances & systems covered",
      description: `
        Some homes and appliances are new and some are old; we cover them all
      `,
    },
    {
      title: "Protection against unknown",
      description: `
        SilverBack Home Warranty provides coverage for undetectable pre-existing
        conditions that through a visual inspection or mechanical test could not
        have been detected.
      `,
    },
    {
      title: "FREE Listing Coverage",
      description: `
        Sellers need coverage too and we offer free listing coverage during the
        listing period.
      `,
    },
    {
      title: "Repair or Replace",
      description: `
      We offer repair or replacement services
        for 12 months at a time for a low service call fee on covered systems and
        components. See details of coverage in the service contract.
      `,
    },
    {
      title: "Re-Key Service",
      description: `
        Have 4 locks Re-keyed for a low service call fee.
      `,
    },
    {
      title: "Top Notch Service Professionals",
      description: `
        Our Service Network of Professional Contractors are insured, licensed and do
        incredible work!
      `,
    },
  ],
};
