import React from "react";
import Typography from "@mui/material/Typography";
import { Field } from "redux-form";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { withTranslation } from "react-i18next";

import { isRequired } from "helpers/validators";
import {
  renderTextField,
  renderSelectField,
  renderRadioGroup,
  renderDatePicker,
} from "helpers/form";
import * as Styled from "./styled-components";
import { StreetFieldAuto } from "helpers/autocomplete";

class PropertyDetails extends React.Component {
  state = { show: false, size: 0 };
  render() {
    const { t, isListingCoverage, optionTotal, isLimited, change } = this.props;
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const bigHouseOptions = [
      { value: "false", label: "No" },
      { value: "true", label: "Yes" },
    ];
    const bigHouseSize = [
      {
        value: 1,
        label: "6000 - 7000 Sq.Ft",
        disabled: isLimited ? (optionTotal > 85 * 1 ? false : true) : false,
      },
      {
        value: 2,
        label: "7000 - 8000 Sq.Ft",
        disabled: isLimited ? (optionTotal > 85 * 2 ? false : true) : false,
      },
      {
        value: 3,
        label: "8000 - 9000 Sq.Ft",
        disabled: isLimited ? (optionTotal > 85 * 3 ? false : true) : false,
      },
      {
        value: 4,
        label: "9000 - 10000 Sq.Ft",
        disabled: isLimited ? (optionTotal > 85 * 4 ? false : true) : false,
      },
      {
        value: 5,
        label: "10000+ Sq.Ft",
        disabled: isLimited ? (optionTotal > 85 * 5 ? false : true) : false,
      },
    ];
    const handleOnChange = (event, value) => {
      this.setState((prevState) => ({
        show: !prevState.show,
      }));
      this.props.onhomesizechange(0);
    };

    const getHouseSize = (event, index) => {
      this.props.onhomesizechange(bigHouseSize[index - 1].value);
      this.setState({ size: bigHouseSize[index - 1].value });
    };

    const focusOut = (value) => {
      change("street", value);
    };
    return (
      <Styled.FormGroup>
        <Typography
          className="label"
          color="primary"
          align="left"
          variant="subtitle"
          sx={{
            fontFamily: "'PT Sans Narrow', 'Roboto', 'sans-serif'",
            fontSize: "24px",
          }}
        >
          {t("plans:propertyDetails")}
        </Typography>

        <Divider />

        <Grid container sx={{ margin: "-4px" }}>
          <Grid item sm={10} xs={12} style={{ padding: "4px" }}>
            <Field
              name="street"
              label={t("street")}
              focusOut={focusOut}
              change={change}
              validate={isRequired}
              component={StreetFieldAuto}
            />
          </Grid>

          {/* <Grid item sm={10} xs={12}>
            <StreetField
              name="street"
              label={t('street')}
              focusOut={focusOut}
              change={change}
              isStreetError={this.props.isStreetError}
              validate={isRequired}
            />
          </Grid> */}
          {/* <Grid item sm={10} xs={12}> */}
          {/* <Field name="street" label={t('street')} component={renderTextField} validate={isRequired} /> */}
          {/* </Grid> */}
          <Grid item sm={2} xs={12} style={{ padding: "4px" }}>
            <Field name="unit" label={t("unit")} component={renderTextField} />
          </Grid>
          <Grid item sm={4} xs={12} style={{ padding: "4px" }}>
            <Field
              name="city"
              label={t("city")}
              component={renderTextField}
              validate={isRequired}
            />
          </Grid>
          <Grid item sm={4} xs={6} style={{ padding: "4px" }}>
            <Field
              name="state"
              label={t("state")}
              component={renderTextField}
              validate={isRequired}
            />
          </Grid>
          <Grid item sm={4} xs={6} style={{ padding: "4px" }}>
            <Field
              name="postal"
              label={t("postal")}
              component={renderTextField}
              validate={isRequired}
            />
          </Grid>
          {width >= 600 && (
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={7}>
                  <Typography
                    className="label"
                    color="secondary"
                    align="left"
                    variant="caption"
                    sx={{
                      fontSize: "22px !important",
                      lineHeight: "1.375em",
                    }}
                  >
                    {t("plans:bigHouse")}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Field
                    name="bigHouse"
                    component={renderRadioGroup}
                    validate={isRequired}
                    className="flex-row"
                    options={bigHouseOptions}
                    onChange={(e, newValue) => handleOnChange(e, newValue)}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {width < 600 && (
            <Grid item xs={12}>
              <Field
                name="bigHouse"
                label={t("plans:bigHouse")}
                options={bigHouseOptions}
                component={renderSelectField}
                validate={isRequired}
              />
            </Grid>
          )}

          {!isListingCoverage && (
            <Grid item xs={12} sm={6}>
              <Field
                name="closingDate"
                label={t("plans:estClosingDate")}
                component={renderDatePicker}
                validate={!isListingCoverage ? isRequired : null}
                // InputLabelProps={{
                //   shrink: true
                // }}
              />
            </Grid>
          )}
          {this.state.show ? (
            <Grid item sm={6}>
              <Grid container spacing={1}>
                <Grid item sm={7}>
                  <Typography className="label" color="secondary" align="left">
                    {t("plans:homeSize")}
                  </Typography>
                </Grid>
                <Grid item sm={5}>
                  <Field
                    name="houseSize"
                    key="houseSize"
                    label={t("plans:selectHouseSize")}
                    component={renderSelectField}
                    options={bigHouseSize}
                    validate={isRequired}
                    onChange={(event, index) => getHouseSize(event, index)}
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Styled.FormGroup>
    );
  }
}

export default withTranslation(["common", "plans"])(PropertyDetails);
