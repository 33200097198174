import { combineReducers } from "redux";
import { reducer as formReducer, getFormValues } from "redux-form";
import { createSelector } from "reselect";

import cart from "services/shopping-cart";
import utils from "helpers/utils";
import {
  reducePlanAddons,
  reducePlanFeatures,
  calculatePlanTotal,
} from "helpers/orders";

import app, * as fromApp from "./app";
import data, * as fromData from "./data";
import email from "./email";
import auth, * as fromAuth from "./auth";
import consumer, * as fromConsumer from "./consumer";
import profile from "./profile";

export default combineReducers({
  form: formReducer,
  app,
  auth,
  consumer,
  data,
  email,
  profile,
});

// selectors
export const getIsSignedIn = (state) => fromAuth.isSignedIn(state.auth);
export const getUserDisplayName = (state) =>
  fromAuth.getDisplayName(state.auth);
export const getUserEmail = (state) => fromAuth.getEmail(state.auth);
export const getUserPhone = (state) => fromAuth.getPhoneNumber(state.auth);

export const getLoadCount = (state) => fromApp.getLoadCount(state.app);
export const getIsLoading = (state) => getLoadCount(state) > 0;
export const getErrors = (state) => fromApp.getErrors(state.app);
export const getAgents = (state) => fromData.getAgents(state.data);
export const getPreferredPlan = (state) =>
  fromData.getPreferredPlan(state.data);
export const getPreferredAmount = (state) => fromData.getPlanTotal(state.data);
export const getIsListingCoverage = (state) =>
  getPreferredPlan(state) === "listing-coverage";

export const getConsumerPlanFeatures = (state) =>
  fromConsumer.getPlanFeatures(state.consumer);
export const getConsumerPlanOptions = (state) =>
  fromConsumer.getPlanOptions(state.consumer);
export const getConsumerPlanAddons = (state) =>
  fromConsumer.getPlanAddons(state.consumer);
export const getConsumerOrder = (state) =>
  fromConsumer.getOrder(state.consumer);
export const getServiceCallFee = (state) =>
  getConsumerOrder(state).serviceCallFee;
export const getplantype = (state) => fromConsumer.getPlantype(state.consumer);

// Reselectors

export const getPlan = () => {
  const { plan } = cart;
  return plan;
};

export const getOrderFormInitialValues = () => {
  const { plan, address } = cart;
  const planOption =
    plan.name === "variable" ? utils.findPlanMatch(plan.amount) : plan.name;

  return {
    ...address,
    planOption,
    bigHouse: "false",
  };
};

export const getPlanTotal = createSelector(
  getFormValues("orders"),
  (formValues) => {
    if (!formValues) return 0;
    const { planOption } = formValues;
    return planOption ? utils.getPlanAmount(planOption) : 0;
  }
);

export const getPlanOptionTotal = createSelector(
  getFormValues("orders"),
  (values) => {
    // console.log(values);
    const options = typeof values === "undefined" ? [] : values.addons || [];
    return utils.getAddonTotal(options, values);
  }
);

export const getRenewalPrice = createSelector(
  getConsumerPlanOptions,
  getConsumerPlanAddons,
  getFormValues("homeowner-plan-addons"),
  (plans, addons, selectedAddons) => {
    if (!plans || !plans.length) return {};
    // don't show total for custom builds until there are feature totals
    // so they can't game the system
    const planAddonTotal = reducePlanAddons(selectedAddons, addons);
    return {
      annual: planAddonTotal,
      // No $5 premium for monthly option - policy
      monthly: parseInt((planAddonTotal / 12).toFixed(2), 10),
    };
  }
);

export const getConsumerPlanTotal = createSelector(
  getConsumerPlanOptions,
  getConsumerPlanFeatures,
  getConsumerPlanAddons,
  getConsumerOrder,
  getFormValues("homeowner-custom-plan"),
  getFormValues("homeowner-plan-addons"),
  getFormValues("homeowner-property-info"),
  (
    plans,
    features,
    addons,
    order,
    selectedFeatures,
    selectedAddons,
    propertyInfo = {}
  ) => {
    if (!plans || !plans.length) return {};

    const planBase = plans[order.planIndex].price;
    const planFeatureTotal = reducePlanFeatures(
      selectedFeatures,
      features,
      planBase
    );

    // don't show total for custom builds until there are feature totals
    // so they can't game the system
    if (order.planIndex > 0 && planFeatureTotal === 0) return {};

    const planAddonTotal = reducePlanAddons(selectedAddons, addons);
    let squareFootage =
      propertyInfo.bigHouse === true ? propertyInfo.squareFootage : 0;
    const planTotal = calculatePlanTotal(
      planBase,
      planFeatureTotal,
      planAddonTotal,
      order.serviceCallFee,
      squareFootage
    );
    console.log(
      planBase,
      planAddonTotal,
      planFeatureTotal,
      "plantotals782",
      selectedFeatures,
      addons
    );
    return {
      annual: planTotal,
      // $5 premium for monthly option
      monthly: (planTotal / 12 + 5).toFixed(2),
    };
  }
);

export const getConsumerFeatureCount = createSelector(
  getConsumerOrder,
  getFormValues("homeowner-custom-plan"),
  (order, features = {}) => {
    if (!order || order.planIndex === 0) return 10;

    return Object.keys(features).map((key) => features[key]).length;
  }
);
export const getListingCoverage = () => {
  return (
    new URLSearchParams(window.location.search).get("plan") ===
    "listing-coverage"
  );
};

export const getConsumerOrderSummary = createSelector(
  getConsumerOrder,
  getConsumerPlanTotal,
  getFormValues("homeowner-custom-plan"),
  getFormValues("homeowner-plan-addons"),
  getFormValues("homeowner-property-info"),
  getConsumerPlanTotal,
  (order, total, features = {}, addons = {}, property = {}) => {
    const selectedAddons = Object.keys(addons).filter((key) => addons[key]);
    const selectedFeatures = Object.keys(features).filter(
      (key) => features[key]
    );

    const summary = {
      plan: order.planName === "kingkong" ? "King Kong" : "Custom",
      total,
      serviceCallFee: order.serviceCallFee,
      addons: selectedAddons,
      property,
    };

    if (order.planName !== "kingkong") {
      summary.features = selectedFeatures;
    }

    return summary;
  }
);
