import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Hide from "@mui/material/Hidden";
import { withTranslation } from "react-i18next";
import styles from "../../agent.module.css";
import * as Styled from "./styled";

const OrderTotal = ({ t, planTotal, optionTotal, discount }) => (
  <Styled.Wrapper>
    <Grid container className={styles.common} style={{ margin: "-8px" }}>
      <Hide smDown>
        <Grid item sm={2} style={{ padding: "8px" }} className="Hidden600">
          <Typography color="textSecondary" variant="h3">
            {`${t("totalDue")}:`}
          </Typography>
        </Grid>
      </Hide>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("plan")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${planTotal}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={1} className="center" style={{ padding: "8px" }}>
        <Typography className="header" variant="h5" color="textSecondary">
          +
        </Typography>
      </Grid>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("options")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${optionTotal}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={1} className="center" style={{ padding: "8px" }}>
        <Typography className="header" variant="h5" color="textSecondary">
          -
        </Typography>
      </Grid>
      <Grid item sm={1} xs={4} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("applyCoupon")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${discount}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography className="header" variant="h5" color="textSecondary">
          =
        </Typography>
      </Grid>
      <Grid item sm={2} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("total")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${planTotal + optionTotal - discount}
        </Typography>
      </Grid>
    </Grid>
  </Styled.Wrapper>
);

export default withTranslation("common")(OrderTotal);
