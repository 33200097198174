import styled from '@emotion/styled'

export const Wrapper = styled('div')({}, ({ theme }) => ({
  width: '100%',
  background: 'rgba(255, 255, 255, 0.75)',
  padding: `20px 30px`,
  borderRadius: 5,
  boxShadow: theme.shadows[2],

  '& .contact-field': {
    margin: `10px 0`,
  },
}));
