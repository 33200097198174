import { FUNCTION_API, PaymentApi, QUICKBOOKS_API } from "constants/firebase";
import firebase, { auth } from "./firebase";
import { getAuth } from "firebase/auth";
import { config } from "constants/firebase";
import { initializeApp, getApps } from "firebase/app";

export default class PaymentService {
  static createToken({
    expYear,
    expMonth,
    number,
    name,
    cvc,
    streetAddress,
    city,
    region,
    postalCode,
  }) {
    const body = {
      card: {
        name,
        cvc,
        number,
        expYear,
        expMonth,
        address: {
          region,
          postalCode,
          streetAddress,
          city,
          country: "US",
        },
      },
    };

    return fetch(`${QUICKBOOKS_API}/quickbooks/v4/payments/tokens`, {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  }

  static charge({ token, amount, description, recurring }) {
    const url = `${PaymentApi}/charge/`;
    const body = JSON.stringify({
      currency: "USD",
      amount: amount || 1000,
      token,
      recurring,
      description,
    });
    // initializeApp(config);
    const auth = getAuth(firebase);
    // const auth = getAuth();
    const currentUser = auth.currentUser;
    if (currentUser) {
      return auth.currentUser
        .getIdToken(true)
        .then((idToken) => {
          console.log("calling create payment api::::::", idToken);
          return fetch(url, {
            method: "POST",
            headers: {
              Authorization: idToken,
              "Content-Type": "application/json",
            },
            body,
          });
        })
        .then((res) => {
          if (!res.ok) throw new Error("unable to charge card");

          return res.json();
        });
    }
  }
  static checkCRM() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Cookie",
      "b266a5bf57=a711b6da0e6cbadb5e254290f114a026; iamcsr=242ef4cd-0468-4b2c-b157-edb7907bfdd3; _zcsr_tmp=242ef4cd-0468-4b2c-b157-edb7907bfdd3"
    );
    myHeaders.append("Access-Control-Allow-Origin", "*");
    const url =
      "https://accounts.zoho.com/oauth/v2/token?refresh_token=1000.3335161e9fbd225a3ecc34abdf7a56fc.30855c25235400f676676cb3381111e4%26redirect_uri=https://silverbackhw-637d2.firebaseapp.com%26client_id=1000.Q6VV4GXV9ZQA11DOWG6NZJ743S0D9J%26client_secret=588a26479c9c61abd2b6ef4ebb8e403781162bd2b4%26grant_type=refresh_token";

    return fetch(`${PaymentApi}/getzohotoken?url=${url}`, {
      method: "GET",
    }).then((response) => response.json());
  }
}
