import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';

export default ({ open, onClose }) => (
  <Dialog open={open}>
    <DialogTitle>Signin</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please signin to continue.
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
