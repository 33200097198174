import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import styled from "@emotion/styled";

import LogoSource from "assets/logo-full.png";
import Section from "components/common/section";
import Button from "components/common/button";

class WhyChoose extends React.Component {
  renderReasons = (reasons) =>
    reasons.map((reason, index) => (
      <ListItem key={`reason-${index}`} style={{ padding: "12px 24px" }}>
        <ListItemIcon>
          <CheckIcon />
        </ListItemIcon>
        <ListItemText
          style={{ padding: "0 16px" }}
          primary={reason}
          primaryTypographyProps={{ variant: "h5" }}
          sx={{ color: "rgba(0, 0, 0, 0.87)" }}
        />
      </ListItem>
    ));

  render() {
    const { t, style, onChoosePlan } = this.props;

    return (
      <Section style={style} shadow>
        <Grid
          container
          // spacing={2}
          alignContent="center"
          alignItems="center"
          sx={{ margin: "-8px" }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{ textAlign: "center", padding: "8px" }}
          >
            <Image
              src={LogoSource}
              style={{ height: "300", maxWidth: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ padding: "8px" }}>
            <List>{this.renderReasons(t("whyChoose.reasons"))}</List>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            style={{ marginTop: 32 }}
          >
            <Grid item xs={12} sm={4}>
              <Button color="primary" onClick={onChoosePlan}>
                {t("choosePlan")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    );
  }
}

const Image = styled("img")(() => ({
  maxWidth: "100%",
  height: 300,
}));

export default compose(withTranslation("homeowner"))(WhyChoose);
