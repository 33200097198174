import React, { Component } from "react";
import { func } from "prop-types";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import Typography from "@mui/material/Typography";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import Hide from "@mui/material/Hidden";

import {
  getPlanTotal,
  getPlanOptionTotal,
  getPreferredPlan,
  getPlan,
  getUserDisplayName,
  getUserEmail,
  getUserPhone,
  // getIsListingCoverage,
  getListingCoverage,
} from "store/reducers";
import {
  decrementLoading,
  incrementLoading,
  loadAgentData,
  submitOrder,
} from "store/actions/data";
import DataService from "services/data";

import BackgroundImage from "assets/images/Kitchen 2 w gorilla.jpg";
import Section from "components/common/section";
import OrderForm from "./components/order-form";
import OrderConfirmation from "./components/order-confirmation";
import OrderTotal from "./components/order-total";
import ZcrmService from "services/zohocrm";
// import { clearToken } from "helpers/utils";
// import PaymentService from "services/authorize";

class AgentOrders extends Component {
  static propTypes = {
    loadAgentData: func.isRequired,
  };

  state = {
    submitting: false,
    showConfirmation: false,
    submitError: "",
    defaultAgentsValues: {},
    coupon_list: [],
    coupon_value: 0,
    size: 0,
    year: 1,
    lead: [],
    prepaidAmount: "",
  };

  componentDidMount() {
    // let node = [];
    // localStorage.setItem('lead_details', node);
    // console.log('CD:',localStorage.getItem('lead_details').length);
    // let token = ZcrmService.getToken();
    // token.then(res => {
    //   console.log(res);
    //   let search = ZcrmService.searchUser(this.props.email, res.access_token,'Leads');
    //   search.then(response => {
    //     console.log('result',response);
    //     localStorage.setItem('lead_details', JSON.stringify(response.data));
    //     this.setState({lead:response.data})
    //   });
    // });
    this.props.loadAgentData(DataService.getAgents());
    const coupons = DataService.getpromoCodes("real_estate");
    coupons.then((res) => {
      if (Object.keys(res).length > 0) {
        const couponIds = Object.values(res);
        var couponOptions = [];
        couponIds.map((coupon) => {
          var isPresent = couponOptions.some(function (el) {
            return el.label === coupon.label;
          });
          if (!isPresent) {
            couponOptions?.push(coupon);
          }
          return couponOptions;
        });
        this.setState({ coupon_list: couponOptions });
      }
    });
  }

  findAgents(values) {
    const newAgentKeys = ["seller", "buyer"];
    const newAgents = newAgentKeys.map((key) =>
      DataService.pluckAgent(key, values)
    );

    return newAgents;
  }

  findEscrowInfo(values) {
    return {
      titleEmail: values["escrowEmail"],
      titleEscrow: values["escrowCompany"],
      titleOfficer: values["escrowOfficer"],
      titlePhone: values["escrowPhone"],
    };
  }
  setBuyerCredit = (amount) => {
    this.setState({ buyer_credit_val: amount });
  };

  setPrepaidAmount = (amount) => {
    this.setState({ prepaidAmount: amount });
  };
  handlePayNow = () => {
    console.log("pay now click");
  };

  handleSubmit = async (
    values,
    isLimited,
    planTotal,
    optionTotal,
    size,
    year
  ) => {
    // if (values.street !== null) return this.setState({ streetError: true })
    const transactionID = localStorage.getItem("transaction_id");
    localStorage.removeItem("transaction_id");
    this.setState({ submitting: true });
    const baseError = "Error submitting order, please retry.";
    const { name, email, phone } = this.props;
    let userdata = {
      name: name,
      email: email,
      phone: phone,
    };
    try {
      this.props.incrementLoading();
      console.log(values, isLimited, planTotal, optionTotal, size, year);
      let plan_total = (planTotal + size * 85) * year;
      planTotal = (planTotal + size * 85) * year - this.state.coupon_value;
      console.info("Data service submit order entry ::::::" + new Date());
      const promise = DataService.submitOrder(
        values,
        isLimited,
        planTotal,
        optionTotal,
        this.state.coupon_value
      );
      this.props.submitOrder(promise);
      console.info("After data service submit order ::::::" + new Date());
      const agents = this.findAgents(values);
      DataService.updateAgents(agents);

      const escrow = this.findEscrowInfo(values);
      if (escrow.titleEmail !== undefined) {
        DataService.updateEscrow(escrow);
      }
      console.info("Before zohocrm submit order ::::::" + new Date());
      promise
        // .then(() => this.setState({ showConfirmation: true }))
        .then(() => {
          let token = ZcrmService.getToken();
          token.then((res) => {
            // let lead = localStorage.getItem('lead_details');
            // console.log(typeof(lead));
            // console.log(lead.length);
            // if (lead === undefined || JSON.parse(lead).length === 0) {
            //   console.log('generating new lead');
            let lead_id;
            var buyerInfo = {
              first_Name:
                values.buyerAgentName !== undefined
                  ? values.buyerAgentName.substr(
                      0,
                      values.buyerAgentName.indexOf(" ")
                    )
                  : values.buyerAgentEmail !== undefined
                  ? values.buyerAgentEmail.substr(
                      0,
                      values.buyerAgentEmail.indexOf("@")
                    )
                  : "Blank",
              name:
                values.buyerAgentName !== undefined
                  ? values.buyerAgentName.substr(
                      values.buyerAgentName.indexOf(" ") + 1
                    )
                  : values.buyerAgentEmail !== undefined
                  ? values.buyerAgentEmail.substr(
                      0,
                      values.buyerAgentEmail.indexOf("@")
                    )
                  : "Line",
              email:
                values.buyerAgentEmail !== undefined
                  ? values.buyerAgentEmail
                  : "",
              phone:
                values.buyerAgentPhone !== undefined
                  ? values.buyerAgentPhone
                  : "",
              company:
                values.buyerAgentCompany !== undefined
                  ? values.buyerAgentCompany
                  : "",
            };

            var sellerInfo = {
              first_Name:
                values.sellerAgentName !== undefined
                  ? values.sellerAgentName.substr(
                      0,
                      values.sellerAgentName.indexOf(" ")
                    )
                  : values.sellerAgentEmail !== undefined
                  ? values.sellerAgentEmail.substr(
                      0,
                      values.sellerAgentEmail.indexOf("@")
                    )
                  : "Blank",
              name:
                values.sellerAgentName !== undefined
                  ? values.sellerAgentName.substr(
                      values.sellerAgentName.indexOf(" ") + 1
                    )
                  : values.sellerAgentEmail !== undefined
                  ? values.sellerAgentEmail.substr(
                      0,
                      values.sellerAgentEmail.indexOf("@")
                    )
                  : "Line",
              email:
                values.sellerAgentEmail !== undefined
                  ? values.sellerAgentEmail
                  : "",
              phone:
                values.sellerAgentPhone !== undefined
                  ? values.sellerAgentPhone
                  : "",
              company:
                values.sellerAgentCompany !== undefined
                  ? values.sellerAgentCompany
                  : "",
            };
            var escrowInfo = {
              first_Name:
                values.escrowOfficer !== undefined
                  ? values.escrowOfficer.substr(
                      0,
                      values.escrowOfficer.indexOf(" ")
                    )
                  : values.escrowCompany !== null || undefined
                  ? values.escrowCompany
                  : "Blank",
              name:
                values.escrowOfficer !== undefined
                  ? values.escrowOfficer.substr(
                      values.escrowOfficer.indexOf(" ") + 1
                    )
                  : values.escrowCompany !== null || undefined
                  ? values.escrowCompany
                  : "Line",
              email: values.escrowEmail !== undefined ? values.escrowEmail : "",
              phone:
                values.escrowPhone !== undefined
                  ? values.escrowPhone.toString()
                  : "",
              company:
                values.escrowCompany !== undefined ? values.escrowCompany : "",
            };
            let buyerAgent = ZcrmService.upsertREpros(
              buyerInfo,
              res.access_token
            );
            buyerAgent.then((buyer) => {
              console.log(buyer);
              let sellerAgent = ZcrmService.upsertREpros(
                sellerInfo,
                res.access_token
              );
              sellerAgent.then((seller) => {
                let escrowAgent = ZcrmService.upsertREpros(
                  escrowInfo,
                  res.access_token
                );
                escrowAgent.then((escrow) => {
                  let generate_lead = ZcrmService.generateLead(
                    values,
                    userdata,
                    res.access_token,
                    this.props.isListingCoverage
                  );
                  generate_lead
                    .then(async (res) => {
                      console.log(res.data);
                      res?.data[0].code === "SUCCESS"
                        ? (lead_id = res.data[0].details.id)
                        : (lead_id = "");
                      console.log(lead_id);
                      let related_id = {
                        lead_id:
                          res.data.length > 0 ? res.data[0].details.id : "",
                        buyer_id:
                          buyer.data.length > 0 ? buyer.data[0].details.id : "",
                        seller_id:
                          seller.data.length > 0
                            ? seller.data[0].details.id
                            : "",
                        escrow_id:
                          escrow.data.length > 0
                            ? escrow.data[0].details.id
                            : "",
                      };
                      console.log(related_id);
                      let data = {
                        values: values,
                        userdata: userdata,
                        related_id: related_id,
                        discount: this.state.coupon_value,
                      };
                      // const order = await
                      ZcrmService.generateOrder(
                        this.state.buyer_credit_val,
                        res.access_token,
                        data,
                        0,
                        this.props.isListingCoverage,
                        transactionID,
                        this.state.prepaidAmount
                      );
                      console.info(
                        "After crm  submit order with new lead ::::::" +
                          new Date()
                      );
                      // if (!order.data || !order?.data[0]?.code === "SUCCESS") {
                      //   if (transactionID)
                      //     PaymentService.refund({ data: { transactionID } });
                      //   throw new Error(order.error.message);
                      // }
                      this.props.handleNavigation(`/plans/order/confirm`, {
                        state: {
                          type: "plans",
                          user: userdata,
                          data: values,
                          cost: planTotal,
                          optionTotal: optionTotal,
                          year: year,
                          discount: this.state.coupon_value,
                        },
                      });
                    })
                    .catch((e) => {
                      this.setState({
                        submitError: `${baseError} Error`,
                        submitting: false,
                      });
                      this.props.decrementLoading();
                      console.log(e, "error");
                      // clearToken();
                    });
                });
              });
            });

            // } else {
            //   console.log('lead exist');
            //   let buyerAgent = ZcrmService.searchUser(values.buyerAgentEmail, res.access_token,'RE_Pros');
            //   buyerAgent.then(buyer => {
            //     let sellerAgent = ZcrmService.searchUser(values.sellerAgentEmail, res.access_token,'RE_Pros');
            //     sellerAgent.then(seller => {
            //       let escrowAgent = ZcrmService.searchUser(values.escrowEmail, res.access_token,'RE_Pros');
            //       escrowAgent.then(escrow => {
            //         let related_id = {
            //           lead_id: this.state.lead.length > 0 ? this.state.lead[0].id : '',
            //           buyer_id: buyer.data.length > 0 ? buyer.data[0].id : '',
            //           seller_id: seller.data.length > 0 ? seller.data[0].id : '',
            //           escrow_id: escrow.data.length > 0 ? escrow.data[0].id : ''
            //         }
            //         console.log(related_id);
            //         ZcrmService.generateOrder(values, userdata, res.access_token, related_id, this.state.coupon_value);
            //         console.info("After crm  submit order without new lead ::::::" + new Date());

            //             this.props.history.push({
            //               pathname: `/plans/order/confirm`,
            //               state: { type: 'plans' }
            //             });

            //       });
            //     });
            //   });
            // }
          });
        })
        .catch((e) => {
          this.setState({
            submitError: `${baseError} Error - ${e.message}`,
            submitting: false,
          });
          this.props.decrementLoading();
          console.log(e, "error");
          // clearToken();
        });
    } catch (e) {
      this.setState({
        submitError: `${baseError} Error - ${e.message}`,
        submitting: false,
      });
      this.props.decrementLoading();
      console.log(e, "error");
    }
  };

  setDefaultAgents = (prefix, agent, agents) => {
    agent.oldPhone = agent.phone;
    const { defaultAgentsValues } = this.state;
    defaultAgentsValues[prefix] = agent;

    this.setState({
      defaultAgentsValues,
      agents,
    });
  };

  searchEscrow = (field, query) => {
    return new Promise((res, rej) => {
      DataService.searchEscrow(field, query, (items) => {
        if (items) return res(items);
        return rej();
      });
    });
  };
  get_coupon = (amount) => {
    // this.setState({
    //   coupon_value: amount
    // });
    // console.log(this.state.coupon_value)
    this.setState({ coupon_value: amount }, () => {
      console.log(this.state.coupon_value);
    });
  };
  get_size = (size) => {
    console.log(size);
    this.setState({ size });
  };
  get_year = (year) => {
    this.setState({ year });
    const isLimited = this.props.plan.name === "variable";
    const limitedAmount = this.props.plan.amount * 1;
    console.log(isLimited);
    console.log(limitedAmount);
    isLimited
      ? (this.props.planTotal + this.state.size * 85) * year > limitedAmount
        ? this.setState({ year: 1 })
        : console.log("plan approve")
      : this.setState({ year });
  };
  getcouponfromURL = () => {
    const query = new URLSearchParams(this.props.location?.search);
    const couponfromURL = query.get("promo");
    return couponfromURL;
  };
  getoptionlist = () => {
    const coupons = DataService.getpromoCodes("real_estate");
    return coupons;
  };

  render() {
    const { plan, agents, planTotal, planOptionTotal, preferredPlan, t } =
      this.props;
    const { showConfirmation, submitError, size, year } = this.state;
    const showTotals =
      preferredPlan !== "listing-coverage" ? !showConfirmation : false;
    const isLimited = plan.name === "variable";
    const limitedAmount = plan.amount * 1;
    // console.log(planTotal);
    return (
      <Section image={BackgroundImage}>
        <Helmet title={`${t("nameShort")}: ${t("plans:order.pageTitle")}`} />

        <Typography
          sx={{ letterSpacing: "-0.02em" }}
          variant="h1"
          color="inherit"
          gutterBottom
          className="Hidden600"
        >
          {t("plans:order.header")}
        </Typography>

        <Typography
          sx={{ letterSpacing: "-0.02em" }}
          variant="h1"
          color="inherit"
          gutterBottom
          className="hidden600"
        >
          {t("plans:order.header")}
        </Typography>

        <OrderForm
          onSubmit={(val) =>
            this.handleSubmit(
              val,
              isLimited,
              planTotal,
              isLimited
                ? (limitedAmount - planTotal).toFixed(2) * 1
                : planOptionTotal,
              size,
              year
            )
          }
          agents={agents}
          isSubmitting={this.state.submitting}
          errorMessage={submitError}
          onAgentSelect={this.setDefaultAgents}
          onEscrowSearch={this.searchEscrow}
          isLimited={isLimited}
          limitedAmount={limitedAmount}
          optionTotal={planOptionTotal}
          options={this.state.coupon_list}
          getcouponamount={this.get_coupon}
          getHomesize={this.get_size}
          getyear={this.get_year}
          planTotal={(planTotal + size * 85) * year}
          getcouponfromURL={this.getcouponfromURL}
          getoptionlist={this.getoptionlist}
          setBuyerCredit={this.setBuyerCredit}
          prepaidAmount={this.setPrepaidAmount}
          buyerCreditvalue={this.state.buyer_credit_val}
          discount={this.state.coupon_value}
          // isStreetError={this.state.streetError}
        />

        {!this.props.isListingCoverage
          ? showTotals && (
              <OrderTotal
                discount={this.state.coupon_value}
                planTotal={(planTotal + size * 85) * year}
                optionTotal={
                  isLimited
                    ? (limitedAmount - (planTotal + size * 85) * year).toFixed(
                        2
                      ) * 1
                    : planOptionTotal
                }
              />
            )
          : null}

        <OrderConfirmation
          open={showConfirmation}
          onClose={() => this.props.handleNavigation("/")}
        />
      </Section>
    );
  }
}

const mapStateToProps = (state) => ({
  planTotal: getPlanTotal(state),
  planOptionTotal: getPlanOptionTotal(state),
  preferredPlan: getPreferredPlan(state),
  plan: getPlan(state),
  name: getUserDisplayName(state),
  email: getUserEmail(state),
  phone: getUserPhone(state),
  // isListingCoverage: getIsListingCoverage(state),
  isListingCoverage: getListingCoverage(),
});

const mapDispatchToProps = (dispatch) => ({
  loadAgentData: (promise) => dispatch(loadAgentData(promise)),
  submitOrder: (promise) => dispatch(submitOrder(promise)),
  incrementLoading: () => dispatch(incrementLoading()),
  decrementLoading: () => dispatch(decrementLoading()),
});

export default compose(
  withTranslation(["common", "plans"]),
  connect(mapStateToProps, mapDispatchToProps)
)(AgentOrders);
