import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
// import { withStyles } from '@mui/material/styles';
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { connect } from "react-redux";
import { getIsSignedIn } from "store/reducers";
import * as Styled from "./styled";
import MobileMenu from "./mobile-menu";
import Logo from "assets/logo.png";
import { Gradient } from "./styled";
import { styled } from "@mui/material/styles";
import styles from "./header.module.css";
import { Button, Menu, MenuItem, Select } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
const StyledHeader = styled("header")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  backgroundColor: "transparent",
  boxShadow: "none",

  [theme.breakpoints.down("sm")]: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
}));
const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  maxWidth: theme.spacing(180),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  flex: "1 0 auto",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));
class Header extends PureComponent {
  state = { menu: null, open: false };

  static propTypes = {
    transparent: PropTypes.bool.isRequired,
  };

  handleMenuOpen = (e) => {
    this.setState({ menu: e.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ menu: null });
  };
  handleRoute = (url) => () => this.props.handleNavigation(url);
  buttonRef = null;
  render() {
    const { transparent, t, isSignedIn, classes } = this.props;
    const authPath = isSignedIn ? "/auth/signout" : "/auth/signin";
    const authText = isSignedIn ? "navigation.signout" : "navigation.signin";
    return (
      <AppBar color="primary" className={styles.header}>
        <StyledToolbar disableGutters>
          <Styled.BrandWrapper onClickCapture={() => this.handleRoute("/")()}>
            <Styled.LightLink to={"/"}>
              <Styled.Image
                src={Logo}
                alt="SilverBack Logo"
                transparent={transparent}
              />
            </Styled.LightLink>
          </Styled.BrandWrapper>

          <>
            <Styled.Brand variant="subtitle2">
              <Styled.LightLink
                to="/homeowner"
                ref={(ref) => (this.buttonRef = ref)}
              >
                {t("navigation.homeowner")}
              </Styled.LightLink>
              <Styled.MenuButton
                disableElevation
                aria-haspopup="true"
                className={styles.dropdown}
                endIcon={<KeyboardArrowDown sx={{ marginBottom: "-4px" }} />}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  this.setState((prev) => ({ open: !prev.open }));
                }}
                id="menu-button"
              >
                {t("navigation.claims")}
                {/* <KeyboardArrowDown /> */}
              </Styled.MenuButton>
              <Menu
                open={this.state.open}
                MenuListProps={{
                  "aria-labelledby": "menu-button",
                }}
                anchorEl={this.buttonRef}
                sx={{
                  fontFamily: "PT Sans Narrow",
                  marginLeft: "24px",
                  padding: "0",
                }}
                onClose={() => this.setState({ open: false })}
              >
                {[
                  { title: t("navigation.maintenance"), link: "/maintenance" },
                  { title: t("navigation.claims"), link: "/claims" },
                ].map((value) => (
                  <MenuItem
                    key={value.title}
                    onClick={() => {
                      this.setState({ open: false });
                      this.handleRoute(value.link)();
                    }}
                    sx={{
                      width: "auto",
                      padding: "16px 24px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 174, 239, 0.12);",
                        color: "#00AEEF",
                      },
                      textTransform: "capitalize",
                    }}
                    className={styles.menuItem}
                  >
                    {/* <Styled.LightLink to="/claims"> */}
                    {value.title}
                  </MenuItem>
                ))}
                {/* </Styled.LightLink> */}
              </Menu>

              <Styled.LightLink to="/contact">
                {t("navigation.contact")}
              </Styled.LightLink>
              <Styled.LightLink to="/about">
                {t("navigation.aboutus")}
              </Styled.LightLink>
              {isSignedIn && (
                <Styled.LightLink to="/auth/signin">
                  {t("navigation.myaccount")}
                </Styled.LightLink>
              )}
              <Styled.AuthLink to={authPath}>{t(authText)}</Styled.AuthLink>
            </Styled.Brand>
          </>

          <div className={styles.hidden}>
            <IconButton onClick={this.handleMenuOpen}>
              <Styled.IconWrapper>
                <MenuIcon className="light" />
              </Styled.IconWrapper>
            </IconButton>
            <MobileMenu
              anchor={this.state.menu}
              open={Boolean(this.state.menu)}
              onClose={this.handleMenuClose}
            />
          </div>
        </StyledToolbar>
        <Gradient className={transparent ? null : "show"} />
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
});

// const styles = (theme) => ({
//   header: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "center",
//     backgroundColor: "transparent",
//     boxShadow: "none",

//     [theme.breakpoints.down("sm")]: {
//       backgroundColor: theme.palette.primary.main,
//       boxShadow: theme.shadows[2],
//     },
//   },

//   toolbar: {
//     maxWidth: theme.spacing(180),
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     flex: "1 0 auto",
//     width: "100%",
//     [theme.breakpoints.down("sm")]: {
//       maxWidth: "100%",
//     },
//   },
// });

export default compose(
  // withStyles(styles),
  withTranslation("common"),
  connect(mapStateToProps)
)(Header);
