import styled from '@emotion/styled'

export const AppContainer = styled('div')(({ theme }) => ({
  label: 'app-container',

  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
  maxWidth: '100%',
}));

export const AppContent = styled('div')(({ theme }) => ({
  label: 'app-content',

  display: 'flex',
  minHeight: '100vh',
  marginTop: theme.spacing(8),
}));
