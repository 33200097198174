export const REFIGERATION = "Additional Refrigeration";
export const STANDALONE_FREEZER = "Stand Alone Freezer";
export const SECOND_KITCKEN = "2nd Kitchen";
export const WASHER_DRYER = "Washer and Dryer";
export const WATER_SOFTENER = "Water Softener";
export const ADD_WATER_HEATER = "Additional Water Heater";
export const CENTRAL_VACUUM = "Central Vacuum";
export const POOL_HOTTUB = "Swimming Pool & Hot Tub ";
export const SEWAGELINE = "Water/Gas/Sewage Line Coverage";
// export const SALT_POOL_HOTTUB = 'POOL/SALT_WATER';
// export const ICEMAKER = 'ICEMAKER';
export const SEPTIC_SYSTEM = "Septic System & Pumping";
export const GRINDER_PUMP = "Grinder Pump/Sewer Ejector";
export const BOOSTER_PUMP = "Booster Pump/Sump Pump";
export const DETACHED_BUILDING = "Detached Building Coverage";
export const WELL_PUMP = "Well Pump";
export const BASEMENT_APARTMENT = "Basement Apartment/Mother-in-law suite";
export const ADD_SQ_FT = "Additional Sq Ft";
export const PREPAID_SERVICE = "Pre-paid Service Call Fee";
export const NO_FAULT_COVERAGE = "No-Fault Coverage";
export const AC_PROTECTION = "AC Protection";
export const BUYER_CREDIT = "BUYER_CREDIT";
export const BRAND_FOR_BRAND = "Brand For Brand";
export const Sprinkler_Timer_System = "Sprinkler Timer & System";
export const AddonPriceEnum = {
  [REFIGERATION]: 65,
  [STANDALONE_FREEZER]: 65,
  [SECOND_KITCKEN]: 100,
  [WASHER_DRYER]: 120,
  [WATER_SOFTENER]: 85,
  [ADD_WATER_HEATER]: 85,
  [CENTRAL_VACUUM]: 65,
  [POOL_HOTTUB]: 180,
  [SEWAGELINE]: 220,
  // [SALT_POOL_HOTTUB]: 330,
  // [ICEMAKER]: 45,
  [SEPTIC_SYSTEM]: 150,
  [GRINDER_PUMP]: 115,
  [BOOSTER_PUMP]: 115,
  [DETACHED_BUILDING]: 240,
  [WELL_PUMP]: 90,
  [BASEMENT_APARTMENT]: 240,
  [ADD_SQ_FT]: 85,
  [PREPAID_SERVICE]: 50,
  [NO_FAULT_COVERAGE]: 85,
  [AC_PROTECTION]: 185,
  [BRAND_FOR_BRAND]: 250,
  [Sprinkler_Timer_System]: 95,
};

export const AddonDesc = {
  [REFIGERATION]: {
    covered:
      "Mechanical components and parts that affect the cooling operation, including ice maker, beverage dispenser. Limits $1,000 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Water supply lines, door, door seals, hinges, rollers, racks, trays, baskets, food spoilage, lights or light sockets, wine chillers, buckets, trim kits, handles, door seals, runner guards, shelves, interior linings, filters, filter-housing, interior/exterior water lines, refrigerator replacement in event ice maker parts are not available, insulation, touch pads, and touch screens",
  },
  "Additional Refrigerator": {
    covered:
      "Mechanical components and parts that affect the cooling operation, including ice maker, beverage dispenser. Limits $1,000 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Water supply lines, door, door seals, hinges, rollers, racks, trays, baskets, food spoilage, lights or light sockets, wine chillers, buckets, trim kits, handles, door seals, runner guards, shelves, interior linings, filters, filter-housing, interior/exterior water lines, refrigerator replacement in event ice maker parts are not available, insulation, touch pads, and touch screens",
  },
  [SECOND_KITCKEN]: {
    covered:
      "Limits $1000 for additional built in appliances, dishwasher, microwave, stove/oven, garbage disposal (not including refrigerator)",
    not_covered: "Refrigerator",
  },
  [STANDALONE_FREEZER]: {
    covered:
      "Mechanical parts and components that affect the operation of the unit. Limits $500 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Ice-makers, crushers, dispensers and related equipment, Internal shell, Racks, Shelves, Glass displays, Lights, Knobs and caps, Dials, Doors, Door seals and gaskets, Door hinges, Door handles, Condensation pans, Clogged drains and clogged lines, Grates, Food spoilage, insulation,",
  },
  [WASHER_DRYER]: {
    covered:
      "Mechanical parts and components which affect operation. Limits $500 for washer $500 per dryer, maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Tubes, drain line, knobs/dials, tub, rollers, lint screens, filters, venting, touchpad assembly, thermal shells, trim/deco kits, soap dispenser, insulation, pans, dual washers, all-in-one washer/dryer combo units.",
  },
  "Washer and Dreyer per set": {
    covered:
      "Mechanical parts and components which affect operation. Limits $500 for washer $500 per dryer, maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Tubes, drain line, knobs/dials, tub, rollers, lint screens, filters, venting, touchpad assembly, thermal shells, trim/deco kits, soap dispenser, insulation, pans, dual washers, all-in-one washer/dryer combo units.",
  },
  [WATER_SOFTENER]: {
    covered:
      "Mechanical parts and components which affect operation. Limits $500 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Water filtration systems, water purification systems, conditions caused by calcium, chemical or sediment build-up and/or deposits, resin bed, softening agents.",
  },
  [ADD_WATER_HEATER]: {
    covered:
      "Mechanical components and parts, gas and electric up to 50 gallon capacity and tankless. Limits $500 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered: "",
  },
  [CENTRAL_VACUUM]: {
    covered:
      "All mechanical system components and parts. Limits $250 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered: "Ductwork, hoses, blockages, accessories",
  },
  [POOL_HOTTUB]: {
    covered:
      "Above-ground and accessible working components and parts of filtration and heating system as follows, pool sweep motor and pump, filter timer, pool pump, motor, filter housing, back flush valve, gaskets, blower, heater, above-ground plumbing pipes and wiring. Limits $1200 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Pop-up heads, pool sweeps, skimmers, fill line, lights, fill valve, control panels, boards, and switches, jets, cartridge filters, disposable filtration mediums, sand as filtration medium, turbo valves, heat pump, chlorinators, ionizers, pool liner, pool cover and related equipment, solar plumbing or heating equipment.",
  },
  "Swimming Pool and Hot Tub": {
    covered:
      "Above-ground and accessible working components and parts of filtration and heating system as follows, pool sweep motor and pump, filter timer, pool pump, motor, filter housing, back flush valve, gaskets, blower, heater, above-ground plumbing pipes and wiring. Limits $1200 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Pop-up heads, pool sweeps, skimmers, fill line, lights, fill valve, control panels, boards, and switches, jets, cartridge filters, disposable filtration mediums, sand as filtration medium, turbo valves, heat pump, chlorinators, ionizers, pool liner, pool cover and related equipment, solar plumbing or heating equipment.",
  },
  [SEWAGELINE]: {
    covered:
      "$2500 maximum per contract period for diagnosis, repair or replacement of leaks in exterior water, sewage drain and underground and concrete encased pipe leaks that are located outside the foundation of the covered property, including water, gas and sewage drain lines that service the covered property.",
    not_covered:
      "hose bibs, exterior faucets, relocation of pipes, downspouts, sprinkler systems, irrigation lines, swimming pool pipes and damage caused by roots.Coverage not available to multi-unit buildings and condominiums. $2500 maximum per contract period for diagnosis, repair or replacement of leaks in exterior water, sewage drain, and gas piping.",
  },
  // [SALT_POOL_HOTTUB]: 330,
  // [ICEMAKER]: 45,
  [SEPTIC_SYSTEM]: {
    covered:
      "Septic Tank, jet pump, aerobic pump, sewage ejector pump, and the line from the house. If stoppage is due to septic tank backup, SBHW will pump the septic tank one time during the term of the Contract due to backup. Coverage limited to one septic tank. Limits $500 maximum for diagnosis, replace, or repair during cove",
    not_covered:
      "Leach fields and tile fields, lateral lines, cleanout, insufficient capacity, the cost of locating or gaining access to tank, chemical treatments.",
  },
  "Septic System and Pumping": {
    covered:
      "Septic Tank, jet pump, aerobic pump, sewage ejector pump, and the line from the house. If stoppage is due to septic tank backup, SBHW will pump the septic tank one time during the term of the Contract due to backup. Coverage limited to one septic tank. Limits $500 maximum for diagnosis, replace, or repair during cove",
    not_covered:
      "Leach fields and tile fields, lateral lines, cleanout, insufficient capacity, the cost of locating or gaining access to tank, chemical treatments.",
  },
  [GRINDER_PUMP]: {
    covered:
      "Sewage grinder pump utilized for the main dwelling only, up to 2 horsepower. Limits $500 maximum for diagnosis, replace, or repair during coverage term. Not available to multi-unit buildings.",
    not_covered:
      "Piping and electrical lines, electric panel box, grinder pump station housing, components.",
  },
  [BOOSTER_PUMP]: {
    covered:
      "Booster and sump pump utilized for the main dwelling only. Limits $500 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Storage or pressure tank, piping and electrical lines, control boxes, well casing, capacitors or relays, pressure switches.",
  },
  [DETACHED_BUILDING]: { covered: "", not_covered: "" },
  [WELL_PUMP]: {
    covered:
      "Well pump utilized for the main dwelling only. Limits $500 maximum for diagnosis, replace, or repair during coverage term.",
    not_covered:
      "Well casing, electrical and piping and electrical lines, control boxes, capacitors or relays, pressure switches, well pump and well pump components and piping for geothermal and/or water source heat pumps, access to repair well pump system.",
  },
  [BASEMENT_APARTMENT]: {
    covered:
      "Additional living space up to 1,800 square feet. Includes all items listed in the Blackback Plan",
  },
  [ADD_SQ_FT]: {
    covered:
      "Properties over 6,000 sq ft. An additional charge per 1,000 sq ft, not to exceed 10,000 sq ft.",
  },
  [PREPAID_SERVICE]: {
    covered:
      "The purchase of this option allows the Contract Holder to request service one time during the coverage period without paying a service call fee to SBHW.",
  },
  [NO_FAULT_COVERAGE]: {
    covered:
      "Includes all items listed under King Kong in addition to, failures due to improper installation, failures due to mismatched systems, code violations, permit fees, crane costs, haul-away of failed system or appliances, rust and corrosion, so long as failure was no caused by the contract holder, known by contract holder prior to effective date, and would not have been detectable by a licensed home inspector or a visual inspection or simple mechanical test. A visual inspection of the covered item to verify that it is without missing parts, without damage that could cause imminent failure and is structurally intact. A simple mechanical test is to verify the operation of the covered item and perform simple diagnostic tests to ensure that it operates properly and is without irregular sounds, odors, smoke or out of the ordinary results. SBHW will not be responsible for replacement service when permits cannot be obtained by the Contract Holder. We may provide at our discretion cash in lieu of repair or replacement. SBHW will pay up to $250 per claim for items related to No-Fault Coverage Upgrade.",
  },
  [AC_PROTECTION]: {
    covered:
      "If SBHW determines an outdoor air conditioning (condenser) unit must be replaced, requiring the indoor air conditioning (coil) unit to be replaced (R22 conversion) as well, SBHW will pay up to $1000 towards the R410A system. SBHW will pay $20 per pound Refrigerant/Freon.",
  },
  [BRAND_FOR_BRAND]: {
    covered:
      "Mechanical parts and components which affect the operation. Appliances must be in good working order on the effective date of the policy. This a la carte option provides additional coverage on all kitchen appliances including refrigerator matching for brand and color of the appliance, if available, when replacement is required up to $5,000 per occurrence.",
    not_covered: "",
  },
  [Sprinkler_Timer_System]: {
    covered:
      "Sprinkler heads, timer, remotely activated control valves, accessible PVC line leaks (not including damage caused by freeze), bubbler heads, diaphragm and backflow prevention devices. Limits $500 maximum to diagnose, replace or repair. Sprinkler heads and system timer are replaced with builder's standard when required. This option covers systems with 6 valves or less. Systems with more than 6 valves require an additional fee - please call for a quote.",
  },
  "Basement Apartment / Mother-in-law suite": {
    covered:
      "Additional living space up to 1,800 square feet. Includes all items listed in the Blackback Plan",
  },
  "No Fault Coverage": {
    covered:
      "Includes all items listed under King Kong in addition to, failures due to improper installation, failures due to mismatched systems, code violations, permit fees, crane costs, haul-away of failed system or appliances, rust and corrosion, so long as failure was no caused by the contract holder, known by contract holder prior to effective date, and would not have been detectable by a licensed home inspector or a visual inspection or simple mechanical test. A visual inspection of the covered item to verify that it is without missing parts, without damage that could cause imminent failure and is structurally intact. A simple mechanical test is to verify the operation of the covered item and perform simple diagnostic tests to ensure that it operates properly and is without irregular sounds, odors, smoke or out of the ordinary results. SBHW will not be responsible for replacement service when permits cannot be obtained by the Contract Holder. We may provide at our discretion cash in lieu of repair or replacement. SBHW will pay up to $250 per claim for items related to No-Fault Coverage Upgrade.",
  },
};
