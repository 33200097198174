import React from "react";
import { Link } from "react-router-dom";
import { Typography, Grid } from "@mui/material";
import styled from "@emotion/styled";

import Section from "components/common/section";
import Button from "components/common/button";
import ImageSource from "assets/images/sales.jpg";

export default () => (
  <Section>
    <Grid container spacing={16} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={6}>
        <Typography variant="h2" color="primary" gutterBottom>
          Buying or Selling a Home?
        </Typography>
        <Typography color="black" variant="h5" gutterBottom>
          SilverBack has home warranty plans specifically designed for home
          buyers and sellers
        </Typography>
        <Button component={Link} to="/plans/start">
          Review Real Estate Plans
        </Button>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Image src={ImageSource} alt="Real Estate Options" />
      </Grid>
    </Grid>
  </Section>
);

const Image = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));
