export default {
  banner: {
    desc: `Mastering Household Maintenance: A Journey of Continuous Learning for Homeowners. Enhance your basic understanding of key home systems such as plumbing, electrical, HVAC, and deepen expertise in areas tailored to your home. Access a collection of concise, helpful YouTube videos addressing common maintenance issues and gaining fundamental knowledge of household systems.`,
  },
  playlist: {
    desc: [
      `In these instructive videos, we'll walk you through essential insights and easy-to-follow guidance on how to properly care for and maintain your appliances. By heeding our expert tips, you'll extend the lifespan of your appliances, enhance their performance, and reduce the risk of inconvenient breakdowns, all while preserving the value of your home.`,
      `We understand the importance of a well-functioning plumbing system in your home. In these informative videos, we'll guide you through essential tips and step-by-step instructions on how to proactively care for and maintain your plumbing infrastructure. By following our expert advice, you can ensure that your plumbing system remains reliable and efficient, helping you avoid unexpected repair costs and ensuring peace of mind for years to come.`,
      `Introducing our dedicated series of HVAC maintenance videos, designed exclusively for homeowners seeking to optimize their heating, ventilation, and air conditioning systems. At SilverBack HW, we acknowledge the vital role your HVAC system plays in maintaining indoor comfort and air quality. In these educational videos, we'll provide you with valuable insights and practical instructions to ensure the continued efficiency and reliability of your HVAC system. By following our expert advice, you'll not only enhance your home's comfort but also potentially reduce energy costs and extend the lifespan of your HVAC equipment, making your home a more comfortable and cost-effective place to live.`,
      `Maintaining your home is not just about preserving its beauty but also ensuring safety, comfort, and longevity. In these informative videos, we'll cover a wide range of essential home maintenance tasks and preventative measures. Whether you're a seasoned homeowner or new to the responsibilities of property upkeep, our expert guidance will help you tackle the challenges of maintaining a safe, functional, and inviting home.`,
    ],
    title: [
      "Appliance Maintenance",
      "Plumbing Maintenance",
      "HVAC Maintenance",
      "General home Maintenance",
    ],
  },
};
