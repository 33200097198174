import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Field } from "redux-form";
import { withTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// import { withStyles } from '@mui/material/styles';

import {
  PlanPriceEnum,
  SILVERBACK,
  BLACKBACK,
  KING_KONG,
  DUPLEX,
  TRIPLEX,
  FOURPLEX,
  NEW_HOUSE_BLACKBACK,
  NEW_HOUSE_SILVERBACK,
  NEW_HOUSE_KING_KONG,
  NEW_CONDO_BLACKBACK,
  NEW_CONDO_SILVERBACK,
  NEW_CONDO_KING_KONG,
} from "constants/plans";
import { isRequired } from "helpers/validators";
import { renderRadioGroup, renderCheckbox } from "helpers/form";
import * as Styled from "./styled-components";
import { compose } from "redux";
import { styled } from "@mui/system";

const styles = (theme) => ({
  planOptionCard: {
    height: "100%",
  },
});

class Plans extends React.Component {
  state = { years: 1, sf: false, mf: false, planName: "single" };
  renderLabel = (label, price, size, year, type) => (
    <Styled.FlexRow>
      <p>{type}</p>
      {type && <span className="prefix">${(price + size * 85) * year}</span>}
      {!type && <span className="prefix">${(price + size * 85) * 1}</span>}
      {label}
    </Styled.FlexRow>
  );
  renderValue = (price, size) => {
    return price + size * 85;
  };
  handleChangesf = (event) => {
    if (event.target.checked) {
      this.setState({ years: 2, sf: true, mf: false });
      this.props.getyearvalue(2);
    } else {
      this.setState({ years: 1, sf: false, mf: false });
      this.props.getyearvalue(1);
      console.log(this.state.sf);
      console.log(this.state.mf);
    }
  };
  handleChangemf = (event) => {
    if (event.target.checked) {
      this.setState({ years: 2, mf: true, sf: false });
      this.props.getyearvalue(2);
    } else {
      this.setState({ years: 1, mf: false, sf: false });
      this.props.getyearvalue(1);
      console.log(this.state.sf);
      console.log(this.state.mf);
    }
  };

  render() {
    const { t, classes, isLimited, limitedAmount, size, planTotal } =
      this.props;
    const { years, sf, mf, planName } = this.state;

    console.log("limitedAmount is =" + limitedAmount);
    console.log("size " + size);
    console.log("planTotal " + planTotal);
    console.log("calculation " + (planTotal + size * 85) * 2);

    return (
      <Styled.FormGroup>
        <Typography
          className="label"
          color="primary"
          align="left"
          variant="body1"
          sx={{
            fontFamily: `"PT Sans Narrow", "Roboto", "sans-serif"`,
            fontWeight: "700",
          }}
        >
          {t("plans:planOptionTitle")}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }} className={classes?.planOptionCard}>
              <CardHeader
                title={t("plans:single.title")}
                titleTypographyProps={{
                  color: "secondary",
                  variant: "subtitle1",
                }}
              />
              <CardContent>
                <Field
                  name="planOption"
                  component={renderRadioGroup}
                  validate={isRequired}
                  onBlur={(e) => e.preventDefault()}
                  onChange={() => {
                    this.setState({ planName: "single" });
                    this.setState({ mf: false });
                    this.props.getyearvalue(1);
                  }}
                  options={[
                    {
                      value: BLACKBACK,
                      label: this.renderLabel(
                        t("plans:single.blackback"),
                        PlanPriceEnum[BLACKBACK],
                        size,
                        years,
                        sf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[BLACKBACK] * years,
                    },
                    {
                      value: SILVERBACK,
                      label: this.renderLabel(
                        t("plans:single.silverback"),
                        PlanPriceEnum[SILVERBACK],
                        size,
                        years,
                        sf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[SILVERBACK] * years,
                    },
                    {
                      value: KING_KONG,
                      label: this.renderLabel(
                        t("plans:single.kingKong"),
                        PlanPriceEnum[KING_KONG],
                        size,
                        years,
                        sf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[KING_KONG] * years,
                    },
                  ]}
                />
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Field
                    name="sf_2years"
                    component={renderCheckbox}
                    label="2-Year Plan"
                    onChange={this.handleChangesf}
                    checked={sf}
                    disabled={
                      planName === "single"
                        ? isLimited
                          ? limitedAmount <
                            (sf ? planTotal : (planTotal + size * 85) * 2)
                          : false
                        : true
                    }
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }} className={classes?.planOptionCard}>
              <CardHeader
                title={t("plans:multi.title")}
                titleTypographyProps={{
                  color: "secondary",
                  variant: "subtitle1",
                }}
              />
              <CardContent>
                <Field
                  name="planOption"
                  component={renderRadioGroup}
                  validate={isRequired}
                  onBlur={(e) => e.preventDefault()}
                  onChange={() => {
                    this.setState({ planName: "multi" });
                    this.setState({ sf: false });
                    this.props.getyearvalue(1);
                  }}
                  options={[
                    {
                      value: DUPLEX,
                      label: this.renderLabel(
                        t("plans:multi.duplex"),
                        PlanPriceEnum[DUPLEX],
                        size,
                        years,
                        mf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[DUPLEX] * years,
                    },
                    {
                      value: TRIPLEX,
                      label: this.renderLabel(
                        t("plans:multi.triplex"),
                        PlanPriceEnum[TRIPLEX],
                        size,
                        years,
                        mf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[TRIPLEX] * years,
                    },
                    {
                      value: FOURPLEX,
                      label: this.renderLabel(
                        t("plans:multi.fourplex"),
                        PlanPriceEnum[FOURPLEX],
                        size,
                        years,
                        mf
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[FOURPLEX] * years,
                    },
                  ]}
                />
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <Field
                    name="mf_2years"
                    component={renderCheckbox}
                    label="2-Year Plan"
                    onChange={this.handleChangemf}
                    checked={mf}
                    disabled={
                      planName === "multi"
                        ? isLimited
                          ? limitedAmount <
                            (mf ? planTotal : (planTotal + size * 85) * 2)
                          : false
                        : true
                    }
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4}>
            <Card sx={{ height: "100%" }} className={classes?.planOptionCard}>
              <CardHeader
                title={t("plans:newConstruction.title")}
                titleTypographyProps={{
                  color: "secondary",
                  variant: "subtitle1",
                }}
              />
              <CardContent>
                {/* <Field
                  name="planOption"
                  component={renderRadioGroup}
                  validate={isRequired}
                  onBlur={e => e.preventDefault()}
                  onChange= { ()=>{ this.setState({sf:false,mf:false}); this.props.getyearvalue(1);} }
                  options={[
                    { 
                      value: NEW_HOUSE,
                      label: this.renderLabel(t('plans:newConstruction.newHome'), PlanPriceEnum[NEW_HOUSE],size, 1),
                      disabled: (isLimited && limitedAmount < PlanPriceEnum[NEW_HOUSE])
                    },
                    {
                      value: NEW_CONDO,
                      label: this.renderLabel(t('plans:newConstruction.newCondo'), PlanPriceEnum[NEW_CONDO],size, 1),
                      disabled: (isLimited && limitedAmount < PlanPriceEnum[NEW_CONDO])
                    }
                  ]}
                /> */}
                <p style={{ color: "black", textAlign: "left" }}>
                  {t("plans:newConstruction.newHome")}
                </p>
                <Field
                  name="planOption"
                  component={renderRadioGroup}
                  validate={isRequired}
                  onBlur={(e) => e.preventDefault()}
                  onChange={() => {
                    this.setState({ planName: "family" });
                    this.setState({ sf: false, mf: false });
                    this.props.getyearvalue(1);
                  }}
                  options={[
                    {
                      value: NEW_HOUSE_BLACKBACK,
                      label: this.renderLabel(
                        t("plans:single.blackback"),
                        PlanPriceEnum[NEW_HOUSE_BLACKBACK],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_HOUSE_BLACKBACK],
                    },
                    {
                      value: NEW_HOUSE_SILVERBACK,
                      label: this.renderLabel(
                        t("plans:single.silverback"),
                        PlanPriceEnum[NEW_HOUSE_SILVERBACK],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_HOUSE_SILVERBACK],
                    },
                    {
                      value: NEW_HOUSE_KING_KONG,
                      label: this.renderLabel(
                        t("plans:single.kingKong"),
                        PlanPriceEnum[NEW_HOUSE_KING_KONG],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_HOUSE_KING_KONG],
                    },
                  ]}
                />
                <p style={{ color: "black", textAlign: "left" }}>
                  {t("plans:newConstruction.newCondo")}
                </p>
                <Field
                  name="planOption"
                  component={renderRadioGroup}
                  validate={isRequired}
                  onBlur={(e) => e.preventDefault()}
                  onChange={() => {
                    this.setState({ planName: "family" });
                    this.setState({ sf: false, mf: false });
                    this.props.getyearvalue(1);
                  }}
                  options={[
                    {
                      value: NEW_CONDO_BLACKBACK,
                      label: this.renderLabel(
                        t("plans:single.blackback"),
                        PlanPriceEnum[NEW_CONDO_BLACKBACK],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_CONDO_BLACKBACK],
                    },
                    {
                      value: NEW_CONDO_SILVERBACK,
                      label: this.renderLabel(
                        t("plans:single.silverback"),
                        PlanPriceEnum[NEW_CONDO_SILVERBACK],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_CONDO_SILVERBACK],
                    },
                    {
                      value: NEW_CONDO_KING_KONG,
                      label: this.renderLabel(
                        t("plans:single.kingKong"),
                        PlanPriceEnum[NEW_CONDO_KING_KONG],
                        size,
                        1
                      ),
                      disabled:
                        isLimited &&
                        limitedAmount < PlanPriceEnum[NEW_CONDO_KING_KONG],
                    },
                  ]}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Styled.FormGroup>
    );
  }
}

export default compose(
  // withStyles(styles),
  withTranslation(["common", "plans"])
)(Plans);
