import React from "react";
import {
  Route,
  Routes,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";

import Landing from "./landing";
import Orders from "./orders";
import Account from "./account";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("*");

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Landing navigate={navigate} path={location.pathname} />}
      />
      <Route path={`account`} element={<Account navigate={navigate} />} />
      <Route
        path={`order/*`}
        element={
          <Orders match={match} location={location} navigate={navigate} />
        }
      />
    </Routes>
  );
};
