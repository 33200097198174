import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {createBrowserHistory as createHistory} from 'history';

import rootReducer from './reducers';

export const history = createHistory();

const reduxStore = (initialState = {}) => {
  const middleware = [thunk];

  const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer,
    initialState,
    composedEnhancers(applyMiddleware(...middleware))
  );

  return store;
};

const store = reduxStore();

export default store;
