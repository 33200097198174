import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    Grid,
    Button
  } from '@mui/material';

const Captcha = (props) => {
  
    const[isdisable,setIsDisable]= useState(true)

     const onRecaptcha = (response) => {
        
        if(response)
        {
            setIsDisable(!Boolean(response));
        }
        
    }
        
      
    return(
        <div>
            <Card >
               <CardHeader title="Please verify to confirm the order" />
               <Divider />
               <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <ReCAPTCHA
                            sitekey='6Lca3k8UAAAAALTNS6xmspck9Cx1nuu6Az_R-3A4'
                            onChange={(res)=>{onRecaptcha(res)}}
                            />
                    </Grid>
                    <Grid item md={12} xs={12} style={{marginTop:'1rem'}}>
                        <Button
                            style={{width: '25%', marginRight:"10px"}}
                            color="primary"
                            variant="contained"
                            disabled={isdisable}
                            onClick={() => props.handleSubmit()}>
                            Confirm
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => props.closeCaptchaPopup()}>
                            Cancel
                        </Button>
                    </Grid>

                  </Grid>
                </CardContent>
                   
            </Card>
        </div>
    )
};

export default Captcha;