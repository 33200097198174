import {
  FUNCTION_API,
  REFRESH_TOKEN,
  REDIRECT_URI,
  CLIENT_ID,
  CLIENT_SECRET,
  ZohoApi,
} from "constants/firebase";
import { getAccessTokenCookie, setAccessTokenCookie } from "helpers/utils";
import moment from "moment";

export default class ZcrmService {
  static getToken() {
    const url = `https://accounts.zoho.com/oauth/v2/token?refresh_token=${REFRESH_TOKEN}%26redirect_uri=${REDIRECT_URI}%26client_id=${CLIENT_ID}%26client_secret=${CLIENT_SECRET}%26grant_type=refresh_token`;
    // const token = getAccessTokenCookie();
    // if (token) return Promise.resolve({ access_token: token });
    return fetch(`${ZohoApi}/getzohotoken?url=${url}`, {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        // setAccessTokenCookie(data);
        return data;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  static generateLead(values, userdata, access_token, isListingCoverage) {
    // static upsertLead(values, userdata, access_token, isListingCoverage) {
    let houseSize = "<6000";
    if (values.bigHouse) {
      if (values.houseSize === 1) {
        houseSize = "6000 - 7000";
      }
      if (values.houseSize === 2) {
        houseSize = "7000 - 8000";
      }
      if (values.houseSize === 3) {
        houseSize = "8000 - 9000";
      }
      if (values.houseSize === 4) {
        houseSize = "9000 - 10000";
      }
      if (values.houseSize === 5) {
        houseSize = ">10000";
      }
    }
    return fetch(`${ZohoApi}/generatelead`, {
      // return fetch(`${ZohoApi}/upsertLead`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${access_token}`,
      }),
      body: JSON.stringify({
        data: [
          {
            Address_1: values.street,
            Address_2: values.unit,
            Cell_Phone: isListingCoverage
              ? values.sellerPhone !== null
                ? values.sellerPhone
                : ""
              : values.buyerPhone !== null
              ? values.buyerPhone
              : "",
            City: values.city,
            Company: "Silverback",
            Create_Date: moment.utc().format("YYYY-MM-DD"),
            Email: isListingCoverage
              ? values.sellerEmail !== null || values.sellerEmail !== undefined
                ? values.sellerEmail
                : ""
              : values.buyerEmail !== null || values.buyerEmail !== undefined
              ? values.buyerEmail
              : "",
            First_Name: isListingCoverage
              ? values.sellerName !== undefined
                ? values.sellerName.substr(0, values.sellerName.indexOf(" "))
                : values.sellerEmail !== undefined
                ? values.sellerEmail.substr(0, values.sellerEmail.indexOf("@"))
                : "Blank"
              : values.buyerName !== undefined
              ? values.buyerName.substr(0, values.buyerName.indexOf(" "))
              : values.buyerEmail !== undefined
              ? values.buyerEmail.substr(0, values.buyerEmail.indexOf("@"))
              : "Blank",
            Home_Phone: isListingCoverage
              ? values.sellerPhone !== null || values.sellerName !== undefined
                ? values.sellerPhone
                : ""
              : values.buyerPhone !== null || values.buyerName !== undefined
              ? values.buyerPhone
              : "",
            Home_Size_Sq_Ft: houseSize,
            Last_Name: isListingCoverage
              ? values.sellerName !== undefined
                ? values.sellerName.substr(values.sellerName.indexOf(" ") + 1)
                : values.sellerEmail !== undefined
                ? values.sellerEmail.substr(0, values.sellerEmail.indexOf("@"))
                : "Line"
              : values.buyerName !== undefined
              ? values.buyerName.substr(values.buyerName.indexOf(" ") + 1)
              : values.buyerEmail !== undefined
              ? values.buyerEmail.substr(0, values.buyerEmail.indexOf("@"))
              : "Line",
            Lead_Type: "Real Estate",
            Preferred_Phone: isListingCoverage
              ? values.sellerPhone !== null
                ? values.sellerPhone
                : ""
              : values.buyerPhone !== null
              ? values.buyerPhone
              : "",
            Source: "Website",
            State_1: values.state,
            Zip_Code: values.postal,
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
      }),
    }).then((res) => res.json());
  }

  static generateOrder(
    buyer_credit_value,
    access_token,
    input,
    type,
    isListingCoverage,
    transactionID,
    prepaidAmount
  ) {
    let estClosingDate;
    console.log(moment.utc().format("YYYY-MM-DD"), "momentdateeeee");
    if (!isListingCoverage) {
      estClosingDate = input.values?.closingDate
        ? typeof input.values?.closingDate === "object"
          ? moment(input.values?.closingDate).format("YYYY-MM-DD")
          : moment.utc(input.values.closingDate).local().format("YYYY-MM-DD")
        : // new Date(input.values.closingDate).toISOString().split("T")[0]
          //  moment(input.values?.closingDate, "DD-MM-YYYY").format("YYYY-MM-DD")
          "";
      /*let estDate = input.values.closingDate;
      let  date = estDate.split('-');
      if (date[0].length === 2){
        estClosingDate = date[2]+'-'+date[0]+'-'+date[1];
      }
      else{
         estClosingDate = input.values.closingDate;
      }*/
    }

    let addEmail =
      type === 0
        ? input.values.additionalEmails !== undefined
          ? input.values.additionalEmails.split(",")
          : []
        : null;
    let order;
    let token = this.getToken();
    let JSONReq;
    let houseSize = "<6000";
    let addonArray =
      type === 0
        ? input.values.addons !== undefined
          ? input.values.addons.map((e) => e.replace(/_/g, " "))
          : []
        : null;
    if (buyer_credit_value > 0) {
      addonArray.push("BUYER CREDIT");
    } else {
      buyer_credit_value = 0;
    }
    if (type === 0) {
      if (input.values.bigHouse) {
        if (input.values.houseSize === 1) {
          houseSize = "6000-7000";
        }
        if (input.values.houseSize === 2) {
          houseSize = "7000-8000";
        }
        if (input.values.houseSize === 3) {
          houseSize = "8000-9000";
        }
        if (input.values.houseSize === 4) {
          houseSize = "9000-10000";
        }
        if (input.values.houseSize === 5) {
          houseSize = "10000 and up";
        }
      }
      JSONReq = [
        {
          Address_1: input.values.street,
          Address_2: input.values.unit,
          Buyer_Agent: input.related_id.buyer_id,
          City: input.values.city,
          Create_Date: moment.utc().format("YYYY-MM-DD"),
          Estimated_Close_Date: isListingCoverage ? "" : estClosingDate,
          Lead: input.related_id.lead_id,
          House_Size: houseSize,
          Length: isListingCoverage
            ? "6 Months"
            : input.values.mf_2years || input.values.sf_2years
            ? "2 Years"
            : input.values.planOption.includes("Construction")
            ? "3 Years"
            : "1 Year",
          Options: addonArray,
          Ordered_By: input.values.placedBy,
          Buyer_Credit_Amount: buyer_credit_value,
          Plan_Name: input.values.planOption?.replace(/_/g, " "),
          Plan_Type: "Real Estate",
          Email_1: addEmail[0] ? addEmail[0] : "",
          Email_2: addEmail[1] ? addEmail[1] : "",
          Email_3: addEmail[2] ? addEmail[2] : "",
          Email_4: addEmail[3] ? addEmail[3] : "",
          Additional_Email_Confirmation: input.values.additionalEmails,
          Additional_Comments: input.values.comments,
          Promo_Code_Amount: input.discount,
          Promo_Code_Name: input.values.promoCode,
          Property_Over_6000: input.values.bigHouse === "true" ? "Yes" : "No",
          Sales_Rep: input.values.areaManager,
          Seller_Agent_Info: input.related_id.seller_id,
          State: input.values.state,
          Status: transactionID ? "Active" : "Pending",
          Title_Escrow: input.related_id.escrow_id,
          Zip: input.values.postal,
          Transaction_ID: transactionID ? transactionID : "",
          Prepaid_Amount1: transactionID ? prepaidAmount : "",
          warranty_Paid:
            input.values.orderedBy === "through escrow"
              ? "through escrow"
              : "with the credit card",
        },
      ];
    }
    if (type === 1) {
      if (input.property.bigHouse) {
        if (input.property.squareFootage === 1) {
          houseSize = "6000-7000";
        }
        if (input.property.squareFootage === 2) {
          houseSize = "7000-8000";
        }
        if (input.property.squareFootage === 3) {
          houseSize = "8000-9000";
        }
        if (input.property.squareFootage === 4) {
          houseSize = "9000-10000";
        }
        if (input.property.squareFootage === 5) {
          houseSize = "10000 and up";
        }
      }
      JSONReq = [
        {
          Address_1: input.property.street,
          Address_2: input.property.unit,
          //   "Buyer_Agent": input.related_id.buyer_id,
          City: input.property.city,
          Create_Date: moment.utc().format("YYYY-MM-DD"),
          //   "Estimated_Close_Date": input.values.closingDate,
          Customer: input.contact_id,
          House_Size: houseSize,
          Length: "1 Year",
          Options:
            input.order.addons !== undefined
              ? input.order.addons.map((e) => e.replace(/_/g, " "))
              : [],
          Ordered_By: "Homeowner",
          Plan_Name: input.order.plan.replace(/_/g, " "),
          Plan_Type: "Consumer",
          Payment_Type: input.plantype,
          Total_Price:
            input.plantype === "monthly"
              ? input.order.total.monthly
              : input.order.total.annual,
          Promo_Code_Amount: input.coupon_amount,
          Promo_Code_Name: input.coupon_label,
          Property_Over_6000: input.property.bigHouse
            ? input.property.bigHouse === true
              ? "Yes"
              : "No"
            : "No",
          //   "Sales_Rep": input.values.areaManager,
          //   "Seller_Agent_Info": input.related_id.seller_id,
          State: input.property.state,
          Status: transactionID ? "Active" : "Pending",
          Transaction_ID: transactionID ? transactionID : "",
          Prepaid_Amount1: transactionID ? prepaidAmount : "",
          //   "Title_Escrow": input.related_id.escrow_id,
          Zip: input.property.postal,
          Email: input.email,
        },
      ];
    }
    return token
      .then((response) => {
        return fetch(`${ZohoApi}/generateOrder`, {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/JSON",
            Authorization: `Zoho-oauthtoken ${response.access_token}`,
          }),
          body: JSON.stringify({
            data: JSONReq,
            trigger: ["approval", "workflow", "blueprint"],
          }),
        }).then((res) => (order = res.json()));
      })
      .then((res) => order);
  }
  static searchUser(email, token, module) {
    return fetch(`${ZohoApi}/search?email=${email}&module=${module}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res.json());
  }

  static getContact(email, token) {
    return fetch(`${ZohoApi}/getContact?email=${email}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res);
  }

  static getAgent(email, token) {
    console.log(email);
    return fetch(`${ZohoApi}/getAgent?email=${email}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res.json());
  }

  static generateClaim(values, access_token, policyInfo) {
    let serviceCategory = {
      0: "Kitchen Appliance",
      1: "Interior Plumbing",
      2: "HVAC",
      3: "Interior Electrical System",
    };
    const jsonObject =
      values.services !== undefined ? values.services.map(JSON.stringify) : [];
    const uniqueSet = new Set(jsonObject);
    values.services = Array.from(uniqueSet).map(JSON.parse);
    console.log(values.services);
    let claimType =
      values.services.length > 0
        ? serviceCategory[values.services[0].category]
        : "";

    let notes = values.services
      .filter((selectedService) => {
        return selectedService.category !== values.services[0].category;
      })
      .map((filteredService) => {
        return filteredService.title;
      });

    let appliance = values.services
      .filter((selectedService) => {
        return selectedService.category === 0;
      })
      .map((filteredService) => {
        return filteredService.title;
      });
    let plumbing = values.services
      .filter((selectedService) => {
        return selectedService.category === 1;
      })
      .map((filteredService) => {
        return filteredService.title;
      });
    let hvac = values.services
      .filter((selectedService) => {
        return selectedService.category === 2;
      })
      .map((filteredService) => {
        return filteredService.title;
      });
    let electrical = values.services
      .filter((selectedService) => {
        return selectedService.category === 3;
      })
      .map((filteredService) => {
        return filteredService.title;
      });
    return fetch(`${ZohoApi}/generateClaim`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${access_token}`,
      }),
      body: JSON.stringify({
        data: [
          {
            Address: values.street,
            Brand: values.brand,
            City: values.city,
            Name: values.name,
            Claim_Type: claimType,
            Create_Date: moment.utc().format("YYYY-MM-DD"),
            Customer_Email: values.email,
            Contractor_Facing_Description: values.description,
            ReKey_of_locks_up_to_6: values.locks,
            ReKey_of_copy_keys_up_to_4: values.keys,
            Preferred_time_of_day: values.preferredTime,
            Added_From: window.location.hostname,
            Item_Service_HVAC: claimType === "HVAC" ? hvac : "",
            Item_Service_Interior_Electrical:
              claimType === "Interior Electrical System" ? electrical : "",
            Item_Service_Interior_Plumbing:
              claimType === "Interior Plumbing" ? plumbing : "",
            Item_Service_Kitchen_Appliances:
              claimType === "Kitchen Appliance" ? appliance : "",
            Notes: notes.join(),
            First_Name: values.name.substr(0, values.name.indexOf(" ")),
            Last_Name: values.name.substr(values.name.indexOf(" ") + 1),
            Model: values.model,
            Policy_Name:
              policyInfo.data.length > 0 ? policyInfo.data[0].id : "",
            Claim_Name: values.coveragePackage,
            State: values.state,
            Preferred_Phone: values.phone,
            Zip: values.postal,
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
      }),
    }).then((res) => res.json());
  }

  static generateContact(token, values) {
    console.log(values.property.bigHouse);
    let houseSize = "<6000";
    if (values.property.squareFootage) {
      if (values.property.squareFootage === 1) {
        houseSize = "6000-7000";
      }
      if (values.property.squareFootage === 2) {
        houseSize = "7000-8000";
      }
      if (values.property.squareFootage === 3) {
        houseSize = "8000-9000";
      }
      if (values.property.squareFootage === 4) {
        houseSize = "9000-10000";
      }
      if (values.property.squareFootage === 5) {
        houseSize = "10000 and up";
      }
    }
    return fetch(`${ZohoApi}/generateContact`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
      body: JSON.stringify({
        data: [
          {
            Address:
              values.property.street + " " + values.property.unit
                ? values.property.unit
                : "",
            Contact_Type: "Consumer",
            Email: values.email,
            First_Name:
              values.name != null
                ? values.name.substr(0, values.name.indexOf(" "))
                : values.email.substr(0, values.email.indexOf("@")),
            Home_Phone: values.phone != null ? values.phone : "",
            Home_Size_Sq_Ft: houseSize,
            Last_Name:
              values.name != null
                ? values.name.substr(values.name.indexOf(" ") + 1)
                : values.email.substr(0, values.email.indexOf("@")),
            Mailing_City: values.property.city,
            Mailing_State: values.property.state,
            Mailing_Street: values.property.street,
            Mailing_Zip: values.property.postal,
            Mobile: "",
            Options: "",
            Other_City: values.property.city,
            Other_Phone: "",
            Other_State: values.property.state,
            Other_Street: values.property.street,
            Other_Zip: values.property.postal,
            Over_5k_sq_ft:
              values.property.bigHouse !== undefined
                ? values.property.bigHouse
                : false,
            Phone: "",
            Plan: values.custom_plan === undefined ? "King Kong" : "Custom",
            Source: "Website",
          },
        ],
      }),
    }).then((res) => res.json());
  }

  static generatePolicy(token, values) {
    console.log(values);
    var houseSize = "<6000";
    if (values.property.squareFootage) {
      if (values.property.squareFootage === 1) {
        houseSize = "6000-7000";
      }
      if (values.property.squareFootage === 2) {
        houseSize = "7000-8000";
      }
      if (values.property.squareFootage === 3) {
        houseSize = "8000-9000";
      }
      if (values.property.squareFootage === 4) {
        houseSize = "9000-10000";
      }
      if (values.property.squareFootage === 5) {
        houseSize = "10000 and up";
      }
    }
    return fetch(`${ZohoApi}/generatePolicy`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
      body: JSON.stringify({
        data: [
          {
            Address_1: values.property.street,
            Address_2: values.property.unit,
            City: values.property.city,
            Customer: values.contact_id,
            Effective_Date: new Date().toISOString().split("T")[0],
            Length: "1 Year",
            Name: values.order.plan,
            Options: values.order.addons,
            Payment_Date: new Date().toISOString().split("T")[0],
            Payment_Type: values.plantype === "annual" ? "Annual" : "Monthly",
            Plan_Name: values.order.plan,
            Plan_Type: "Consumer",
            Property_Over_6000:
              values.property.bigHouse === true ? "Yes" : "No",
            House_Size: houseSize,
            Promo_Code_Amount: values.coupon_amount,
            Promo_Code_Name: values.coupon_label,
            State: values.property.state,
            Zip: values.property.postal,
            Total_Price:
              values.plantype === "monthly"
                ? values.order.total.monthly
                : values.order.total.annual,
            Notes:
              values.order.features === undefined
                ? ""
                : values.order.features.join(),
          },
        ],
        trigger: ["approval", "workflow", "blueprint"],
      }),
    }).then((res) => res.json());
  }

  static renewPolicy(
    token,
    addons,
    totalAmount,
    transactionID,
    paymentType,
    monthlyPrice,
    annualPrice
  ) {
    const policyID = localStorage.getItem("policyID");
    return fetch(`${ZohoApi}/renewPolicy`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
      body: JSON.stringify({
        policy_id: policyID,
        addons: addons.join("; "),
        transaction_id: transactionID,
        policy_amount: totalAmount.toString(),
        payment_type: paymentType === "monthly" ? "Monthly" : "Annual",
        monthly_renewal_price: monthlyPrice,
        annual_renewal_price: annualPrice,
        notes: "Policy Renewed From Website",
      }),
    }).then((res) => res.json());
  }

  static getAllPolicies(token, email) {
    return fetch(`${ZohoApi}/getAllPolicies`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
      body: JSON.stringify({
        email: email,
      }),
    }).then((res) => res.json());
  }

  static searchPolicy(token, policy_number) {
    return fetch(`${ZohoApi}/searchpolicy?policy_number=${policy_number}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res.json());
  }

  static upsertREpros(agentInfo, access_token) {
    return fetch(`${ZohoApi}/upsertREpros`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${access_token}`,
      }),
      body: JSON.stringify({
        data: [
          {
            // "Create_Date": (new Date()).toISOString().split('T')[0],
            Email: agentInfo.email,
            Company: agentInfo.company,
            First_Name: agentInfo.first_Name,
            Name: agentInfo.name,
            Mobile_Phone: agentInfo.phone,
          },
        ],
        duplicate_check_fields: ["Email"],
        trigger: ["approval", "workflow", "blueprint"],
      }),
    }).then((res) => res.json());
  }
  static createPdf(order_id) {
    const token = this.getToken();
    return fetch(`${ZohoApi}/pdfgeneration/createpdf/?order_id=${order_id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res.json());
  }

  static getPolicies(token, email) {
    return fetch(`${ZohoApi}/getPolicy?email=${email}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    })
      .then((res) => res.json())
      .catch((e) => console.log(e));
  }

  static getClaims(token, email) {
    return fetch(`${ZohoApi}/getClaims?email=${email}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/JSON",
        Authorization: `Zoho-oauthtoken ${token}`,
      }),
    }).then((res) => res.json());
  }

  static profileName(email) {
    return fetch(
      `${ZohoApi}/getCustomerNameV3`,
      // `https://us-central1-arun-techjays.cloudfunctions.net/getZohoCustomerName/getCustomerName`,
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/JSON",
        }),
        body: JSON.stringify({
          email: email,
        }),
      }
    ).then((res) => res.json());
  }
}
