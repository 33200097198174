import React from "react";
import Typography from "@mui/material/Typography";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";

import BannerImage from "assets/images/handshake.jpg";
import Section from "components/common/section";
import Helpers from "./helpers";
import * as Styled from "./styled";

class AgentServices extends React.Component {
  renderServices = ({ services, icons }) =>
    services?.map((service, index) => {
      const Icon = icons[index];

      return (
        <Styled.Service href={service} target="_blank" key={`service-${index}`}>
          {typeof Icon === "string" ? (
            <Styled.ImgIcon src={Icon} />
          ) : (
            <Icon className="icon" />
          )}
          <Typography className="subtitle1" color="inherit" variant="display1">
            {this.props.t(`services:agents.labels`)[index]}
          </Typography>
        </Styled.Service>
      );
    });
  normalise(lang) {
    if (lang.startsWith("en")) {
      return "en";
    } else {
      return lang;
    }
  }
  render() {
    const {
      t,
      i18n: { language },
    } = this.props;

    return (
      <Section image={BannerImage} imageMask isContrast={true}>
        <Helmet
          title={`${t("common:nameShort")}: ${t("services:agents.pageTitle")}`}
        />
        <Styled.Content>
          <Styled.Hero>
            <Typography
              sx={{ fontSize: { xs: "30px", md: "2.8rem" } }}
              className="h1"
              color="inherit"
              variant="h2"
            >
              {t("services:agents.header")}
            </Typography>
            <Typography className="heading" color="secondary" variant="h1">
              {t("services:agents.subHeader")}
            </Typography>
          </Styled.Hero>
          <Styled.Points>
            {this.renderServices(Helpers(this.normalise(language)))}
          </Styled.Points>
        </Styled.Content>
      </Section>
    );
  }
}

export default withTranslation(["common", "services"])(AgentServices);
