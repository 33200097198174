import styled from '@emotion/styled'
import { transparentize } from 'polished';

export const Content = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(180),
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const ImgIcon = styled('img')(({ theme }) => ({
  width: '65px',
  marginRight: '20px'
}));

export const Hero = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'right',
  width: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    textAlign: 'center',
    '& .heading': {
      color: theme.palette.secondary.dark,
      fontSize: 55,
    },
    '& .subheading': {
      fontSize: 30,
      marginBottom: 10,
    },
  },
}));

export const Points = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 45px`,
  flex: '1 0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: `0 10px`,
    flex: 'none',
  },
}));

export const Service = styled('a')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.grey['200'],
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textDecoration: 'none',
  padding: '30px 20px',
  transition: 'all 300ms ease-in-out',
  borderBottom: `3px solid rgba(255,255,255,0.1)`,
  width: '100%',

  '&:hover': {
    color: theme.palette.secondary.light,
    borderColor: transparentize(0.5, theme.palette.secondary.light),
    '& .icon': {
      color: theme.palette.primary.light,
    },
    '& img': {
      filter: 'brightness(.75)'
    },
  },

  '& .icon': {
    transition: 'color 500ms cubic-bezier(.67,.34,.53,.8)',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.light,
    fontSize: 65,
    marginRight: 20,
  },

  [theme.breakpoints.down('sm')]: {
    padding: 10,
    textAlign: 'left',
    '& .title': {
      fontSize: 24,
      fontWeight: 300,
    },
    '& .icon': {
      fontSize: 40,
      marginRight: 10,
    },
  },
}));
