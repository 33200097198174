import styled from '@emotion/styled'

export const Wrapper = styled('div')({}, ({ theme }) => ({
  background: `rgba(255,255,255,0.85)`,
  padding: `25px 30px`,
  width: '100%',
  borderRadius: 5,
  color: theme.palette.primary.main,
  boxShadow: theme.shadows[3],

  '& h1': {
    marginTop: 20,
  },
}));
