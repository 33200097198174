import { v4 as uuid } from 'uuid';
import Utils from 'helpers/utils';

export const Claim = (values) => {
  const fields = [
    'policy',
    'model',
    'serial',
    'brand',
    'coveragePackage',
    ['services', []],
    'description'
  ];
  const obj = Utils.createProjection(values, fields);
  obj.contact = Utils.createProjection(values, ['name', 'phone', 'email']);
  obj.address = Utils.createProjection(values, ['street', 'city', 'state', 'postal', ['unit', '']]);
  obj.rekey = Utils.createProjection(values, [['keys', 0], ['locks', 0], ['preferredTime', '']]);
  obj.id = uuid();
  obj.date = new Date().toISOString();
  if(values.waterHeaterType) {
    const heater = obj.services.indexOf('Water Heater');
    obj.services[heater] = `Water Heater: ${values.waterHeaterType}`
  }
  return obj;
}

//

export const Order = (values, amount, optionAmount, discount) => {
  console.log('modal',values);
  const fields = [
    ['closingDate', ''],
    ['placedBy', ''],
    ['planOption', 'Listing Coverage', 'plan'],
    ['planAmount', amount],
    ['id', uuid()],
    ['date', new Date().toISOString()],
    ['addons', [], 'options'],
    ['optionsAmount', optionAmount],
    ['discount', discount],
    ['promoCode', ''],
    ['orderAmount', amount + optionAmount],
    ['comments', ''],
    ['additionalEmails', ''],
    'areaManager'
  ];

  const order = Utils.createProjection(values, fields);
  order.property = Utils.createProjection(
    values,
    [ 'street', ['unit', ''], 'city', 'state', 'postal', ['country', 'USA'], ['large', values.bigHouse === 'true']]
  );

  order.buyer = Utils.createProjection(
    values,
    [['buyerName', '', 'name'], ['buyerEmail', '', 'email'], ['buyerPhone', '', 'phone']]
  );

  order.buyer.agent = Utils.createProjection(
    values,
    [
      ['buyerAgentName', '', 'name'],
      ['buyerAgentEmail', '', 'email'],
      ['buyerAgentPhone', '', 'phone'],
      ['buyerAgentCompany', '', 'company']
    ],
  );
  order.escrow = Utils.createProjection(
    values,
    [['escrowOfficer', '', 'name'], ['escrowEmail', '', 'email'], ['escrowPhone', '', 'phone'], ['escrowCompany', '', 'company']]
  );

  order.seller = Utils.createProjection(
    values,
    [['sellerName', '', 'name'], ['sellerEmail', '', 'email'], ['sellerPhone', '', 'phone']]
  );

  order.seller.agent = Utils.createProjection(
    values,
    [['sellerAgentName', '', 'name'], ['sellerAgentEmail', '', 'email'], ['sellerAgentPhone', '', 'phone'], ['sellerAgentCompany', '', 'company']]
  );
  console.log(order);
  return order;
};