import styled from "@emotion/styled";

export const FormGroup = styled("div")({}, ({ theme }) => ({
  padding: "10px 0",
  textAlign: "left",
  "& h3.label": {
    fontSize: 24,
    [theme.breakpoints.down("sm")]: { fontSize: 18 },
  },

  "& .label": {
    fontFamily: theme.typography.fontFamily2,
    textTransform: "capitalize",
    fontSize: 24,
    padding: "15px 5px",
  },

  "& .flex-row": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },

  "& .flex-column": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  "& .field-label": {
    color: theme.palette.primary.light,
    display: "flex",
    flexDirection: "row",
    textAlign: "left",

    "& .prefix": {
      fontWeight: 600,
      marginRight: 5,
      minWidth: 50,
    },
  },

  "& .disabled-field-label": {
    // color: theme.palette.primary.light,
    display: "flex",
    flexDirection: "row",
    textAlign: "left",

    "& .prefix": {
      fontWeight: 600,
      marginRight: 5,
      minWidth: 50,
    },
  },
}));

export const FlexRow = styled("div")(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    "&.top": {
      alignItems: "flex-start",
    },

    "&.column": {
      border: "1px solid #ccc",
      borderRadius: 5,
      padding: "0 10px",
      margin: "0 5px",
      width: "calc((100% / 3) - 10px)",
      justifyContent: "space-between",

      "& .label": {
        margin: 0,
        width: "100%",
        padding: "10px 0 20px",
      },
    },
  },
  ({ theme }) => ({
    "&.column": {
      boxShadow: theme.shadows[2],
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  })
);

export const FlexColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: "1 0 auto",
  width: "100%",
  height: "100%",
});

export const FlexWrap = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",

  "& .flex-wrap-item": {
    flex: "1 0 50%",
    margin: 0,
  },
});
