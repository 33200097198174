import React from "react";
import { string, number, func } from "prop-types";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import Button from "components/common/button";
import * as Styled from "./styled";

class PlanOption extends React.Component {
  static propTypes = {
    id: string.isRequired,
    title: string.isRequired,
    buttonLabel: string.isRequired,
    onSelection: func.isRequired,
    amount: number,
    minamount: number,
  };

  static defaultProps = {
    amount: null,
    minamount: 0,
  };

  constructor(props) {
    super(props);

    this.state = { value: props.amount || 0 };
  }

  handleClick = () => {
    if (this.props.minamount > 0 && this.props.minamount > this.state.value) {
      this.setState({ value: this.props.minamount });
      return;
    }

    this.props.onSelection(this.props.id, this.state.value);
  };

  handleInputChange = (e) => {
    this.setState({ value: (e.target.value * 1).toFixed(2) * 1 });
  };

  renderValue = () =>
    this.props.amount === null ? (
      <TextField
        className="input-amount"
        variant="standard"
        type="number"
        value={this.state.value === 0 ? "" : this.state.value}
        onChange={this.handleInputChange}
        fullWidth
      />
    ) : (
      <Typography variant="h1" color="secondary" gutterBottom>
        {`$${this.props.amount}`}
      </Typography>
    );

  render() {
    const { amount, title, buttonLabel, onSelection, ...rest } = this.props;

    return (
      <Styled.Card raised classes={{ root: "root" }} {...rest}>
        <Styled.CardContent>
          <Typography
            className="title"
            variant="h3"
            color="primary"
            align="center"
            gutterBottom
          >
            {this.props.title}
          </Typography>
          {this.renderValue()}
          <Button
            onClick={this.handleClick}
            color="secondary"
            variant="contain"
            sx={{ color: "#fff" }}
          >
            {buttonLabel}
          </Button>
        </Styled.CardContent>
      </Styled.Card>
    );
  }
}

export default PlanOption;
