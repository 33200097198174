import { initializeApp, getApps } from "firebase/app";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import "@firebase/functions";

import { config } from "constants/firebase";
import store from "store";
import { onAuthStateChange } from "store/actions/auth";
import { getDatabase, ref as Ref, get as Get } from "firebase/database";

const firebase = initializeApp(config);

export const auth = getAuth(firebase);

onAuthStateChanged(auth, (user) => {
  store.dispatch(onAuthStateChange(user));
});

export const getUser = async () => {
  const user = auth.currentUser;

  if (!user) {
    try {
      await signInAnonymously(auth);
    } catch (error) {
      console.log(error, "error in auht480");
    }

    return auth.currentUser;
  }

  return user;
};

export const get = (ref) => {
  const db = getDatabase();
  const dbRef = Ref(db, ref);
  return Get(dbRef).then((snapshot) => snapshot.val());
};

export default firebase;
