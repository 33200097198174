import React from "react";
import styled from "@emotion/styled";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Modal,
} from "@mui/material";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { withTranslation } from "react-i18next";

import { renderCheckbox } from "helpers/form";
import { Info, Search } from "@mui/icons-material";
import DetailsModal from "components/common/Modal";

class Addons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      selectedAddon: "",
    };
  }

  render() {
    const { t, items } = this.props;
    const newoptions = ["Brand For Brand", "Sprinkler Timer & System"];
    return (
      <StyledCard>
        <CardHeader
          title={t("aLaCarte")}
          titleTypographyProps={{ color: "secondary" }}
        />
        <CardContent>
          <List dense>
            <form>
              {items.map((addon, index) => (
                <ListItem dense divider key={`addon-${index}`} disableGutters>
                  <ListItemText
                    primary={addon.label}
                    secondary={`$${addon.price}`}
                    secondaryTypographyProps={{ color: "secondary" }}
                  />
                  {addon.label !== "Buyer Credit" &&
                    addon.label !== "Detached Building Coverage" && (
                      <ListItemIcon
                        onClick={() => {
                          this.setState({ selectedAddon: addon.label });
                          this.setState({ isOpen: true });
                        }}
                        sx={{ cursor: "pointer" }}
                        children={
                          <Info
                            sx={{
                              width: "20px",
                              color: "rgb(51, 119, 151)",
                              marginLeft: "-8px",
                            }}
                          />
                        }
                      />
                    )}
                  <ListItemSecondaryAction>
                    <span
                      style={{
                        fontSize: "12px",
                        fontStyle: "italic",
                        color: "#00557E",
                        fontWeight: "bold",
                        letterSpacing: "1px",
                      }}
                    >
                      {newoptions.includes(addon.label) ? "(New)" : ""}
                    </span>

                    <Field name={addon.label} component={renderCheckbox} />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </form>
          </List>
        </CardContent>
        <DetailsModal
          open={this.state.isOpen && this.state.selectedAddon}
          onClose={() => this.setState({ isOpen: false })}
          selectedAddon={this.state.selectedAddon}
        />
      </StyledCard>
    );
  }
}

const StyledCard = styled(Card)(() => ({
  height: "100%",
}));

export default compose(
  withTranslation("homeowner"),
  reduxForm({
    form: "homeowner-plan-addons",
    destroyOnUnmount: false,
  })
)(Addons);
