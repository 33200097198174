import styled from "@emotion/styled";

export const Content = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "150px 0",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
  },
}));

export const Form = styled("form")(({ theme }) => ({
  width: "100%",
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing.unit,
  },
}));
