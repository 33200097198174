import React, { Component } from "react";
import ReactDOM from "react-dom";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import Helmet from "react-helmet";
// import firebase from 'firebase/compat/app';
import { initializeApp, getApps } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { withTranslation } from "react-i18next";
import i18n from "i18n";

import { config } from "constants/firebase";
import { ReferenceURL } from "constants/plans";

import cart from "services/shopping-cart";
import Banner from "./components/banner";
import Benefits from "./components/benefits";
import StartingForm from "./components/starting-form";
import ComparePlans from "./components/compare-plans";
import * as Styled from "./styled";
import firebase from "services/firebase";

export class Home extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  handleRoute = (url) => () => this.props.handleNavigation(url);

  handleStartingForm = ({ street, city, postal, state }) => {
    cart.address = { street, city, state, zip: postal };
    this.handleRoute("/plans/start")();
  };

  handleScrollToRef = (ref) => () => {
    const node = ref.current;
    if (node) {
      window.scrollTo({
        top: node.offsetTop,
        behavior: "smooth",
      });
    }
  };
  handleDownload = () => {
    let app = firebase;
    if (getApps().length < 1) {
      app = initializeApp(config);
    }

    const storage = getStorage(app);
    const storageRef = ref(storage, ReferenceURL);
    getDownloadURL(storageRef).then((url) => {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.href = url;
      link.click();
    });
  };

  componentDidMount() {
    const { match } = this.props;
    if (match?.params.locale) {
      i18n.changeLanguage(match?.params.locale);
      this.handleRoute("/")();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Styled.Wrapper>
        <Helmet title={t("nameFull")} bodyAttributes={{ class: "home" }} />
        <Banner
          onNewOrder={this.handleRoute("/plans/start")}
          onComparePlans={this.handleScrollToRef(this.myRef)}
          onAgentServices={this.handleRoute("/agents/services")}
        />
        <StartingForm onSubmit={this.handleStartingForm} />
        <Benefits handleClick={this.handleRoute("/plans/start")} />
        <ComparePlans myRef={this.myRef} onClick={this.handleDownload} />
      </Styled.Wrapper>
    );
  }
}

export default compose(
  withTranslation(["common", "home"]),
  reduxForm({
    form: "homeowner-plan-addons",
    destroyOnUnmount: true,
  })
)(Home);
