import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import ReCAPTCHA from "react-google-recaptcha";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import { formValueSelector, change } from "redux-form";
import {
  isRequired,
  isNumberInRange,
  isNumber,
  isInteger,
  isValidNumber,
  isEmail,
  lengthOne,
  isValidEmail,
} from "helpers/validators";
import { renderTextField, renderSelectField } from "helpers/form";
import Button from "components/common/button";
import TextField from "@mui/material/TextField";
import * as Styled from "./styled";
import { getUserDisplayName, getUserEmail, getUserPhone } from "store/reducers";
import { connect } from "react-redux";

const isInRekeyLockRange = (...args) =>
  isNumberInRange({ from: 1, to: 4 }, ...args);
const isInRekeyKeyRange = (...args) =>
  isNumberInRange({ from: 1, to: 2 }, ...args);

const serviceCategory = Object.freeze({
  Appliance: 0,
  Plumbing: 1,
  HVAC: 2,
  Electrical: 3,
  Miscellaneous: 4,
});
const selector = formValueSelector("claims");
class ClaimForm extends Component {
  state = {
    verified: false,
    category: -1,
    // values: {
    //   name: this.props.claims?.contact?.name
    //     ? this.props.claims?.contact?.name
    //     : "",
    //   postal: this.props.claims?.address?.postal
    //     ? this.props.address.postal
    //     : "",
    //   city: this.props.claims?.address?.city
    //     ? this.props.claims.address.city
    //     : "",
    //   street: this.props.claims?.address?.street
    //     ? this.props.claims.address.street
    //     : "",
    //   coveragePackage: this.props?.claims
    //     ? this.props.claims.coveragePackage
    //     : "",
    //   unit: this.props.contact?.postal,
    //   policy: this.props?.claims ? this.props.claims.policy : "",
    // },
  };

  onRecaptcha = (res) => {
    this.setState({ verified: Boolean(res) });
  };
  inputsRef = React.createRef();
  handleTextChange(event) {
    const { name, value } = event.target;
    this.props.changeFieldValue(name, value);
  }

  renderWaterHeaterOpts = (option, input) => {
    const label = "Water Heater";
    if (option !== label || input.value.indexOf(label) === -1) {
      return;
    }
    const { t } = this.props;
    const changeKeyOpts = [
      { value: "Gas", label: "Gas" },
      { value: "Electric", label: "Electric" },
      { value: "Tankless", label: "Tankless" },
    ];
    return (
      <Field
        name="waterHeaterType"
        label={t("claims:waterHeater:title")}
        component={renderSelectField}
        options={changeKeyOpts}
        validate={isRequired}
      />
    );
  };

  renderServiceCheckbox = (option, input, disabled) => {
    const index = Array.isArray(input.value)
      ? input.value?.findIndex(
          (obj) =>
            obj.title === option.title && obj.category === option.category
        )
      : -1;
    return (
      <Grid item xs={12} key={option?.title}>
        <FormControlLabel
          label={option.title}
          control={<Checkbox />}
          value={option}
          // checked={input.value.indexOf(option) !== -1}
          checked={index !== -1}
          onChange={(event) => {
            const newValue = [...input.value];
            if (event.target.checked) {
              if (newValue.length < 2)
                this.setState({ category: option.category });
              newValue.push(option);
            } else {
              // newValue.splice(newValue.indexOf(option), 1);
              newValue.splice(index, 1);
              if (!newValue.length) this.setState({ category: -1 });
            }
            return input.onChange(newValue);
          }}
          disabled={disabled}
        />
        {/* {console.log(input.value, "Dd", option, "input and options +++")} */}
        {this.renderWaterHeaterOpts(option.title, input)}
      </Grid>
    );
  };

  categoriseServices = (options) => {
    const appliance = [];
    const electrical = [];
    const hvac = [];
    const plumbing = [];
    const Miscellaneous = [];
    options.forEach((option) => {
      switch (option.category) {
        case serviceCategory.Appliance:
          appliance.push(option);
          break;
        case serviceCategory.Electrical:
          electrical.push(option);
          break;
        case serviceCategory.HVAC:
          hvac.push(option);
          break;
        case serviceCategory.Plumbing:
          plumbing.push(option);
          break;
        case serviceCategory.Miscellaneous:
          Miscellaneous.push(option);
        default:
          return;
      }
    });
    return {
      appliance,
      electrical,
      hvac,
      plumbing,
      Miscellaneous,
    };
  };
  renderServiceCategory = (options, input, type) => {
    const { t } = this.props;
    const disabled = this.state.category !== type && this.state.category !== -1;
    return (
      <Grid
        item
        xs={12}
        sm={4}
        md={3}
        sx={{ flexBasis: { md: "20%" }, maxWidth: { md: "20%" } }}
      >
        <Typography color="secondary" variant="subtitle1">
          {t(`claims:services:optionTitles`)[type]}
        </Typography>
        {options.map((option) =>
          this.renderServiceCheckbox(option, input, disabled)
        )}
      </Grid>
    );
  };
  renderServices = ({ options, input }) => {
    const { appliance, electrical, hvac, plumbing, Miscellaneous } =
      this.categoriseServices(options);
    return (
      <Grid container spacing={1}>
        {this.renderServiceCategory(
          appliance,
          input,
          serviceCategory.Appliance
        )}
        {this.renderServiceCategory(plumbing, input, serviceCategory.Plumbing)}
        {this.renderServiceCategory(hvac, input, serviceCategory.HVAC)}
        {this.renderServiceCategory(
          electrical,
          input,
          serviceCategory.Electrical
        )}
        {this.renderServiceCategory(
          Miscellaneous,
          input,
          serviceCategory.Miscellaneous
        )}
      </Grid>
    );
  };

  formatPhoneNumber = (phoneNumber) => {
    var cleaned = ("" + phoneNumber).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const value = ["(", match[2], ")", match[3], "-", match[4]].join("");
      return value;
    }
  };
  // inputRef = React.createRef();
  changeState = (key, val) => this.setState({ [key]: val });

  renderRekey = () => {
    const { t } = this.props;
    const { locks, keys } = this.state;
    const changeKeyOpts = [
      { value: "Early Morning", label: t("claims:rekey.time.earlyMorning") },
      { value: "Late Morning", label: t("claims:rekey.time.lateMorning") },
      {
        value: "Early Afternoon",
        label: t("claims:rekey.time.earlyAfternoon"),
      },
      { value: "Late Afternoon", label: t("claims:rekey.time.lateAfternoon") },
    ];

    return (
      <Grid container spacing={1} style={{ marginBottom: 40 }}>
        <Grid item xs={12}>
          <Typography color="secondary" variant="h5">
            {t("claims:rekey:title")}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginBottom: 20 }}>
          <Field
            name="locks"
            label={`${t("claims:rekey:locks")} (${t("claims:rekey:upTo")} 4)`}
            component={renderTextField}
            validate={locks ? [isNumber, isInteger, isInRekeyLockRange] : null}
            onChange={(value) => this.changeState("locks", value.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginBottom: 20 }}>
          <Field
            name="keys"
            label={`${t("claims:rekey:keys")} (${t("claims:rekey:upTo")} 2)`}
            component={renderTextField}
            validate={keys ? [isNumber, isInteger, isInRekeyKeyRange] : null}
            onChange={(value) => this.changeState("keys", value.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ marginBottom: 20 }}>
          <Field
            name="preferredTime"
            label={t("claims:rekey.time.title")}
            component={renderSelectField}
            options={changeKeyOpts}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { handleSubmit, t, claims } = this.props;
    return (
      <Styled.Wrapper>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Typography
                color="inherit"
                variant="h3"
                style={{ paddingTop: "20px" }}
              >
                {t("claims:contactInformation")}
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  {claims.policy === undefined && (
                    <Field
                      name="policy"
                      label={t("claims:policyNumber")}
                      component={renderTextField}
                      validate={lengthOne}
                    />
                  )}
                  {claims.policy !== undefined && (
                    <TextField
                      name="policy"
                      defaultValue={claims.policy || ""}
                      label={t("claims:policyNumber")}
                      validate={isRequired}
                      variant="standard"
                      helperText={lengthOne(this.props.Policy)}
                      error={Boolean(lengthOne(this.props.Policy))}
                      onChange={(e) => this.handleTextChange(e)}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {claims.address === undefined && (
                    <Field
                      name="coveragePackage"
                      label={t("claims:coveragePackage")}
                      component={renderTextField}
                      validate={lengthOne}
                    />
                  )}
                  {claims.address !== undefined && (
                    <TextField
                      name="coveragePackage"
                      defaultValue={claims.coveragePackage || ""}
                      label={t("claims:coveragePackage")}
                      validate={isRequired}
                      helperText={lengthOne(this.props.CoveragePackage)}
                      error={Boolean(lengthOne(this.props.CoveragePackage))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={12}>
                  {(!claims || claims.Name === undefined) && (
                    <Field
                      name="name"
                      label={t("name")}
                      component={renderTextField}
                      validate={[isRequired, lengthOne]}
                    />
                  )}
                  {claims && claims.Name !== undefined && (
                    <TextField
                      name="name"
                      defaultValue={claims.Name || ""}
                      label={t("name")}
                      helperText={lengthOne(this.props.NameState)}
                      error={Boolean(lengthOne(this.props.NameState))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {claims.Phone !== undefined ? (
                    <TextField
                      name="phone"
                      label={t("phone")}
                      defaultValue={claims.Phone || ""}
                      validate={[isRequired, isValidNumber]}
                      // onMouseOut={(event) => {
                      //   if (event.target.value) {
                      //     this.props.change(
                      //       "phone",
                      //       this.formatPhoneNumber(event.target.value)
                      //     );
                      //   }
                      // }}
                      onBlur={(event) => {
                        if (event.target.value) {
                          this.props.change(
                            "phone",
                            this.formatPhoneNumber(event.target.value)
                          );
                        }
                      }}
                      variant="standard"
                      helperText={isValidNumber(this.props.PhoneState)}
                      error={Boolean(isValidNumber(this.props.PhoneState))}
                      onChange={(e) => {
                        if (e.target.value && e.target?.value.length > 9) {
                          setTimeout(() => {
                            this.props.change(
                              "phone",
                              this.formatPhoneNumber(e.target.value)
                            );
                          }, 700);
                          // this.props.change("phone", e.target.value);
                        }
                        this.handleTextChange(e);
                      }}
                    />
                  ) : (
                    <Field
                      name="phone"
                      label={t("phone")}
                      component={renderTextField}
                      validate={[isRequired, isValidNumber]}
                      onMouseOut={(event) => {
                        if (event.target.value) {
                          this.props.change(
                            "phone",
                            this.formatPhoneNumber(event.target.value)
                          );
                        }
                      }}
                      onBlur={(event) => {
                        if (event.target.value) {
                          this.props.change(
                            "phone",
                            this.formatPhoneNumber(event.target.value)
                          );
                        }
                      }}
                      // ref={this.inputsRef}
                      // inputRef={this.props.inputRef}
                    />
                  )}
                </Grid>
                <Grid item xs={6}>
                  {claims.Email ? (
                    <TextField
                      name="email"
                      label={t("email")}
                      validate={[isRequired, isValidEmail]}
                      defaultValue={claims.Email}
                      variant="standard"
                      helperText={isValidEmail(this.props.EmailState)}
                      error={Boolean(isValidEmail(this.props.EmailState))}
                      onChange={(e) => this.handleTextChange(e)}
                    />
                  ) : (
                    <Field
                      name="email"
                      label={t("email")}
                      component={renderTextField}
                      validate={[isRequired, isValidEmail]}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                color="inherit"
                variant="h3"
                style={{ paddingTop: "20px" }}
              >
                {t("claims:propertyInformation")}
              </Typography>
              <Grid container spacing={1}>
                <Grid item sm={8} xs={12}>
                  {(!claims.address || claims.address.street === undefined) && (
                    <Field
                      name="street"
                      label={t("street")}
                      component={renderTextField}
                      validate={lengthOne}
                    />
                  )}
                  {claims.address && claims.address.street !== undefined && (
                    <TextField
                      name="street"
                      defaultValue={claims.address.street || ""}
                      label={t("street")}
                      validate={isRequired}
                      // inputRef={(el) => (this.inputsRef.address.street = el)}
                      // value={this.state.values.street}
                      // onChange={this.handleChange.bind(this)}
                      helperText={lengthOne(this.props.Street)}
                      error={Boolean(lengthOne(this.props.Street))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                  {console.log(claims.address?.street, "9010")}
                </Grid>
                <Grid item sm={4} xs={12}>
                  {(claims.address === undefined || !claims.address.unit) && (
                    <Field
                      name="unit"
                      label={t("unit")}
                      component={renderTextField}
                      // inputRef={this.props.inputRef}
                    />
                  )}
                  {claims.address && claims.address.unit && (
                    <TextField
                      name="unit"
                      defaultValue={claims.address ? claims.address.unit : ""}
                      label={t("unit")}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
                <Grid item sm={4} xs={12}>
                  {(!claims.address || claims.address.city === undefined) && (
                    <Field
                      name="city"
                      label={t("city")}
                      component={renderTextField}
                      validate={lengthOne}
                    />
                  )}
                  {claims.address && claims.address.city !== undefined && (
                    <TextField
                      name="city"
                      defaultValue={claims.address ? claims.address.city : ""}
                      label={t("city")}
                      helperText={lengthOne(this.props.City)}
                      error={Boolean(lengthOne(this.props.City))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
                <Grid item sm={4} xs={6}>
                  {(!claims.address || claims.address.state === undefined) && (
                    <Field
                      name="state"
                      label={t("state")}
                      component={renderTextField}
                      validate={lengthOne}
                    />
                  )}
                  {claims.address && claims.address.state !== undefined && (
                    <TextField
                      name="state"
                      defaultValue={claims.address ? claims.address.state : ""}
                      label={t("state")}
                      validate={isRequired}
                      helperText={lengthOne(this.props.State)}
                      error={Boolean(lengthOne(this.props.State))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
                <Grid item sm={4} xs={6}>
                  {(!claims.address || claims.address.postal === undefined) && (
                    <Field
                      name="postal"
                      label={t("postal")}
                      component={renderTextField}
                      validate={lengthOne}
                      variant="standard"
                    />
                  )}
                  {claims.address && claims.address.postal !== undefined && (
                    <TextField
                      name="postal"
                      defaultValue={claims.address ? claims.address.postal : ""}
                      label={t("postal")}
                      validate={isRequired}
                      helperText={lengthOne(this.props.Postal)}
                      error={Boolean(lengthOne(this.props.Postal))}
                      onChange={(e) => this.handleTextChange(e)}
                      variant="standard"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography
                color="inherit"
                variant="h3"
                style={{ paddingTop: "20px" }}
              >
                {t("claims:services.title")}
              </Typography>
              <Typography
                color="secondary"
                variant="body2"
                style={{
                  lineHeight: "1.71429em",
                  fontFamily: `"Roboto", "Helvetica", "Arial", "sans-serif"`,
                }}
              >
                {t("claims:services.disclaimer")}
              </Typography>
              {this.renderRekey()}
              <Field
                name="services"
                options={t("claims:services.options")}
                component={this.renderServices}
              />
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography color="inherit" variant="h3">
                  {t("claims:details.title")}
                </Typography>
                <Field
                  name="brand"
                  label={t("claims:details.brand")}
                  component={renderTextField}
                />
                <Field
                  name="model"
                  label={t("claims:details.model")}
                  component={renderTextField}
                />
                <Field
                  name="serial"
                  label={t("claims:details.serialNumber")}
                  component={renderTextField}
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={2}>
                <Typography color="inherit" variant="h3">
                  {t('claims:serviceCall.title')}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography color="secondary" variant="h3">
                  $60
                </Typography>
              </Grid> */}
            <Grid item xs={12}>
              <Typography color="inherit" variant="h3">
                {t("claims:description")}
              </Typography>
              <Field
                name="description"
                multiline
                rows={10}
                label={t("claims:detail")}
                component={renderTextField}
                validate={isRequired}
              />
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }}>
            <ReCAPTCHA
              sitekey="6Lca3k8UAAAAALTNS6xmspck9Cx1nuu6Az_R-3A4"
              onChange={this.onRecaptcha}
            />
          </div>

          <Button
            fullWidth
            type="submit"
            color="primary"
            disabled={this.props.isSubmitting || !this.state.verified}
            style={{ marginTop: 20, color: "#fff" }}
            sx={{
              "&.Mui-disabled": {
                color: "rgba(0, 0, 0, 0.26) !important",
                backgroundColor: "rgba(0, 0, 0, 0.12)",
              },
            }}
          >
            {t("submit")}
          </Button>
        </form>
      </Styled.Wrapper>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  NameState: selector(state, "name"),
  EmailState: selector(state, "email"),
  Policy: selector(state, "policy"),
  CoveragePackage: selector(state, "coveragePackage"),
  PhoneState: selector(state, "phone"),
  Street: selector(state, "street"),
  City: selector(state, "city"),
  State: selector(state, "state"),
  Postal: selector(state, "postal"),
  unit: selector(state, "unit"),
  name: getUserDisplayName(state),
  //  <TextField
  email: getUserEmail(state),
  phone: getUserPhone(state),
  initialValues: {
    name:
      ownProps.claims.Customer !== undefined && ownProps.claims.Customer?.name
        ? ownProps.claims.Customer.name
        : "",
    email:
      ownProps.claims !== undefined && ownProps.claims?.Email
        ? ownProps.claims.Email
        : "",
    policy:
      ownProps.claims !== undefined && ownProps.claims?.Policy_Number
        ? ownProps.claims.Policy_Number
        : "",
    coveragePackage:
      ownProps.claims !== undefined && ownProps.claims?.Plan_Name
        ? ownProps.claims.Plan_Name
        : "",
    phone:
      ownProps.claims !== undefined && ownProps.claims?.Phone
        ? ownProps.claims.Phone
        : "",
    street:
      ownProps.claims !== undefined && ownProps.claims?.Address_1 !== null
        ? ownProps.claims.Address_1
        : "",
    unit:
      ownProps.claims !== undefined && ownProps.claims?.Address_2 !== null
        ? ownProps.claims.Address_2
        : "",
    city:
      ownProps.claims !== undefined && ownProps.claims?.City !== null
        ? ownProps.claims.City
        : "",
    state:
      ownProps.claims !== undefined && ownProps.claims?.State !== null
        ? ownProps.claims.State
        : "",
    postal:
      ownProps.claims !== undefined && ownProps.claims?.Zip !== null
        ? ownProps.claims.Zip
        : "",
  },
});
const mapDispatchToProps = (dispatch) => ({
  changeFieldValue: (field, value) => dispatch(change("claims", field, value)),
});
export default compose(
  withTranslation(["common", "claims"]),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: "claims",
    enableReinitialize: true,
  })
)(ClaimForm);
