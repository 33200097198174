import React from "react";
import { compose } from "redux";
import { reduxForm, Field } from "redux-form";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import { withTranslation } from "react-i18next";

import Section from "components/common/section";
import Button from "components/common/button";
import { renderTextField } from "helpers/form";
import * as Validator from "helpers/validators";
import styles from "./repair.module.css";
const RequestQuote = ({
  t,
  classes,
  handleSubmit,
  pristine,
  submitting,
  style,
  myref,
  fromaboutus,
}) => (
  <Section className={styles.section} shadow={!fromaboutus} style={style}>
    <Card>
      <CardContent className={styles?.content}>
        <form ref={myref} onSubmit={handleSubmit}>
          <Grid container sx={{ margin: "-8px" }}>
            <Grid
              item
              xs={12}
              component={Typography}
              variant="h1"
              color="secondary"
              align="center"
              className={styles?.title}
            >
              {t("quote.title")}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              variant="subtitle1"
              color="primary"
              align="center"
              className={styles?.subtitle}
            >
              {t("quote.description")}
            </Grid>

            <Grid
              container
              //  spacing={16}
              sx={{ margin: "-8px" }}
            >
              <Grid item xs={12} sm={6} sx={{ padding: "8px" }}>
                <Field
                  name="name"
                  component={renderTextField}
                  label={t("common:name")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: "8px" }}>
                <Field
                  name="email"
                  component={renderTextField}
                  label={t("common:email")}
                  validate={Validator.isRequired}
                  type="email"
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: "8px" }}>
                <Field
                  name="phone"
                  component={renderTextField}
                  label={t("common:phone")}
                  validate={Validator.isRequired && Validator.isValidReqNumber}
                  type="phone"
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ padding: "8px" }}>
                <Field
                  name="zip"
                  component={renderTextField}
                  label={t("common:postal")}
                  validate={Validator.isRequired}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                alignItems="center"
                justifyContent="center"
                sx={{ padding: "8px" }}
              >
                <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
                  <Button
                    type="submit"
                    sx={{
                      color: "white",
                      "&.Mui-disabled": {
                        backgroundColor: "rgba(0, 0, 0, 0.12)",
                      },
                    }}
                    disabled={pristine || submitting}
                  >
                    {t("common:submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  </Section>
);

// const styles = (theme) => ({
//   content: {
//     padding: theme.spacing(4),
//     [theme.breakpoints.down("sm")]: {
//       padding: theme.spacing(2),
//     },
//   },
//   title: {
//     [theme.breakpoints.down("sm")]: {
//       fontSize: theme.spacing(3),
//     },
//   },
//   subtitle: {
//     [theme.breakpoints.down("sm")]: {
//       fontSize: theme.spacing(2),
//     },
//   },
// });

export default compose(
  withTranslation("homeowner"),
  reduxForm({ form: "homeowner-request-quote" })
  // withStyles(styles)
)(RequestQuote);
