import axios from "axios";

export default class EmailService {
  static send = ({ email, subject, message, phone }) => {
    console.log(email, subject, message, phone, "from send emeail");
    const url = process.env.REACT_APP_ZAPIER_GENERAL_EMAIL;
    const body = JSON.stringify({
      to: process.env.REACT_APP_EMAIL_TO,
      from: { email, phone },
      subject,
      body: message,
    });

    return axios.post(url, body, {
      transformRequest: [
        (data, headers) => {
          delete headers["Content-Type" || "content-type"];
          console.log(data, "dataa+++++");
          return data;
        },
      ],
    });
  };

  static requestQuote = (values) =>
    EmailService.send({
      email: values.email,
      name: values.name,
      subject: "SilverBack: Homeowner Quote",
      message: `
      <html><body>
        <p>Hi SilverBack</p>
        <p>
          My name is ${values.name} and I'm interested in learning
          more about the home warranty options.

          <ul>
            <li>Email: ${values.email}</li>
            <li>Phone: ${values.phone}</li>
            <li>Zip Code: ${values.zip}</li>
          </ul>
        <p>
      </body></html>
    `,
    });

  static requestOrderChange = (values, user, review) => {
    console.log(process.env.REACT_APP_ZAPIER_GENERAL_EMAIL);
    return EmailService.send({
      email: user.email,
      subject: "SilverBack: Need changes in Order",
      message: `
      <html><body>
        <p>Hi SilverBack</p>
        <p>I need to change order details. My email Id is ${user.email}</p>
        <p>${values}</p>
          <h2>Property Details</h2>
          <div>Street: ${
            review.street !== undefined ? review.street : "---"
          }</div>
          <div>Unit: ${review.unit !== undefined ? review.unit : "---"}</div>
          <div>City: ${review.city !== undefined ? review.city : "---"}</div>
          <div>State: ${review.state !== undefined ? review.state : "---"}</div>
          <div>Postal: ${
            review.postal !== undefined ? review.postal : "---"
          }</div>
          <div>BigHouse: ${
            review.bigHouse !== undefined ? review.bigHouse : "---"
          }</div>
          <hr />
          <h2>Seller Details</h2>
          <div>Seller Name: ${
            review.sellerName !== undefined ? review.sellerName : "---"
          }</div>
          <div>Seller Email: ${
            review.sellerEmail !== undefined ? review.sellerEmail : "---"
          }</div>
          <div>Seller Phone: ${
            review.sellerPhone !== undefined ? review.sellerPhone : "---"
          }</div>
          <div>Seller Agent Name: ${
            review.sellerAgentName !== undefined
              ? review.sellerAgentName
              : "---"
          }</div>
          <div>Seller Agent Email: ${
            review.sellerAgentEmail !== undefined
              ? review.sellerAgentEmail
              : "---"
          }</div>
          <div>Seller Agent Phone: ${
            review.sellerAgentPhone !== undefined
              ? review.sellerAgentPhone
              : "---"
          }</div>
          <hr />
          <h2>Buyer Details</h2>
          <div>Buyer Name: ${
            review.buyerName !== undefined ? review.buyerName : "---"
          }</div>
          <div>Buyer Email: ${
            review.buyerEmail !== undefined ? review.buyerEmail : "---"
          }</div>
          <div>Buyer Phone: ${
            review.buyerPhone !== undefined ? review.buyerPhone : "---"
          }</div>
          <div>Buyer Agent Name: ${
            review.buyerAgentName !== undefined ? review.buyerAgentName : "---"
          }</div>
          <div>Buyer Agent Email: ${
            review.buyerAgentEmail !== undefined
              ? review.buyerAgentEmail
              : "---"
          }</div>
          <div>Buyer Agent Phone: ${
            review.buyerAgentPhone !== undefined
              ? review.buyerAgentPhone
              : "---"
          }</div>
          <hr />
          <h2>Escrow Details</h2>
          <div>Escrow Name: ${
            review.escrowOfficer !== undefined ? review.escrowOfficer : "---"
          }</div>
          <div>Escrow Email: ${
            review.escrowEmail !== undefined ? review.escrowEmail : "---"
          }</div>
          <div>Escrow Company: ${
            review.escrowCompany !== undefined ? review.escrowCompany : "---"
          }</div>
          <div>Escrow Phone: ${
            review.escrowPhone !== undefined ? review.escrowPhone : "---"
          }</div>
        <p>Thank you</p>
      </body></html>
      
    `,
    });
  };
  static sendContact = (values) =>
    EmailService.send({
      email: values.from,
      name: values.name,
      phone: values.mobile,
      subject: "SilverBack Contact",
      message: `<html><body>${values.message}</body></html>`,
    });

  static sendClaimAlert = (claim) =>
    EmailService.send({
      email: claim.contact.email,
      name: claim.contact.name,
      subject: `New Service Claim: Policy ${claim.policy}`,
      message: `
        <main>
          <div>Policy: ${claim.policy}</div>
          <div>Name: ${claim.contact.name}</div>
          <div>Phone: ${claim.contact.phone}</div>
          <div>Email: ${claim.contact.email}</div>
          <hr />
          <h2>Address</h2>
          <div>
            <div>${claim.address.street}, ${claim.address.unit}</div>
            <div>${claim.address.city}, ${claim.address.state} ${claim.address.postal}
          </div>
          <hr />
          <h2>Claim Details</h2>
          <div>Requested Services: ${claim.services}</div>
          <div>Description: ${claim.description}</div>
        </main>
      `,
    });

  static sendOrderAlert = (order) =>
    EmailService.send({
      email: "alerts@silverbackhw.com",
      name: "Silverback Orders",
      subject: `New Order: ${order.plan.toUpperCase()}`,
      message: `
        <html>
        <body>
          <h1>Total: ${order.orderAmount}</h1>
          <hr/>
          <h2>Address</h2>
          <p>${order.property.street}</p>
          <p>
            ${order.property.city},
            ${order.property.state},
            ${order.property.postal},
          </p>
          <hr/>
          <h4>Buyer: ${order.buyer.name}</h4>
          <h4>Seller: ${order.seller.name}</h4>
          <h4>Submitted By: ${order.placedBy}</h4>
          <hr/>
          <p>Please review the Google Sheet for further details</p>
        </body>
        </html>
      `,
    });
}
