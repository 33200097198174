import react from "react";
import styles from "./goals.module.css";
import GoalImage from "assets/images/about_us_or_goal.png";
import Offer from "assets/images/what we offer.jpg";
const OurGoal = () => {
  return (
    <div className={styles.container}>
      <div className={styles.OurGoal}>
        <div>
          <h2 className={styles.h2}>Our goal</h2>
          Our primary goal is to change the way people perceive homeownership by
          providing easy, simple, and cost-effective solutions. We understand
          that breakdowns and unexpected repairs can be stressful, which is why
          we have partnered with dedicated and skilled third-party contractors
          who are ready to swiftly address any issues you may encounter in your
          home. With our team of professionals, you can rest assured that your
          home will be in safe hands.
        </div>
        <img src={GoalImage} />
      </div>
      <div className={styles.OurGoal}>
        <img src={Offer} />
        <div>
          <h2 className={styles.h2}>What we offer</h2>
          We take pride in offering transparent and reliable home warranty plans
          that provide peace of mind for homeowners. Whether it's a
          malfunctioning appliance, a faulty HVAC system, or a plumbing
          emergency, SilverBack Home Warranty has got you covered. Join us on
          this journey to redefine homeownership and experience the convenience
          and security of having a trusted partner by your side.
        </div>
      </div>
    </div>
  );
};

export default OurGoal;
