import React from "react";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";

import Button from "components/common/button";
import * as Styled from "./styled";

const OrderConfirmation = ({ t, open, onClose }) => (
  <Styled.Modal open={open} onClose={onClose}>
    <Styled.ModalContent>
      <Typography variant="h1" color="primary">
        {t("claims:confirm.title")}
      </Typography>
      <Typography variant="subtitle1">
        {t("claims:confirm.subtitle")}
      </Typography>
      <Button
        onClick={onClose}
        color="secondary"
        style={{ marginTop: 20, color: "#fff" }}
      >
        {t("goHome")}
      </Button>
    </Styled.ModalContent>
  </Styled.Modal>
);

export default withTranslation(["common", "claims"])(OrderConfirmation);
