import React, { Component } from "react";
// import { withRouter } from "react-router-dom";

import { compose } from "redux";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  ListItemText,
  ListItem,
  List,
  Grid,
  Typography,
} from "@mui/material";
import { translate, withTranslation } from "react-i18next";
import Button from "components/common/button";
import { reduxForm } from "redux-form";
import withRouter from "helpers/withRouters";

class PolicyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expirationInfo: "policy not yet expired",
    };
  }
  isPolicyExpired = (policyExpirationDate, policyInfo) => {
    var currentDate = new Date();
    var eightDaysFromExpiryDate = new Date(policyExpirationDate);
    var ninetyDaysBeforeExpirationDate = new Date(policyExpirationDate);
    eightDaysFromExpiryDate.setDate(eightDaysFromExpiryDate.getDate() + 8);
    ninetyDaysBeforeExpirationDate.setDate(
      ninetyDaysBeforeExpirationDate.getDate() - 90
    );
    if (
      currentDate <= eightDaysFromExpiryDate &&
      currentDate >= ninetyDaysBeforeExpirationDate &&
      policyInfo.Status !== "Do Not Renew" &&
      policyInfo.Status !== "30 Day Wait" &&
      policyInfo.Status !== "Expired - Renewed"
    ) {
      return "policy expired";
    } else if (currentDate < ninetyDaysBeforeExpirationDate) {
      return "show nothing";
    } else if (currentDate > eightDaysFromExpiryDate) {
      return "show quote";
    }
  };

  renderPlans = () => {
    const { plans, t } = this.props;
    if (plans && plans.length > 0) {
      const policies = JSON.parse(plans);
      return policies.map((plan) => {
        return (
          <Grid item xs={12} sm={6} key={plan.id}>
            <Paper key={plan.id} elevation={1} style={{ height: "100%" }}>
              <List>
                <ListItem>
                  <ListItemText
                    primary="Policy Number"
                    secondary={plan.Policy_Number}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Policy Name"
                    secondary={plan.Plan_Name}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Details Of Coverage"
                    secondary={
                      plan.Options.length !== 0
                        ? plan.Options.map((option) => (
                            <li>{option.replace(/_/g, " ")}</li>
                          ))
                        : "---"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Policy Status"
                    secondary={plan.Status ? plan.Status : "---"}
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Effective Date"
                    secondary={
                      plan.Effective_Date !== ""
                        ? new Date(plan.Effective_Date).toLocaleDateString()
                        : "---"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Expiration Date"
                    secondary={
                      plan.Expiration_Date !== ""
                        ? new Date(plan.Expiration_Date).toLocaleDateString()
                        : "---"
                    }
                  />
                  {this.isPolicyExpired(plan.Expiration_Date, plan) ===
                  "policy expired" ? (
                    <Button
                      onClick={() => {
                        this.props.navigate("/policyRenew", {
                          state: { policyInfo: plan },
                        });
                      }}
                      fullWidth={false}
                      style={{
                        marginRight: "0.8rem",
                        height: "40px",
                        width: "30%",
                        color: "#fff",
                      }}
                    >
                      Renew
                    </Button>
                  ) : this.isPolicyExpired(plan.Expiration_Date, plan) ===
                    "show quote" ? (
                    <Typography color="primary" variant="subheading">
                      {" "}
                      {t("common:quote")}
                    </Typography>
                  ) : (
                    ""
                  )}
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary="Customer"
                    secondary={plan.Customer ? plan.Customer.name : "---"}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} sm={12}>
          <Paper elevation={0} style={{ height: "100%" }}>
            <List>
              <ListItem>
                <ListItemText primary="No Previous Policies to display" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      );
    }
  };

  render() {
    return (
      <Card>
        <CardHeader title="Policy Details" />
        <CardContent>
          <Grid container spacing={2}>
            {this.renderPlans()}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default compose(
  withRouter,
  withTranslation("common"),
  reduxForm({
    form: "homeowner-plan-addons",
    destroyOnUnmount: true,
  })
)(PolicyDetails);
