import React, { Component } from "react";
import { compose } from "redux";
import { bool, arrayOf, string } from "prop-types";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
// import { withTheme } from "@mui/material/styles";
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Header from "components/common/header";
import Footer from "components/common/footer";

import AgentServices from "components/agent-services";
import Alert from "components/common/alert";
import Auth from "components/auth";
import Claims from "components/claims";
import Contact from "components/contact";
import Home from "components/home";
import Homeowner from "components/homeowner";
import LoadingSpinner from "components/common/loading-spinner";
import NotFound from "components/not-found";
import AgentOrders from "components/agent-orders";
import GetStarted from "components/get-started";
import PrivacyPolicy from "components/privacy-policy";
import OrderConfirmation from "components/common/confirm-order";
import RenewPolicy from "components/homeowner/orders/plan-builder/renew-policy";
import RenewPolicyCheckout from "components/homeowner/orders/renew-policy-checkout/renew-policy-checkout";
import { getErrors, getIsLoading } from "store/reducers";
import { dismissError } from "store/actions/app";
import { AppContainer, AppContent } from "./styles";
import withRouter from "helpers/withRouters";
import Maintenanace from "components/maintenance";
import AboutUs from "components/about-us";
import SinglePlaylist from "components/maintenance/singlePlaylist";
import BrochurePage from "components/home/brochure";

// import PdfGeneration from 'components/pdf-generation';

class App extends Component {
  static propTypes = {
    isLoading: bool.isRequired,
    errors: arrayOf(string),
  };

  state = { show: false };

  handleScroll = () => {
    if (this.props.location.pathname !== "/") return;

    const breakpoint = window.innerHeight * 0.25;

    if (!this.state.show && window.scrollY >= breakpoint) {
      this.setState({ show: true });
    } else if (this.state.show && window.scrollY < breakpoint) {
      this.setState({ show: false });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  handleErrorDismissal = (message, index) => () =>
    this.props.dispatchErrorDismissal(message, index);

  render() {
    const { isLoading, errors } = this.props;
    const showHeader =
      this.props.location.pathname === "/" ? this.state.show : true;
    const error = errors.length ? errors[0] : null;

    return (
      <AppContainer>
        <CssBaseline />
        <Header
          transparent={!showHeader}
          handleNavigation={this.props.navigate}
        />
        <AppContent>
          <Routes>
            <Route
              exact
              path="/:locale(es)?"
              element={<Home handleNavigation={this.props.navigate} />}
            />
            <Route path="/auth/*" element={<Auth />} />
            <Route
              path="/contact"
              element={<Contact navigate={this.props.navigate} />}
            />
            <Route
              path="/claims"
              element={<Claims navigate={this.props.navigate} />}
            />
            <Route
              path="/plans/start"
              element={<GetStarted handleNavigation={this.props.navigate} />}
            />
            <Route
              exact
              path="/plans/order"
              element={
                <AgentOrders
                  location={this.props.location}
                  handleNavigation={this.props.navigate}
                />
              }
            />
            <Route
              exact
              path="/plans/order/confirm"
              element={
                <OrderConfirmation
                  handleNavigation={this.props.navigate}
                  locationobj={this.props.location}
                />
              }
            />
            <Route
              exact
              path="/homeowner/order/confirm"
              element={
                <OrderConfirmation
                  handleNavigation={this.props.navigate}
                  locationobj={this.props.location}
                />
              }
            />
            <Route
              exact
              path="/policyRenew/checkout/confirm"
              element={
                <OrderConfirmation
                  handleNavigation={this.props.navigate}
                  locationobj={this.props.location}
                />
              }
            />
            <Route path="/agents/services" element={<AgentServices />} />
            <Route path="/homeowner/*" element={<Homeowner />} />
            <Route
              exact
              path="/policyRenew"
              element={
                <RenewPolicy
                  navigate={this.props.navigate}
                  location={this.props.location}
                />
              }
            />
            <Route
              exact
              path="/policyRenew/checkout"
              element={
                <RenewPolicyCheckout
                  navigate={this.props.navigate}
                  location={this.props.location}
                />
              }
            />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/maintenance" element={<Maintenanace />} />
            <Route path="/about" element={<AboutUs />} />
            <Route
              path="/maintenance/:playlistId"
              element={<SinglePlaylist />}
            />
            <Route path="/brochure" element={<BrochurePage />} />
            <Route element={<NotFound />} />
          </Routes>
          {error && (
            <Alert
              message={error}
              onDismiss={this.handleErrorDismissal(error, 0)}
            />
          )}
          {isLoading && <LoadingSpinner />}
        </AppContent>
        <Footer />
      </AppContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: getErrors(state),
  isLoading: getIsLoading(state),
});

const composed = compose(
  withRouter,
  // withTheme(),
  withTranslation(),
  connect(mapStateToProps, { dispatchErrorDismissal: dismissError })
)(App);

function withMyHook(WrappedComponent) {
  return function WithMyHook(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const handlenavigate = (to, opt) => navigate(to, opt);
    // ...use other hooks or add custom logic here
    return (
      <WrappedComponent
        {...props}
        navigate={handlenavigate}
        location={location}
      />
    );
  };
}

export default withMyHook(composed);
