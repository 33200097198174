import React from "react";
import Downshift from "downshift";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete as MuiAutocomplete } from "@mui/material";

const AutoComplete = (props) => {
  const {
    items,
    itemToString,
    onSelect,
    input,
    onSearch,
    itemToDisplayString,
  } = props;

  const getSuggestions = (inputValue) => {
    let count = 0;
    return items.filter((item) => {
      const strItem = itemToString(item);

      const keep =
        (!inputValue ||
          (typeof strItem === "string" &&
            strItem.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)) &&
        count < 5;

      if (keep) {
        count += 1;
      }
      return keep;
    });
  };

  const renderSuggestion = ({
    suggestion,
    index,
    highlightedIndex,
    itemProps,
  }) => {
    const label = itemToDisplayString
      ? itemToDisplayString(suggestion)
      : itemToString(suggestion);
    return (
      <MenuItem
        {...itemProps}
        key={itemProps.id}
        selected={highlightedIndex === index}
        component="div"
        sx={{
          fontWeight: "700",
          fontFamily: `'PT Sans Narrow', 'Roboto', 'sans-serif'`,
          lineHeight: "1.5em",
        }}
      >
        {label}
      </MenuItem>
    );
  };

  const renderInput = ({ inputValue, InputProps }) => {
    const {
      label,
      meta: { error, touched },
    } = props;
    return (
      <TextField
        fullWidth
        label={label}
        error={error && touched}
        helperText={touched && error}
        InputProps={InputProps}
        value={input.value}
        {...input}
        variant="standard"
      />
    );
  };

  return (
    <Downshift
      itemToString={itemToString}
      selectedItem={input.value}
      onSelect={onSelect}
      onStateChange={({ inputValue }) => {
        if (inputValue !== undefined) {
          input.onChange(inputValue);
        }
        // input.onChange("oooooooooooo");
      }}
      // onChange={({ inputValue }) => {
      //   input.onChange(inputValue);
      // }}
    >
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        highlightedIndex,
      }) => (
        <div style={{ width: "100%", position: "relative" }}>
          {renderInput({
            inputValue,
            InputProps: getInputProps({}),
          })}

          {isOpen && inputValue.length > 2 && (
            <Paper square style={{ position: "absolute", zIndex: 1000 }}>
              {getSuggestions(inputValue).map((suggestion, index) =>
                renderSuggestion({
                  suggestion,
                  index,
                  highlightedIndex,
                  itemProps: getItemProps({ item: suggestion }),
                })
              )}
            </Paper>
          )}
        </div>
      )}
    </Downshift>
  );
};

export default AutoComplete;

// Old
// import React from "react";
// import Downshift from "downshift";
// import TextField from "@mui/material/TextField";
// import Paper from "@mui/material/Paper";
// import MenuItem from "@mui/material/MenuItem";
// import { Autocomplete as MuiAutocomplete } from "@mui/material";

// export default class AutoComplete extends React.Component {

//   getSuggestions = (inputValue) => {
//     const { items, itemToString } = this.props;
//     let count = 0;
//     return items.filter((item) => {
//       const strItem = itemToString(item);

//       const keep =
//         (!inputValue ||
//           (typeof strItem === "string" &&
//             strItem.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)) &&
//         count < 5;

//       if (keep) {
//         count += 1;
//       }
//       return keep;
//     });
//   };

//   renderSuggestion = ({ suggestion, index, highlightedIndex, itemProps }) => {
//     const { itemToDisplayString } = this.props;
//     const isHighlighted = highlightedIndex === index;
//     const label = itemToDisplayString(suggestion);
//     console.log(suggestion, "suggestion++++++____");
//     return (
//       <MenuItem
//         {...itemProps}
//         key={itemProps.id}
//         selected={isHighlighted}
//         component="div"
//       >
//         {label}
//       </MenuItem>
//     );
//   };

//   renderInput = ({ inputValue, InputProps }) => {
//     const {
//       input,
//       label,
//       meta: { error, touched },
//     } = this.props;
//     console.log(label, "labelll++++", input.value);
//     return (
//       <TextField
//         fullWidth
//         label={label}
//         error={error && touched}
//         helperText={touched && error}
//         InputProps={InputProps}
//         value={input.value}
//         {...input}
//         variant="standard"
//       />
//     );
//   };

//   render() {
//     const { itemToString, onSelect, input } = this.props;
//     console.log(input.value, "++++++++____________");
//     return (
//       <Downshift
//         itemToString={itemToString}
//         // selectedItem={input.value}
//         onSelect={onSelect}
//         // onStateChange={({ inputValue }) => {
//         //   console.log(inputValue, "inputVallue++++__");
//         //   input.onChange(inputValue);
//         // }}
//       >
//         {({
//           getInputProps,
//           getItemProps,
//           isOpen,
//           inputValue,
//           highlightedIndex,
//         }) => (
//           <div style={{ width: "100%", position: "relative" }}>
//             {this.renderInput({
//               inputValue,
//               InputProps: getInputProps({}),
//             })}

//             {isOpen && inputValue.length > 2 && (
//               <Paper square style={{ position: "absolute", zIndex: 1000 }}>
//                 {this.getSuggestions(inputValue).map((suggestion, index) =>
//                   this.renderSuggestion({
//                     suggestion,
//                     index,
//                     highlightedIndex,
//                     itemProps: getItemProps({ item: suggestion }),
//                   })
//                 )}
//               </Paper>
//             )}
//           </div>
//         )}
//       </Downshift>

//       // <MuiAutocomplete
//       //   value={input.value}
//       //   onChange={(event, newValue) => {
//       //     onSelect(newValue);
//       //     // If you want to update the input value in the form state, you can do it here
//       //     // For example, if using Redux Form, you can call:
//       //     // input.onChange(newValue);
//       //   }}
//       //   inputValue={input.value}
//       //   onInputChange={(event, newInputValue) => {
//       //     input.onChange(newInputValue);
//       //   }}
//       //   // open={isOpen}
//       //   isOptionEqualToValue={(option, value) => option === value} // Optional: If your data is not simple strings, use this function to determine how to compare options and values.
//       //   options={this.getSuggestions(input.value)}
//       //   getOptionLabel={(option) => option} // Replace 'option' with the appropriate property of your data to display in the dropdown.
//       //   renderInput={(params) => (
//       //     <div style={{ width: "100%", position: "relative" }}>
//       //       {this.renderInput({
//       //         inputValue: input.value,
//       //         InputProps: params.InputProps,
//       //       })}
//       //     </div>
//       //   )}
//       //   renderOption={(option, { inputValue, selected }) => (
//       //     <li
//       //       {...getItemProps({ item: option })}
//       //       // Style the selected option
//       //       style={{ backgroundColor: selected ? "lightgrey" : "white" }}
//       //     >
//       //       {option}
//       //     </li>
//       //   )}
//       //   renderList={(props, children) => (
//       //     <Paper
//       //       square
//       //       {...props}
//       //       style={{ position: "absolute", zIndex: 1000 }}
//       //     >
//       //       {children}
//       //     </Paper>
//       //   )}
//       // />
//     );
//   }
// }
