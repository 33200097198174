import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styled from "@emotion/styled";

class FeatureList extends React.PureComponent {
  state = { selectedItem: null };

  closeFeatureDialog = () => this.setState({ selectedItem: null });

  renderFeatureDialog = () => {
    const { selectedItem } = this.state;

    if (!selectedItem) return null;

    return (
      <Dialog
        open={Boolean(selectedItem)}
        maxWidth="md"
        fullWidth
        onBackdropClick={this.closeFeatureDialog}
        onEscapeKeyDown={this.closeFeatureDialog}
      >
        <DialogTitle>{selectedItem.label}</DialogTitle>
        <DialogContent>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6}>
              <Typography variant="title" color="secondary">
                Covered
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedItem.covered}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="title" color="secondary">
                Excluded
              </Typography>
              <Typography variant="body2" gutterBottom>
                {selectedItem.excluded}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeFeatureDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  handleSelect = (item) => () => this.setState({ selectedItem: item });

  render() {
    const { t } = this.props;

    return (
      <StyledCard>
        <CardHeader
          title={t("systemsAndAppliances")}
          titleTypographyProps={{ color: "secondary" }}
        />
        <CardContent>
          <List>
            {this.props.features.map((item) => (
              <ListItem
                key={`feature-${item.label}`}
                onClick={this.handleSelect(item)}
                dense
                divider
                disableGutters
              >
                <StyledItemText primary={item.label} />
                {/* <p>{item.price}</p> */}
              </ListItem>
            ))}
          </List>
        </CardContent>

        {this.renderFeatureDialog()}
      </StyledCard>
    );
  }
}

const StyledCard = styled(Card)(() => ({
  height: "100%",
}));

const StyledItemText = styled(ListItemText)(({ theme }) => ({
  height: 18,
  "> span": {
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

export default compose(withTranslation("homeowner"))(FeatureList);
