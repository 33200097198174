import react from "react";
import { Typography, useMediaQuery, Grid } from "@mui/material";
import styles from "./maintenance.module.css";
import { useTranslation } from "react-i18next";
import Helmet from "react-helmet";
import Section from "components/common/section";
import YouTubePlaylistPlayer from "./components/Player";

import Electrician from "../../assets/images/Electrician.png";
import PlaylistSection from "./components/Playlist-Section";
import BackgroundImage from "assets/images/AdobeStockHome.jpeg";

function Maintenanace() {
  const { t } = useTranslation("maintenance");
  const Playlists = [
    "PL4fhJ8V3ASKL2payUun7A4ks-SbkQlMh4",
    "PL4fhJ8V3ASKIwae6ka2CLbBcxNy0vLxad",
    "PL4fhJ8V3ASKKa-KOnwrprLS1TKE-opkuP",
    "PL4fhJ8V3ASKKraHNAQVvAbwXRyZniHmIl",
  ];
  const { title, desc } = t("playlist");
  // const description = t("banner");
  const imageHidden = useMediaQuery("(max-width:992px)");

  return (
    <>
      <Helmet title={`${t("common:nameShort")}: ${t("title")}`} />

      <Grid container direction="column">
        <Section
          style={{ background: "#f4f6f7", padding: "0", color: "unset" }}
        >
          <div className={styles.home}>
            <div className={styles.banner}>
              <div className={styles.bannerLeft}>
                <Typography color="primary" variant="h2">
                  Home maintenance
                </Typography>
                <Typography
                  sx={{ paddingTop: "24px", color: "rgba(0, 0, 0, 0.54)" }}
                  variant="body1"
                >
                  {t("banner.desc")}
                </Typography>
              </div>
              {!imageHidden && (
                <div className={styles.bannerRight}>
                  <img src={Electrician} height={"513px"} width={"100%"} />
                </div>
              )}
            </div>
            {Playlists.map((id, index) => {
              return (
                <PlaylistSection
                  playlistId={id}
                  title={title[index]}
                  desc={desc[index]}
                  key={id}
                />
              );
            })}
          </div>
        </Section>
      </Grid>
    </>
  );
}

export default Maintenanace;
