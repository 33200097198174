import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import Button from 'components/common/button';
import Section from 'components/common/section';

export class NotFound extends Component {
  render() {
    const { t } = this.props;

    return (
      <Section>
        <Grid
          container
          spacing={16}
          align="center"
          justify="center"
          style={{ width: '50%', margin: '0 auto' }}
        >
          <Typography color="textSecondary" variant="display4">
            {t('pageNotFound')}
          </Typography>
          <Typography color="secondary" variant="display2" gutterBottom>
            {t('pageNotFoundMessage')}
          </Typography>
          <Button color="secondary" component={Link} to="/">
            {t('goHome')}
          </Button>
        </Grid>
      </Section>
    );
  }
}

export default withTranslation()(NotFound);
