import styled from '@emotion/styled'

export const Content = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}));

export const BrandContainer = styled('div')(({ theme }) => ({
  width: '35%',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}));

export const FormContainer = styled('div')(({ theme }) => ({
  width: '50%',

  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));
