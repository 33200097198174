import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";
// import { Slider } from '@material-ui/lab';

class ServiceCallOptions extends React.Component {
  // handleChange = (event, value) => {
  //   const price = 65
  //   this.props.onChange(price);
  //   this.setState({ price });
  // };

  // getSliderValue = amount => {
  //   const value = Math.round((amount - 75) / 25) + 1;

  //   return value;
  // };

  render() {
    const { t, value } = this.props;

    return (
      <StyledCard>
        <CardHeader
          title={t("serviceCallFees")}
          titleTypographyProps={{ color: "secondary" }}
          subheader={t("serviceCallDescription")}
          subheaderTypographyProps={{ variant: "caption" }}
        />
        <CardContent>
          <Typography id="slider-label" variant="h3" color="secondary">
            ${value}
          </Typography>
          {/* <StyledSlider
            color="secondary"
            min={1}
            max={3}
            step={1}
            value={this.getSliderValue(value)}
            onChange={this.handleChange}
          /> */}
        </CardContent>
      </StyledCard>
    );
  }
}

const StyledCard = styled(Card)(() => ({}));

// const StyledSlider = styled(Slider)(() => ({
//   padding: `22px 0`
// }));

export default compose(withTranslation("homeowner"))(ServiceCallOptions);
