import styled from "@emotion/styled";

export const Wrapper = styled("div")({}, ({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: theme.palette.common.white,

  [theme.breakpoints.down("sm")]: {
    "& .title": {
      textAlign: "center",
      fontSize: 40,
    },
  },
}));

export const CardWrapper = styled("div")({}, ({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",

  "& .plan-option": {
    width: "calc(33% - 5px)",
  },

  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    "& .plan-option": {
      width: "100%",
      marginBottom: 10,
    },
  },
}));
