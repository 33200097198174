import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { lighten } from "polished";
import Typography from "@mui/material/Typography";
import theme from "helpers/theme";
import { Button } from "@mui/material";

const LinkStyles = {
  textDecoration: "none",
  padding: "8px 16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: 16,
};

export const LightLink = styled(Link)(LinkStyles, ({ theme }) => ({
  color: theme.palette.primary.dark,
  transition: "color 300ms ease-in-out",
  "&:hover": {
    color: theme.palette.primary.dark,
  },
}));

export const MenuButton = styled(Button)(LinkStyles, ({ theme }) => ({
  all: "unset",
  color: theme.palette.primary.dark,
  transition: "color 300ms ease-in-out",
  cursor: "pointer",
  "&:hover": {
    // color: theme.palette.primary.dark,
    // all: "unset",
    backgroundColor: "inherit",
  },
}));

export const AuthLink = styled(Link)(LinkStyles, ({ theme }) => ({
  label: "auth-link",

  color: theme.palette.primary.dark,
  border: `1px solid ${theme.palette.primary.dark}`,
  borderRadius: theme.shape.borderRadius,
  transition: `all 300ms ease-in-out`,
  marginLeft: theme.spacing(2),
  padding: `${theme.spacing.unit}px ${theme.spacing(3)}px`,
  "&:hover": {
    // background: `rgba(255, 255, 255, 0.65)`,
    color: theme.palette.common.dark,
  },
}));

export const DarkLink = styled(Link)(LinkStyles, ({ theme }) => ({
  color: theme.palette.primary.main,
  transition: "color 500ms ease-in",
  textTransform: "uppercase",
  fontWeight: 300,
  fontSize: 14,
  "&:hover": {
    color: lighten(0.2, theme.palette.primary.main),
  },
}));

export const BrandWrapper = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
});

export const Brand = styled(Typography)({
  textTransform: "uppercase",
  padding: "0 16px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
});

export const IconWrapper = styled("div")({}, ({ theme }) => ({
  "& svg.dark": {
    color: theme.palette.secondary.main,
    paddingRight: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& svg.light": { color: "#FFF" },
}));

export const Gradient = styled("div")(({ theme }) => ({
  background: `linear-gradient(0.25turn, ${theme.palette.primary.main}, 85%, ${theme.palette.secondary.main})`,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[2],
  height: 66,
  left: 0,
  opacity: 0,
  position: "fixed",
  transition: `opacity 300ms ease-in`,
  width: "100%",
  zIndex: -1,

  "&.show": {
    opacity: 1,
  },
}));

export const Image = styled("img")(({ transparent }) => ({
  height: 50,
  opacity: transparent ? 0 : 1,
}));
