import React from "react";
import { withTranslation } from "react-i18next";
import { Grid, Card, CardHeader } from "@mui/material";

import Section from "components/common/section";
import Button from "components/common/button";
import BackgroundImage from "assets/images/AdobeStockHome.jpeg";

import FAQ from "./faq";

const Banner = ({
  t,
  onChoosePlan,
  onMyAccount,
  onRequestQuote,
  isContrast,
}) => (
  <Section image={BackgroundImage} shadow fullHeight imageMask isContrast>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card
          style={{
            marginBottom: 24,
            backgroundColor: `rgba(255,255,255,0.85)`,
          }}
        >
          <CardHeader
            title={t("title")}
            subheader={t("subtitle")}
            titleTypographyProps={{
              variant: "h1",
              color: "primary",
              gutterBottom: true,
            }}
            subheaderTypographyProps={{ color: "primary" }}
          />
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        container
        sx={{ padding: "8px", margin: "-8px" }}
      >
        <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
          <Button color="white" onClick={onChoosePlan}>
            {t("choosePlan")}
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
          <Button color="white" onClick={onRequestQuote}>
            {t("requestQuote")}
          </Button>
        </Grid>

        <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
          <Button color="white" onClick={onMyAccount}>
            {t("myAccount")}
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{ padding: "8px !important", margin: "0 !important" }}
      >
        <FAQ />
      </Grid>
    </Grid>
  </Section>
);

export default withTranslation("homeowner")(Banner);
