import styled from '@emotion/styled'

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  marginBottom: 20,
  borderRadius: 10,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));
