import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';

export default ({ open, total, onConfirm, onCancel, callFee }) => (
  <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" open={open}>
    <DialogTitle>Confirm Order</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {callFee === undefined ? `
        You are about to order a Silverback Home Warranty and your 
        card will be charged $${total}.
      ` : `You are about to pay for service call fee and your card will be charged $${total}`}
      </DialogContentText>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary">
          Ok
        </Button>
      </DialogActions>
    </DialogContent>
  </Dialog>
);
