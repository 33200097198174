import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import withRouter from "helpers/withRouters";
import { reduxForm } from "redux-form";
// import {  renderSelectField } from 'helpers/form';
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import Button from "components/common/button";
import { getIsSignedIn } from "store/reducers";
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';
// import { renderAutocomplete } from 'helpers/form';
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { getConsumerOrderSummary } from "store/reducers";

class ApplyCoupon extends React.Component {
  state = {
    selectedoption: "",
    show: false,
    inputCoupon: "",
    showinvalid: false,
    coupon_options: [],
  };
  saveCart = () => {};
  // componentWillMount() {
  //   console.log(this.props.options, "+++__");
  // }
  searchCoupon = (value) => {
    if (!value || value.length < 3) {
      this.setState({ show: false });
      return;
    }
  };
  applyCoupon = (e) => {
    this.setState({
      inputCoupon: e,
    });
    this.searchCoupon(e);
  };
  validate = () => {
    const {
      order: { total },
      plantype,
    } = this.props;
    var found = false;
    var selected_coupon = {};
    this.props.options.map((d) => {
      if (d.label === this.state.inputCoupon) {
        found = true;
        selected_coupon.label = d.label;
        selected_coupon.value = d.value;
      }
      return found;
    });
    if (found) {
      let amount =
        plantype === "annual"
          ? total.annual - selected_coupon.value
          : total.monthly - selected_coupon.value / 12;
      if (amount > 0) {
        this.setState({ show: false, showinvalid: false });
        selected_coupon.value =
          plantype === "annual"
            ? selected_coupon.value
            : (selected_coupon.value / 12).toFixed(2);
      } else {
        this.setState({ showinvalid: true, show: false });
        selected_coupon.value = 0;
        selected_coupon.label = "";
      }
    } else {
      this.setState({ show: true, showinvalid: false });
      selected_coupon.value = 0;
      selected_coupon.label = "";
    }
    this.props.getcouponamount(selected_coupon);

    return this.state.inputCoupon;
  };
  cancel = () => {
    var selected_coupon = {};
    selected_coupon.value = 0;
    selected_coupon.label = "";
    this.props.getcouponamount(selected_coupon);
    this.setState({
      inputCoupon: "",
      show: false,
      showinvalid: false,
    });
  };
  render() {
    const { t, isSignedIn } = this.props;
    return (
      <Card style={{ height: "100%" }}>
        <CardHeader
          title={t("applyCoupon")}
          titleTypographyProps={{ color: "primary" }}
          sx={{ padding: "16px 24px" }}
        />
        <CardContent sx={{ padding: "16px 24px 24px" }}>
          <form style={{ height: "100px" }}>
            <Grid container spacing={2} sx={{ margin: "-8px" }}>
              {isSignedIn ? (
                <React.Fragment>
                  <Grid
                    container
                    spacing={2}
                    sx={{ padding: "8px !important" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ padding: "8px !important" }}
                    >
                      <TextField
                        label="Promo Code"
                        onChange={(event) =>
                          this.applyCoupon(event.target.value)
                        }
                        value={this.state.inputCoupon}
                        style={{ marginLeft: "0.7rem" }}
                        variant="standard"
                      />
                      {this.state.show && (
                        <FormHelperText style={{ marginLeft: "0.7rem" }}>
                          {t("common:invalidCoupon")}
                        </FormHelperText>
                      )}
                      {this.state.showinvalid && (
                        <FormHelperText style={{ marginLeft: "0.7rem" }}>
                          Promo Code value exceeds total amount
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        fullWidth={false}
                        onClick={this.validate}
                        style={{
                          marginRight: "0.8rem",
                          height: "40px",
                          color: "white",
                        }}
                      >
                        Apply
                      </Button>
                      <Button
                        fullWidth={false}
                        onClick={this.cancel}
                        // variant="outlined"
                        style={{
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderBottomColor: "rgba(0, 85, 126, 0.5",
                          border: "1px solid rgba(0, 85, 126, 0.5)",
                          color: "#00557E",
                          borderRadius: "10px",
                          height: "40px",
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ) : null}
            </Grid>
          </form>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  order: getConsumerOrderSummary(state),
});

export default compose(
  connect(mapStateToProps),
  withTranslation("homeowner"),
  withRouter,
  reduxForm({
    form: "Coupon",
    destroyOnUnmount: false,
    enableReinitialize: true,
  })
)(ApplyCoupon);
