import React, { Component } from "react";
import ReactDOM from "react-dom";
import Grid from "@mui/material/Grid";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Helmet from "react-helmet";
// import { withTheme } from '@mui/material/styles';

import EmailService from "services/email";
import { requestQuote } from "store/actions/email";
import Banner from "./banner";
import AverageRepairCosts from "./repair-costs";
import WhyChoose from "./why-choose";
import RequestQuote from "./quote-form";
import RealEstate from "./real-estate";

class HomeownerLanding extends Component {
  handleRequestQuote = (values) => {
    this.props.requestQuote(EmailService.requestQuote(values));
  };
  constructor(props) {
    super(props);
    // Create a ref using the React.createRef() API
    this.myRef = React.createRef();
  }
  handleRoute = (url) => () => this.props.navigate(url);

  handleChoosePlan = (path) => this.handleRoute(`${path}/order`);

  handleScrollToRef = (ref) => () => {
    const node = ref.current;
    if (node) {
      window.scrollTo({
        top: node.offsetTop,
        behavior: "smooth",
      });
    }
  };

  render() {
    const { t, theme, path } = this.props;

    return (
      <Grid container>
        <Helmet title={`${t("common:nameShort")}: ${t("title")}`} />
        <Banner
          onRequestQuote={this.handleScrollToRef(this.myRef)}
          onChoosePlan={this.handleChoosePlan(path)}
          onMyAccount={this.handleRoute(`${path}/account`)}
          isContrast={true}
        />
        <WhyChoose onChoosePlan={this.handleChoosePlan(path)} />
        <AverageRepairCosts onChoosePlan={this.handleChoosePlan(path)} />
        <RequestQuote
          myref={this.myRef}
          style={{ zIndex: 3, backgroundColor: theme?.palette.primary.main }}
          onSubmit={this.handleRequestQuote}
        />
        <RealEstate />
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  requestQuote: (promise) => dispatch(requestQuote(promise)),
});

export default compose(
  withTranslation(["homeowner", "common"]),
  // withTheme(),
  connect(null, mapDispatchToProps)
)(HomeownerLanding);
