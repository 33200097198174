import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Card, CardHeader, CardContent, Grid } from "@mui/material";
import styled from "@emotion/styled";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import Button from "components/common/button";
import { getplantype } from "store/reducers";
import { connect } from "react-redux";

class PlanTotal extends React.Component {
  state = { selected: this.props.plantype };
  componentDidMount() {
    this.props.subscriptionType(this.state.selected);
  }
  render() {
    const { t, annualPrice, monthlyPrice, onSubmit, disabled } = this.props;
    const { selected } = this.state;
    const handleChange = (ev) => {
      this.setState({ selected: ev.target.value });
      this.props.subscriptionType(ev.target.value);
    };
    return (
      <StyledCard>
        .
        <CardHeader
          title="Policy Total"
          titleTypographyProps={{ color: "inherit" }}
          sx={{ padding: "16px 24px" }}
        />
        <CardContent sx={{ padding: "16px 24px" }}>
          <Grid
            container
            // spacing={2}
            sx={{ margin: "-8px" }}
          >
            <Grid
              item
              xs={7}
              container
              // spacing={2}
              sx={{ margin: "-8px", padding: "8px" }}
            >
              {/* <Grid item>
                <Typography color="inherit">
                  {t('annual')}: ${annualPrice}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="inherit">
                  {t('monthly')}: ${monthlyPrice}
                </Typography>
              </Grid> */}
              <Grid item>
                <FormControl
                  component="fieldset"
                  name="method-of-payment"
                  sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                >
                  <RadioGroup
                    onChange={handleChange}
                    value={selected}
                    style={{ Color: "white !important" }}
                  >
                    <FormControlLabel
                      value="annual"
                      color="primary"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "rgba(0, 0, 0, 0.87)" },
                          }}
                        />
                      }
                      // label={t('annual')}
                      label={`${t("annual")} : $${
                        annualPrice ? annualPrice : ""
                      }`}
                    />
                    <FormControlLabel
                      value="monthly"
                      control={
                        <Radio
                          sx={{
                            "&.Mui-checked": { color: "rgba(0, 0, 0, 0.87)" },
                          }}
                        />
                      }
                      // label={t('monthly')}
                      label={`${t("monthly")} : $${
                        monthlyPrice ? monthlyPrice : ""
                      }`}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Button
                onClick={onSubmit}
                style={{ color: "#00557E", minWidth: "112px" }}
                color="white"
                disabled={disabled}
              >
                {t("checkout")}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </StyledCard>
    );
  }
}

PlanTotal.defaultProps = {
  annualPrice: 0,
  monthlyPrice: 0,
  onSubmit: null,
};

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));
const mapStateToProps = (state) => ({
  plantype: getplantype(state),
});
export default compose(
  withTranslation("homeowner"),
  connect(mapStateToProps)
)(PlanTotal);
