export default {
  start: {
    pageTitle: "Opciones de plan",
    header: "Empezar (Solo para transacciones inmobiliarias)",
  },

  order: {
    pageTitle: "Nuevo orden",
    header: "Haga su Pedido",
    confirmationTitle: "¡Gracias!",
    confirmationBody: `
      Su orden ha sido puesta. Se enviará una factura a la
      Oficial de título / fideicomiso y confirmación de pago y cobertura
      será enviado después del cierre.
    `,
  },

  policy: {
    confirmationTitle: "¡Gracias por renovar la póliza!",
    confirmationBody: `
     Su póliza ha sido renovada.
    `,
  },

  listingCoverage: "Cobertura del Listado",
  orderNow: "Ordenar Ahora",
  variableAmount: "Tengo Tanto:",
  choosePlan: "Elija Plan",
  mostPopular: "Plan más Popular",
  seePlans: "Ver Planes",

  placedBy: "Pedido realizado por:",

  propertyDetails: "Detalles de la propiedad",
  selectManager: "Seleccione su gerente de área",
  bigHouse: "¿La propiedad es más de 5000 pies cuadrados?",
  estClosingDate: "Estimated Closing Date",

  planOptionTitle: "Opciones de plan",

  single: {
    title: "Casa unifamiliar / Condominio / Townhome",
    blackback: "Plan Blackback",
    silverback: "Plan Silverback",
    kingKong: "Plan King Kong",
  },
  multi: {
    title: "Casas Multifamiliares",
    duplex: "Dúplex",
    triplex: "Triplex",
    fourplex: "Fourplex",
  },
  newConstruction: {
    title: "Nueva Construcción (años 2-4)",
    newHome: "Casa de una sola familia",
    newCondo: "Condominio / Townhome / Casa móvil",
  },

  addonTitle: "Opciones a la carta (Agregar a cualquier plan)",
  addonLabels: [
    "Refrigeracion Adicional",
    "Congelador independiente",
    "2da cocina (por unidad)",
    "Lavadora y secadora (por set)",
    "Suavizador de agua",
    "Calentador de agua adicional",
    "Vacío central",
    "Piscina y jacuzzi",
    "Cobertura de la línea de agua / gas / alcantarillado",
    // 'Piscina de agua salada y bañera de hidromasaje',
    // 'Máquina para hacer hielo independiente',
    "Sistema séptico y bombeo",
    "Bomba trituradora / eyector de alcantarillado",
    "Bomba de refuerzo / bomba de sumidero",
    "Cobertura independiente del edificio",
    "Bomba de pozo",
    "Apartamento en el sótano / Suite para suegra",
    "Pies cuadrados adicionales",
    "Tarifa de llamada de servicio prepago",
    "Cobertura sin fallas",
    "Protección AC",
    "Marca por marca",
    "Temporizador y sistema de rociadores",
    "crédito del comprador",
  ],

  form: {
    title: "Haga su Pedido",
    comments: "Comentarios adicionales",
    emails: "Confirmación de correo electrónico adicional",
    emailHelper: `
      A diferencia de los correos electrónicos mencionados anteriormente,
      separados por una coma
    `,
    placeOrder: "Realizar Pedido",
    payNow: "Pagar ahora",
    prepay: "Cobertura del comprador de prepago",
    required: "** falta de campos obligatorios",
  },
};
