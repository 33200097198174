import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingSpinner = () => (
  <div
    style={{
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.15)',
      zIndex: 2000,
      overflow: 'hidden',
    }}
  >
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <CircularProgress size={100} color="secondary" />
    </div>
  </div>
);

export default LoadingSpinner;
