import { handlePromise } from './data';

export const LOAD_PLAN_OPTIONS = 'consumer/LOAD_PLAN_OPTIONS';
export const LOAD_PLAN_FEATURES = 'consumer/LOAD_PLAN_FEATURES';
export const LOAD_PLAN_ADDONS = 'consumer/LOAD_PLAN_ADDONS';
export const SET_SELECTED_PLAN = 'consumer/SET_SELECTED_PLAN';
export const SET_SERVICE_CALL_FEE = 'consumer/SET_SERVICE_CALL_FEE';
export const CLEAR_CART = 'consumer/CLEAR_CART';
export const PLAN_TYPE = 'consumer/PLAN_TYPE';

export const loadPlanFeatures = promise => dispatch =>
  handlePromise(
    LOAD_PLAN_FEATURES,
    promise,
    'Error loading plan features',
    dispatch
  );

export const loadPlanOptions = promise => dispatch =>
  handlePromise(
    LOAD_PLAN_OPTIONS,
    promise,
    'Error loading plan options',
    dispatch
  );

export const loadPlanAddons = promise => dispatch =>
  handlePromise(
    LOAD_PLAN_ADDONS,
    promise,
    'Error loading plan addons',
    dispatch
  );

export const setSelectedPlan = (plan, index) => ({
  type: SET_SELECTED_PLAN,
  payload: { plan, index }
});

export const setServiceCallFee = fee => ({
  type: SET_SERVICE_CALL_FEE,
  payload: fee
});

export const clearCart = () => ({
  type: CLEAR_CART
});

export const setplantype = type => ({
  type: PLAN_TYPE,
  payload:type
});
