export const REPORT_ERROR = "app/REPORT_ERROR";
export const DISMISS_ERROR = "app/DISMISS_ERROR";

export const reportError = (error) => ({
  type: REPORT_ERROR,
  payload: { error },
});

export const dismissError = (message, index) => ({
  type: DISMISS_ERROR,
  payload: { index, message },
});
