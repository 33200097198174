import React, { PureComponent } from "react";
import { func } from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import PeaceOfMindIcon from "@mui/icons-material/Mood";
import ClaimIcon from "@mui/icons-material/ErrorOutline";
import TimeIcon from "@mui/icons-material/AccessTime";
import SecurityIcon from "@mui/icons-material/Security";
import CoverageIcon from "@mui/icons-material/MoneyOff";
import RepairIcon from "@mui/icons-material/Build";
import KeyIcon from "@mui/icons-material/VpnKey";
import ProfessionalIcon from "@mui/icons-material/Star";
import { withTranslation } from "react-i18next";

import BackgroundImage from "assets/images/kitchen.jpg";
import Button from "components/common/button";
import Section from "components/common/section";
import BenefitCard from "./benefit-card";

const BenefitIcons = [
  PeaceOfMindIcon,
  ClaimIcon,
  TimeIcon,
  SecurityIcon,
  CoverageIcon,
  RepairIcon,
  KeyIcon,
  ProfessionalIcon,
];

class Benefits extends PureComponent {
  static propTypes = {
    handleClick: func.isRequired,
  };

  renderBenefit = (props) => <BenefitCard {...props} />;

  renderBenefits = () => {
    const { t } = this.props;
    const benefits = t("home:benefits");
    return benefits.map((benefit, index) => (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        key={`benefit-${index}`}
        style={{ padding: "8px" }}
      >
        {this.renderBenefit({
          title: benefit.title,
          description: benefit.description,
          icon: BenefitIcons[index],
        })}
      </Grid>
    ));
  };

  render() {
    const { t, handleClick } = this.props;

    return (
      <Section image={BackgroundImage} shadow fullHeight>
        <Grid
          container
          style={{ margin: "-12px" }}
          align="center"
          justifyContent="center"
        >
          <Grid item xs={12} style={{ padding: "12px" }}>
            <Typography variant="h2" color="primary">
              {t("home:why")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            // spacing={2}
            style={{ margin: "-8px", padding: "12px" }}
          >
            {this.renderBenefits()}
            <Grid item xs={12} sm={6} md={4} style={{ padding: "8px" }}>
              <BenefitCard
                title={t("home:action2")}
                description={t("home:action1")}
                logo
              >
                <Grid item xs={12}>
                  <Button sx={{ color: "#fff" }} onClick={handleClick}>
                    {t("common:getStarted")}
                  </Button>
                </Grid>
              </BenefitCard>
            </Grid>
          </Grid>
        </Grid>
      </Section>
    );
  }
}

export default withTranslation(["common", "home"])(Benefits);
