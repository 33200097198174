import React from "react";
import { node, string, any, bool } from "prop-types";
import { transparentize } from "polished";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { withStyles } from '@mui/material/styles';
import styles from "./benefits.module.css";
import SilverBackLogo from "assets/logo.png";

const BenefitCard = ({
  icon: Icon,
  title,
  description,
  logo,
  children,
  classes,
  ...rest
}) => (
  <Card {...rest} className={logo ? styles?.card : styles?.transparent} raised>
    <Grid
      container
      // spacing={1}
      style={{ padding: "-8px" }}
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{ margin: "-8px" }}
    >
      <Grid item xs={4} style={{ padding: "8px" }}>
        {Icon && <Icon height={250} color="inherit" className={styles?.icon} />}
        {logo && (
          <img
            className={styles?.image}
            src={SilverBackLogo}
            alt="SilverBack"
          />
        )}
      </Grid>
      <Grid item xs={8} container sx={{ padding: "8px" }}>
        <Typography
          variant="subtitle1"
          color="inherit"
          align="left"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          style={{
            textTransform: "unset",
            fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
            fontWeight: "500",
          }}
          variant="subtitle2"
          color="inherit"
          align="left"
        >
          {description}
        </Typography>
        {children}
      </Grid>
    </Grid>
  </Card>
);

BenefitCard.propTypes = {
  icon: any,
  logo: bool,
  title: string.isRequired,
  children: node,
};

BenefitCard.defaultProps = {
  icon: null,
  children: null,
  logo: false,
};

const cardStyles = (theme) => ({
  height: "100%",
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

// const styles = (theme) => ({
//   card: {
//     ...cardStyles(theme),
//     color: theme.palette.primary.main,
//   },
//   transparent: {
//     ...cardStyles(theme),
//     color: theme.palette.common.white,
//     backgroundColor: transparentize(0.2, theme.palette.primary.main),
//   },
//   image: {
//     width: "100%",
//   },
//   icon: {
//     fontSize: theme.spacing(10),
//   },
// });

// export default withStyles(styles)(BenefitCard);
export default BenefitCard;
