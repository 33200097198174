import React from "react";
import { Link } from "react-router-dom";
import { reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid } from "@mui/material";
// import styled from '@emotion/styled'

import Section from "components/common/section";
import Button from "components/common/button";
import {
  renderTextField,
  renderSelectField,
  renderCheckbox,
} from "helpers/form";
import * as Validator from "helpers/validators";
import { getIsSignedIn } from "store/reducers";
import { connect } from "react-redux";
import SigninDialog from "./signin-dialog";
import { getFormValues } from "redux-form";
import { styled } from "@mui/material/styles";
import styles from "./property.module.css";
class PropertyInfo extends React.Component {
  state = { bigHouse: false, houseSize: 1, showConfirmation: false };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
    if (!event.target.checked) {
      this.setState({ houseSize: 0 });
    }
  };

  handleConfirmationClose = () => {
    this.setState({ showConfirmation: false });
    this.props.navigate("/auth/signin", { state: { type: "orderplan" } });
  };
  componentDidMount() {
    console.log("property...");
    const { isSignedIn, isbighouse } = this.props;
    // isSignedIn ? this.setState({showConfirmation:false}) : this.setState({showConfirmation:true})
    if (isbighouse !== undefined) {
      isbighouse.bigHouse !== undefined
        ? this.setState({ bigHouse: isbighouse.bigHouse })
        : this.setState({ bigHouse: false });
    }
  }
  render() {
    const { t, pristine, submitting, match, isSignedIn, invalid } = this.props;
    const { showConfirmation } = this.state;
    const bigHouseSize = [
      { value: 1, label: "6000 - 7000 Sq.Ft" },
      { value: 2, label: "7000 - 8000 Sq.Ft" },
      { value: 3, label: "8000 - 9000 Sq.Ft" },
      { value: 4, label: "9000 - 10000 Sq.Ft" },
      { value: 5, label: "10000+ Sq.Ft" },
    ];
    const getHouseSize = (event, index) => {
      this.setState({ houseSize: bigHouseSize[index - 1].value });
    };
    return (
      <Section style={{ justifyContent: "flex-start" }}>
        <SigninDialog
          onClose={this.handleConfirmationClose}
          open={showConfirmation}
        ></SigninDialog>
        <form>
          <Grid container sx={{ margin: "-8px" }}>
            <Grid item xs={12} sm={8} sx={{ padding: "8px" }}>
              <Field
                name="street"
                component={renderTextField}
                label={t("common:street")}
                validate={Validator.isRequired}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
              <Field
                name="unit"
                component={renderTextField}
                label={t("common:unit")}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
              <Field
                name="city"
                component={renderTextField}
                label={t("common:city")}
                validate={Validator.isRequired}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
              <Field
                name="state"
                component={renderTextField}
                label={t("common:state")}
                validate={Validator.isRequired}
              />
            </Grid>

            <Grid item xs={12} sm={4} sx={{ padding: "8px" }}>
              <Field
                name="postal"
                component={renderTextField}
                label={t("common:postal")}
                validate={[Validator.isRequired, Validator.isZipCode]}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              sx={{ padding: "8px", color: "rgba(0, 0, 0, 0.87)" }}
            >
              <Field
                name="bigHouse"
                label={t("bigHouse")}
                component={renderCheckbox}
                onChange={this.handleChange("bigHouse")}
                sx={{
                  "& .MuiFormControlLabel-label {": {
                    color: "black",
                  },
                  // color: "black",
                }}
                style={{ color: "black" }}
              />
            </Grid>

            {this.state.bigHouse && (
              <Grid item xs={12} sm={4}>
                <Field
                  name="squareFootage"
                  key="squareFootage"
                  component={renderSelectField}
                  label={t("squareFootage")}
                  options={bigHouseSize}
                  validate={Validator.isRequired}
                  onChange={(event, index) => getHouseSize(event, index)}
                />
              </Grid>
            )}
          </Grid>

          {/* <ButtonContainer> */}
          <div className={styles.ButtonContainer}>
            <Button
              type="submit"
              component={Link}
              to={`/homeowner/order/build`}
              disabled={pristine || submitting || invalid}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                },
              }}
            >
              {t("common:continue")}
            </Button>
          </div>
          {/* </ButtonContainer> */}
        </form>
      </Section>
    );
  }
}

// const ButtonContainer = styled('div')(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'row',
//   alignItems: 'center',
//   justifyContent: 'flex-end',
//   paddingTop: theme.spacing(5),
//   a: {
//     width: 200
//   },

//   [theme.breakpoints.down('sm')]: {
//     padding: `${theme.spacing(3)}px 0`,
//     a: { width: '100%' }
//   }
// }));
const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-end",
  paddingTop: theme.spacing(5),
  "& a": {
    width: 200,
  },
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(3)} 0`,
    "& a": { width: "100%" },
  },
}));
const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
  isbighouse: getFormValues("homeowner-property-info")(state),
});

export default compose(
  connect(mapStateToProps),
  withTranslation("homeowner"),
  reduxForm({ form: "homeowner-property-info", destroyOnUnmount: false })
)(PropertyInfo);
