import styled from '@emotion/styled'

import Section from 'components/common/section';

export const Wrapper = styled(Section)(({ theme }) => ({
  position: 'fixed',
  background: 'silver',
  color: '#fff',
  bottom: 0,
  left: 0,
  padding: 30,
  height: 100,
  zIndex: 3000,

  [theme.breakpoints.down('sm')]: {
    height: 79,
  },
}));
