import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { reduxForm, Field } from "redux-form";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Radio,
  FormControlLabel,
  Grid,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

import * as Validator from "helpers/validators";
import { renderTextField, renderRadioGroup } from "helpers/form";
import Button from "components/common/button";
import { getConsumerOrderSummary, getConsumerPlanTotal } from "store/reducers";
import ConfirmDialog from "./confirm-dialog";
import LoadingSpinner from "components/common/loading-spinner";
import { reportError } from "store/actions/app";
import PaymentService from "services/authorize";
import ZcrmService from "services/zohocrm";
import withRouter from "helpers/withRouters";
// import { clearToken } from "helpers/utils";

class PaymentMethod extends React.Component {
  state = {
    token: null,
    showConfirmation: false,
    paymentInfo: {},
    loading: false,
    transactionErrorMessage: "",
    transactionError: false,
  };

  render() {
    const {
      order: { addons },
    } = this.props;
    const {
      t,
      disabled,
      total,
      handleSubmit,
      service_call_fee,
      discount,
      totalAmount,
      policyInfo,
      paymentType,
      monthlyPrice,
      annualPrice,
    } = this.props;
    const { recurring } = this.state;
    const totalAmountWithDiscount = totalAmount - discount;

    const openConfirmationDialog = async (values) => {
      this.setState({ showConfirmation: true, paymentInfo: values });
    };

    const handleCheckout = () => {
      this.setState({ loading: true, showConfirmation: false });
      let paymentResposne = PaymentService.payment(
        this.state.paymentInfo,
        totalAmountWithDiscount
      );
      paymentResposne.then((response) => {
        if (response.status === true) {
          const token = ZcrmService.getToken();
          token.then((res) => {
            const renewPolicy = ZcrmService.renewPolicy(
              res.access_token,
              addons,
              totalAmountWithDiscount,
              response.data.Transaction_id,
              paymentType,
              monthlyPrice,
              annualPrice
            );
            renewPolicy
              .then((response) => {
                if (response.code === "success") {
                  this.props.navigate("/policyRenew/checkout/confirm", {
                    state: { type: "policy" },
                  });
                  this.setState({ loading: false, showConfirmation: false });
                }
              })
              .catch((e) => {
                // if (e.name === "StatusCodeError") clearToken();
              });
          });

          // props.handlePaymentResponse(response.data.Transaction_id, true)
        } else {
          this.setState({
            loading: false,
            showConfirmation: false,
            transactionError: true,
            transactionErrorMessage: response.errors.error_message.includes(
              "AnetApi/xml/v1/schema/AnetApiSchema.xsd:cardCode"
            )
              ? "Invalid CVV Code"
              : response.errors.error_message,
          });
          setTimeout(
            () =>
              this.setState({
                transactionError: false,
                transactionErrorMessage: "",
              }),
            5000
          );
        }
      });
    };

    return (
      <Card style={{ height: "100%" }}>
        <form
          onSubmit={handleSubmit((values) => openConfirmationDialog(values))}
        >
          <ConfirmDialog
            total={totalAmountWithDiscount}
            open={this.state.showConfirmation}
            onCancel={() => this.setState({ showConfirmation: false })}
            onConfirm={() => handleCheckout()}
            callFee={service_call_fee}
          />

          <CardHeader
            title="Payment Method"
            titleTypographyProps={{ color: "primary" }}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  name="first_name"
                  component={renderTextField}
                  label={t("common:nameOnCard")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="card_number"
                  component={renderTextField}
                  label={t("common:cardNumber")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  name="expiry_date"
                  placeholder="MM/YY"
                  component={renderTextField}
                  label={t("common:expDate")}
                  validate={Validator.isValidExpiryDate}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Field
                  name="card_code"
                  component={renderTextField}
                  label={t("common:cvc")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name="address"
                  component={renderTextField}
                  label={t("common:street")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="city"
                  component={renderTextField}
                  label={t("common:city")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="state"
                  component={renderTextField}
                  label={t("common:state")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Field
                  name="zip"
                  component={renderTextField}
                  label={t("common:postal")}
                  validate={Validator.isRequired}
                />
              </Grid>
              <Grid item xs={12}>
                <Field name="recurring" component={renderRadioGroup}>
                  <FormControlLabel
                    value="true"
                    label={`Billed Monthly (x 12): $${total.monthly}`}
                    labelPlacement="end"
                    control={<Radio color="primary" />}
                  />
                  <FormControlLabel
                    value="false"
                    label={`Single Payment: $${total.annual}`}
                    labelPlacement="end"
                    control={<Radio color="primary" />}
                  />
                </Field>
              </Grid>
            </Grid>
          </CardContent>
          {this.state.transactionError && (
            <Grid item md={12} xs={12}>
              <div
                style={{
                  margin: "10px 10px",
                  padding: "10px",
                  backgroundColor: "#f44336",
                  borderRadius: "5px",
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <ErrorIcon />
                <div style={{ marginLeft: "10px" }}>
                  {this.state.transactionErrorMessage}
                </div>
              </div>
            </Grid>
          )}
          <CardActions
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {service_call_fee === undefined && (
              <Button
                fullWidth={false}
                onClick={() => {
                  this.props.navigate("/policyRenew", {
                    state: { policyInfo: policyInfo },
                  });
                }}
                style={{
                  height: "40px",
                  color: "white",
                  fontSize: "0.9375rem",
                }}
              >
                {t("goBack")}
              </Button>
            )}
            <Button
              fullWidth={false}
              disabled={disabled || this.props.submitting}
              type="submit"
              style={{ height: "40px", color: "white" }}
            >
              {t("checkout")}
            </Button>
          </CardActions>
        </form>
        {this.state.loading && <LoadingSpinner />}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  total: getConsumerPlanTotal(state),
  order: getConsumerOrderSummary(state),
});

export default compose(
  withRouter,
  withTranslation("homeowner"),
  reduxForm({
    form: "payment-method",
    initialValues: { recurring: "false" },
  }),
  connect(mapStateToProps, { reportError })
)(PaymentMethod);
