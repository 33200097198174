import firebase, { auth } from "services/firebase";
import {
  signOut as logout,
  GoogleAuthProvider,
  signInWithRedirect,
  FacebookAuthProvider,
  getRedirectResult,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
} from "firebase/auth";

export default class AuthService {
  static sendEmailVerification() {
    const user = auth.currentUser;

    if (!user) return;

    return sendEmailVerification(user);
  }

  static signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password).then(() =>
      AuthService.sendEmailVerification()
    );
  }

  static signIn(email, password) {
    try {
      return signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      console.log(error, "signinError");
    }
  }

  static googleSignIn() {
    const provider = new GoogleAuthProvider();

    return signInWithRedirect(auth, provider);
  }

  static facebookSignIn() {
    const provider = new FacebookAuthProvider();
    console.log(provider, auth, "face");
    return signInWithRedirect(auth, provider);
  }

  static async linkAccounts(email, newCredential) {
    try {
      const providers = await fetchSignInMethodsForEmail(auth, email);
      console.log(providers);
    } catch (error) {
      console.log(error);
    }
  }

  static getOAuthResults() {
    return getRedirectResult(auth).catch((error) => {
      if (error.code === "auth/account-exists-with-different-credential") {
        // AuthService.linkAccounts(email, credential); // TODO:

        throw new Error(`
            You have already created an account with a different
            provider. Please try signing in again using that provider.
          `);
      }

      throw error;
    });
  }

  static signOut() {
    return logout(auth);
  }
}
