export default {
  motto: `te cubrimos`,
  why: "¿Por qué elegir SilverBack?",
  action1:
    "Deje De Pagar Costosas Reparaciones Para Su Hogar De Su Propio Bolsillo.",
  action2: "¡Obtenga Hoy Una Garantía De Hogar SilverBack!",

  comparePlans: "Comparar Planes",

  getStartedTitle: "Se Producirán Fallas Inesperadas",
  getStartedCTA: "Proteja su Hogar Ahora",

  benefits: [
    {
      title: "Pedazo de Mente",
      description: `
        Usted sabe que los principales sistemas de electrodomésticos con el tiempo se
        romperán en su hogar; con la cobertura de SilverBack Home Warranty, le damos la
        espalda y le damos tranquilidad.
      `,
    },
    {
      title: "Servicio de Reclamo de Emergencia 24/7",
      description: `
        Necesita una línea de emergencia para reportar un problema serio ¿Descompostura?
        ¡Nosotros siempre estamos ahí!
      `,
    },
    {
      title:
        "Sin Restricciones de Edad en Electrodomésticos y Sistemas Cubiertos",
      description: `
        Algunas casas y electrodomésticos son nuevos y otros son viejos; los cubrimos a todos por igual.
      `,
    },
    {
      title: "Protección Cntra lo Desconocido",
      description: `
        SilverBack Home Warranty brinda cobertura para servicios preexistentes,
        condiciones indetectables que a través de una inspección visual o prueba
        mecánica no podrían ser detectados.
      `,
    },
    {
      title: "Cobertura de listado GRATUITO",
      description: `
        Los vendedores también necesitan cobertura y ofrecemos cobertura gratuita durante el periodo de lista.
      `,
    },
    {
      title: "Reparar o Reemplazar",
      description: `
        ¡Si no podemos arreglarlo, lo reemplazamos! Ofrecemos servicios de reparación
        o reemplazo durante 12 meses a la vez por una tarifa de llamada de servicio
        baja en sistemas cubiertos y componentes. Vea los detalles de la cobertura en
        el contrato de servicio para mas detalles.
      `,
    },
    {
      title: "Servicio para Cambiar Cerraduras",
      description: `
        Cambie hasta 4 cerraduras por una tarifa de llamada de servicio baja.
      `,
    },
    {
      title: "Los Mejores Profesionales de Servicio",
      description: `
        Nuestra red de servicios de contratistas son profesionales, están asegurados,
        con licencia y son los mejores en la industria.
      `,
    },
  ],
};
