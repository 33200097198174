import { v4 as uuid } from "uuid";

import firebase, { getUser } from "services/firebase";
import {
  getDatabase,
  ref,
  get,
  set,
  query,
  orderByChild,
  equalTo,
  push,
  startAt,
  endAt,
  onValue,
} from "firebase/database";
// import utils from 'helpers/utils';
import * as models from "./models";
import moment from "moment";

window.firebase = firebase;

export default class DataService {
  static getAgents() {
    // return firebase
    //   .database()
    //   .ref("agents")
    //   .once("value")
    //   .then((snapshot) => snapshot.val());
    const db = getDatabase();
    return get(ref(db, "agents")).then((snapshot) => snapshot.val());
  }

  static async updateAgents(agents) {
    agents.map((agent) => {
      if (!agent) return Promise.resolve();

      const safeEmail = agent.email.replace(/\./g, "__");
      // const ref = firebase.database().ref(`agents/${safeEmail}`);
      // return ref.set(agent);
      const db = getDatabase();
      const agentRef = ref(db, `agents/${safeEmail}`);
      return set(agentRef, agent);
    });
  }

  static async updateEscrow(escrow) {
    // eslint-disable-next-line
    escrow.titleOfficer !== undefined
      ? (escrow.titleOfficer = escrow.titleOfficer)
      : (escrow.titleOfficer = "");
    // eslint-disable-next-line
    escrow.titlePhone !== undefined
      ? (escrow.titlePhone = escrow.titlePhone)
      : (escrow.titlePhone = "");
    // const titlesRef = firebase.database().ref("titles");
    // titlesRef
    //   .orderByChild("titleEmail")
    //   .equalTo(escrow["titleEmail"])
    //   .once("value", function (snapshot) {
    //     const val = snapshot.val();
    //     if (val) {
    //       for (const key in val) {
    //         const ref = firebase.database().ref(`titles/${key}`);
    //         return ref.set(escrow);
    //       }
    //     } else {
    //       return titlesRef.push(escrow);
    //     }
    //   });
    try {
      const db = getDatabase();
      const titlesRef = ref(db, "titles");
      const titleQuery = query(
        titlesRef,
        orderByChild("titleEmail"),
        equalTo(escrow["titleEmail"])
      );
      get(titleQuery)
        .then((snapshot) => {
          const val = snapshot.val();
          if (val) {
            for (const key in val) {
              const titleRef = ref(db, `titles/${key}`);
              return set(titleRef, escrow);
            }
          } else {
            return push(titlesRef, escrow);
          }
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  }

  static pluckAgent(key, values) {
    const company = `${key}AgentCompany`;
    const email = `${key}AgentEmail`;
    const phone = `${key}AgentPhone`;
    const name = `${key}AgentName`;

    // don't try to modify or add an agent if there's no name or email
    if (!values[name] || !values[email]) return;

    const nameParts = values[name].split(" ");

    return {
      firstName: nameParts[0],
      lastName: nameParts[nameParts.length - 1],
      company: values[company] || "",
      email: values[email].toLowerCase(),
      phone: (values[phone] || "").replace(/\D/g, ""),
    };
  }

  static searchEscrow(field, Query, cb) {
    Query = capitalize(Query);
    // const ref = firebase.database().ref(`titles`);
    // ref
    //   .orderByChild(field)
    //   .startAt(query)
    //   .endAt(query + "\uf8ff")
    //   .once("value")
    //   .then((snapshot) => {
    //     cb(snapshot.val());
    //   });
    const db = getDatabase();
    const titlesRef = ref(db, "titles");
    // const q = query(
    //   titlesRef,
    //   orderByChild(field),
    //   startAt(Query),
    //   endAt(Query + "\uf8ff")
    // );

    // get(q)
    //   .then((snapshot) => {
    //     if (snapshot.exists()) {
    //       cb(snapshot.val());
    //     } else {
    //       console.log("No data available");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    const queryRef = ref(db, "titles");
    onValue(
      query(
        queryRef,
        orderByChild(field),
        startAt(Query),
        endAt(Query + "\uf8ff")
      ),
      (snapshot) => {
        cb(snapshot.val());
      }
    );
  }

  static sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static async fetch_retry(url, options, n) {
    try {
      return await fetch(url, options);
    } catch (err) {
      if (n >= 1) {
        err.message += ", " + n;
        throw err;
      }
      await DataService.sleep(5000);
      return await DataService.fetch_retry(url, options, n + 1);
    }
  }

  static async submitOrder(
    values,
    isLimited,
    planTotal,
    optionTotal,
    discount
  ) {
    console.log("submit form", discount);
    try {
      const user = await getUser();
      console.log(user, "user", values);
      // const { planOption, addons } = values;
      // const amount = utils.getPlanAmount(planOption);
      const amount = planTotal;
      // const optionAmount = (isLimited)? optionTotal:utils.getAddonTotal(addons);
      const optionAmount = optionTotal;
      values.closingDate =
        typeof values?.closingDate === "object"
          ? moment(values?.closingDate).toISOString()
          : moment(values?.closingDate, "DD-MM-YYYY").toISOString();
      const order = models.Order(values, amount, optionAmount, discount);

      const crmReq = await DataService.fetch_retry(
        process.env.REACT_APP_ORDER_HOOK,
        {
          method: "POST",
          mode: "cors",
          body: JSON.stringify({ user, order }),
          //  headers: {
          //     'Content-Type': 'application/json',
          //     accept: 'application/json'
          //   }
          headers: {
            "Content-Type": "text/plain",
            accept: "text/plain",
          },
        },
        1
      )
        .then((res) => res.json())
        .catch((err) => ({
          id: `ERROR CREATING CRM ORDER: ${err.message}`,
        }));

      const crmOrder = {
        ...order,
        crmId: crmReq.id || "NA",
      };

      // TODO: deprecate Firefabase data store once CRM is good to go
      // return firebase
      //   .database()
      //   .ref(`users/${user.uid}/orders/${order.id}`)
      //   .set(crmOrder)
      //   .then(() => crmOrder);

      const db = getDatabase();
      const orderRef = ref(db, `users/${user.uid}/orders/${order.id}`);
      return set(orderRef, crmOrder).then(() => crmOrder);
    } catch (e) {
      console.log(e);
    }
  }

  static async submitClaim(values) {
    const claim = models.Claim(values);
    const user = await getUser();
    buildClaim(claim);
    const crmReq = await DataService.fetch_retry(
      process.env.REACT_APP_SERVICE_HOOK,
      {
        method: "POST",
        mode: "cors",
        body: JSON.stringify({ user, claim }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
      1
    )
      .then((res) => res.json())
      .catch((err) => ({
        id: `ERROR CREATING CRM CLAIM: ${err.message}`,
      }));

    const crmClaim = {
      ...claim,
      crmId: crmReq.id || "NA",
    };

    // TODO: deprecate Firebase data store once CRM is good to go
    // return firebase
    //   .database()
    //   .ref(`users/${user.uid}/claims/${claim.id}`)
    //   .set(crmClaim)
    //   .then(() => crmClaim);
    const db = getDatabase();
    const claimRef = ref(db, `users/${user.uid}/claims/${claim.id}`);
    return set(claimRef, crmClaim).then(() => crmClaim);
  }

  static async saveConsumerOrder(order, chargeId, isSubscription = false) {
    const user = await getUser();
    const id = uuid();

    // return firebase
    //   .database()
    //   .ref(`users/${user.uid}/plans/${id}`)
    //   .set({
    //     ...order,
    //     id,
    //     chargeId,
    //     isSubscription,
    //     createdAt: new Date().getTime(),
    //   });
    const db = getDatabase();
    const planRef = ref(db, `users/${user.uid}/plans/${id}`);
    return set(planRef, {
      ...order,
      id,
      chargeId,
      isSubscription,
      createdAt: new Date().getTime(),
    });
  }

  static async getpromoCodes(category) {
    //   return firebase
    //     .database()
    //     .ref(`coupons/${category}`)
    //     .once("value")
    //     .then((snapshot) => snapshot.val());
    // }
    const db = getDatabase();
    const couponRef = ref(db, `coupons/${category}`);
    return get(couponRef).then((snapshot) => snapshot.val());
  }
}

function capitalize(str) {
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

function buildClaim(claim) {
  const jsonObject =
    claim.services !== undefined ? claim.services.map(JSON.stringify) : [];
  const uniqueSet = new Set(jsonObject);
  claim.services = Array.from(uniqueSet).map(JSON.parse);
  claim.services = claim.services
    .map((services) => {
      return services.title;
    })
    .join();
}
