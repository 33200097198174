import styled from '@emotion/styled'
import MuiCard from '@mui/material/Card';

export const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderRadius: 5,
}));

export const Card = styled(MuiCard)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  padding: 15,
  [theme.breakpoints.down('sm')]: {
    padding: 5,
  },
}));

export const ErrorMessage = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.error.light,
  borderRadius: 10,
  marginTop: theme.spacing(2),
}));
