import React from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
// import { withStyles } from '@mui/material/styles';

const Alert = ({ message, onDismiss, classes }) => (
  <Snackbar
    open
    autoHideDuration={5000}
    anchorOrigin={{
      horizontal: "right",
      vertical: "bottom",
    }}
    sx={{ maxWidth: "100%" }}
    onClose={onDismiss}
  >
    <SnackbarContent
      classes={{ root: classes?.snackbar }}
      sx={{
        backgroundColor: "#c62828",
        width: "100%",
        padding: "24px 16px",
        display: "flex",
        justifyContent: "center",
      }}
      message={
        <span style={{ display: "flex", alignItems: "center" }}>
          <ErrorIcon
            sx={{ fontSize: "20", opacity: " 0.9", marginRight: "16px" }}
          />
          {message}
        </span>
      }
      action={[
        <IconButton
          key={message}
          aria-label="Close"
          color="inherit"
          onClick={onDismiss}
        >
          <CloseIcon />
        </IconButton>,
      ]}
    />
  </Snackbar>
);

const styles = (theme) => ({
  snackbar: {
    backgroundColor: theme.palette.error.dark,
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

// export default withStyles(styles)(Alert);
export default Alert;
