import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { change } from "redux-form";

import { sendContact } from "store/actions/email";
import EmailService from "services/email";
import Logo from "assets/logo-full.png";
import BackgroundImage from "assets/images/living-room.jpg";
import Section from "components/common/section";

import ContactForm from "./components/contact-form";
import * as Styled from "./styled";

class Contact extends Component {
  handleSubmit = (values) => {
    this.props.sendContact(EmailService.sendContact(values));
    this.props.navigate("/");
  };

  render() {
    const { t } = this.props;

    return (
      <Section image={BackgroundImage} imageMask>
        <Helmet title={`${t("nameShort")}: ${t("contactUs")}`} />
        <Styled.Content>
          <Styled.BrandContainer>
            <Styled.Image src={Logo} alt={t("nameShort")} />
            <Typography color="inherit" variant="h1" align="center">
              Call: 833.750.6400
            </Typography>
          </Styled.BrandContainer>
          <Styled.FormContainer>
            <ContactForm
              onSubmit={this.handleSubmit}
              change={this.props.change}
            />
          </Styled.FormContainer>
        </Styled.Content>
      </Section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendContact: (promise) => dispatch(sendContact(promise)),
  change,
});

export default compose(
  withTranslation("common"),
  connect(null, mapDispatchToProps)
)(Contact);
