import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material";
import theme from "helpers/theme";
// import { withStyles } from '@mui/material/styles';

const CommonButton = (props) => {
  return props.color === "white" ? (
    <WhiteStyledButton {...props} />
  ) : (
    <StyledButton {...props} />
  );
};
const TheButton = ({ children, ...props }) => (
  <Button
    // className={color === "white" ? classes?.whiteButton : classes?.button}
    variant="raised"
    size="large"
    color={props.color === "white" ? "default" : props.color || "#00557E"}
    sx={{
      backgroundColor:
        props.color === "white"
          ? "rgba(0, 0, 0, 0.87)"
          : props.color === "secondary"
          ? "#00AEEF"
          : "#00557E",
      lineHeight: "1.4em",
    }}
    fullWidth
    {...props}
  >
    {children}
  </Button>
);

const styles = (theme) => ({
  button: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
  },

  whiteButton: {
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    textTransform: "uppercase",
    backgroundColor: theme.palette.common.white,
    // color: theme.palette?.primary?.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
  },
});
const StyledButton = styled(TheButton)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  textTransform: "uppercase",
  "&:hover": {
    backgroundColor: "rgb(0, 59, 88)",
  },
}));
const WhiteStyledButton = styled(TheButton)(({ theme }) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  textTransform: "uppercase",
  backgroundColor: "#fff",
  color: "#00557E",
  // color: theme.palette?.primary?.main,
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}));
// export default withStyles(styles)(CommonButton);
export default CommonButton;
