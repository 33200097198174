import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  ListItemText,
  ListItem,
  List,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
class ClaimHistory extends Component {
  parseObjectToString = (object) => {
    const values = Object.values(object);

    return values;
  };

  renderClaims = () => {
    const { claims } = this.props;
    if (Object.keys(claims).length > 0) {
      const claimIds = Object.keys(claims);

      return claimIds.map((id) => {
        const claim = claims[id];

        return (
          <Grid item xs={12} key={id}>
            <Paper key={id} elevation={1} style={{ height: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Submitted"
                        secondary={new Date(claim.date).toLocaleDateString()}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Policy #"
                        secondary={claim.policy}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Property"
                        secondary={
                          claim.address
                            ? claim.address.unit +
                              " " +
                              claim.address.street +
                              ", " +
                              claim.address.city +
                              ", " +
                              claim.address.state +
                              " - " +
                              claim.address.postal
                            : "No address Saved"
                        }
                      />
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <List>
                    {Array.isArray(claim.services) === true && (
                      <ListItem>
                        <ListItemText
                          primary="Requested Services"
                          secondary={
                            claim.services
                              ? this.parseObjectToString(
                                  claim.services.map((service) => {
                                    return service.title;
                                  })
                                )
                              : "No Service Requested"
                          }
                        />
                      </ListItem>
                    )}
                    {Array.isArray(claim.services) === false && (
                      <ListItem>
                        <ListItemText
                          primary="Requested Services"
                          secondary={
                            claim.services
                              ? this.parseObjectToString(claim.services)
                              : "No Service Requested"
                          }
                        />
                      </ListItem>
                    )}
                    <ListItem>
                      <ListItemText
                        primary="Description"
                        secondary={claim.description}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        );
      });
    } else {
      return (
        <Grid item xs={12} sm={12}>
          <Paper elevation={0} style={{ height: "100%" }}>
            <List>
              <ListItem>
                <ListItemText primary="No Previous Claims to display" />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      );
    }
  };

  render() {
    return (
      <Card>
        <CardHeader
          title="Warranty Claim History"
          // action={
          //   <Link component="Link" variant="subheading" to="/claims">
          //     Request Service
          //   </Link>
          // }
        />
        <CardContent>
          <Grid container spacing={2}>
            {this.renderClaims()}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

export default ClaimHistory;
