import { dataLoading, dataLoaded } from "./data";
import { reportError } from "./app";

export const AUTH_STATE_CHANGE = "auth/AUTH_STATE_CHANGE";
export const SIGN_IN = "auth/SIGN_IN";
export const REGISTER = "auth/REGISTER";

export const onAuthStateChange = (user) => ({
  type: AUTH_STATE_CHANGE,
  payload: { user },
});

export const signIn = (promise) => (dispatch) => {
  dispatch(dataLoading(SIGN_IN));

  return promise
    .then((data) => dispatch(dataLoaded(SIGN_IN, data)))
    .catch((error) => dispatch(reportError(error.message)));
};

export const register = (promise) => (dispatch) => {
  dispatch(dataLoading(REGISTER));
  console.log(promise, "prooomise");
  return promise
    .then((data) => dispatch(dataLoaded(REGISTER, data)))
    .catch((error) => dispatch(reportError(error.message)));
};
