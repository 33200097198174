import react from "react";
import Helmet from "react-helmet";
import Section from "components/common/section";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import WhatAre_we from "./components/what-are-we";
import GoalsSection from "./components/Goals";
import RequestQuote from "../homeowner/landing/quote-form";
import { requestQuote } from "store/actions/email";
import styles from "./aboutus.module.css";
import bannerImage from "assets/images/about-us-banner.png";
import EmailService from "services/email";
const AboutUs = () => {
  const { t } = useTranslation("maintenance");
  const dispatch = useDispatch();

  const handleRequestQuote = (values) => {
    dispatch(requestQuote(EmailService.requestQuote(values)));
  };
  return (
    <>
      <Helmet title={`${t("common:nameShort")}: ${t("title")}`} />

      <Grid container direction="column">
        <Section
          className={styles.commonSection}
          style={{ padding: "0", color: "unset" }}
          image={bannerImage}
        >
          {/* <div> */}
          <div className={styles.banner}>
            <div className={styles.bannerTxt}>
              <h3>Homeownership Made Simple</h3>
              <div>
                We're only as great as the players on our team--and our team is
                the best!
              </div>
            </div>
          </div>
        </Section>
        <Section style={{ padding: "0" }}>
          <WhatAre_we />
        </Section>
        <Section
          style={{ backgroundColor: "#edf9ff", color: "unset", padding: "0" }}
        >
          <GoalsSection />
        </Section>
        <RequestQuote
          style={{
            zIndex: 3,
            backgroundColor: "#fff",
            padding: "48px 8px",
          }}
          fromaboutus
          onSubmit={handleRequestQuote}
        />
        {/* </div> */}
      </Grid>
    </>
  );
};

export default AboutUs;
