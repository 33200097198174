import React, { useState } from "react";
import { useEffect } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import "./autocomplete.css";

// autocomplete without redux form
// export const StreetField = ({ label, focusOut, change, name, isStreetError }) => {

//     const [address, setAddress] = useState(null);
//     const [error, setError] = useState(false);

//     const { ref } = usePlacesWidget({
//         apiKey: 'AIzaSyAbVwtCb4_KmZCqex0RHg9erv-ZZUfOzto',
//         onPlaceSelected: (place) => {
//             let street = []
//             place.address_components.forEach(element => {
//                 if (element.types[0] === 'administrative_area_level_1') change('city', element.long_name);
//                 if (element.types[0] === 'country') change('state', element.long_name);
//                 if (element.types[0] === 'postal_code') change('postal', element.long_name);
//                 if (element.types[0] === 'street_number') street.push(element.long_name);
//                 if (element.types[0] === 'route') street.push(element.long_name);
//                 if (element.types[0] === 'locality') street.push(element.long_name);
//             });
//             change('street', street.toString());
//             setAddress(street.toString());
//         },
//         options: {
//             types: ["address"],
//             componentRestrictions: { country: "us" },
//         },
//     });

//     const handleChange = (event) => {
//         setAddress(event.target.value);
//     }

//     useEffect(() => {
//         (address !== null && address === '') ? setError(false) : setError(true);
//     }, [address])

//     useEffect(() => {
//         isStreetError ? setError(false) : setError(true);
//     }, [isStreetError])

//     return (
//         <div className={error ? "form" : "form-error"}>
//             <input
//                 className={error ? "form-control" : "form-control-error"}
//                 ref={ref}
//                 onBlur={(e) => focusOut(e.target.value)}
//                 value={address}
//                 onChange={handleChange}
//                 placeholder={label}
//                 name={name}
//             />
//             <label for="">{label}</label>
//             {error ? "" : <p className='error'>Required</p>}
//         </div>
//     )

// }

export const StreetFieldAuto = ({
  input,
  label,
  meta: { touched, error },
  change,
}) => {
  const [address, setAddress] = useState("");
  const [err, setErr] = useState(false);

  useEffect(() => {
    touched ? setErr(true) : setErr(false);
  }, [touched]);

  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAbVwtCb4_KmZCqex0RHg9erv-ZZUfOzto",
    onPlaceSelected: (place) => {
      let street = [];
      place.address_components.forEach((element) => {
        if (element.types[0] === "locality") {
          change("city", element.long_name);
        } else if (element.types[0] === "sublocality_level_1") {
          change("city", element.long_name);
        }
        if (element.types[0] === "administrative_area_level_1")
          change("state", element.long_name);
        if (element.types[0] === "postal_code")
          change("postal", element.long_name);
        if (element.types[0] === "street_number")
          street.push(element.long_name);
        if (element.types[0] === "route") street.push(element.long_name);
        // if (element.types[0] === 'locality') street.push(element.long_name);
      });
      change("street", street.toString().replace(",", " "));
      setAddress(street.toString());
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  return (
    <div className={error && touched ? "form-error" : "form"}>
      <input
        className={error && touched ? "form-control-error" : "form-control"}
        ref={ref}
        {...input}
        onChange={(e) => {
          setAddress(e.target.value);
        }}
        value={address}
        id="streetautoInput"
      />
      <label htmlFor="streetautoInput">{label}</label>
      {err ? <FormHelperText error>{error}</FormHelperText> : ""}
    </div>
  );
};
