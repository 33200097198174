const { Modal, Box, IconButton, Typography } = require("@mui/material");

import CloseIcon from "@mui/icons-material/Close";
import { AddonDesc } from "constants/addons";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:"90%", md:650},
  // bgcolor: 'background.paper',
  bgcolor: "white",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius:0.5,
  p: 3,
  maxHeight:"350px",
  overflow:"auto"
};
const DetailsModal = ({ open, onClose, selectedAddon }) => {

  const Addon = AddonDesc[selectedAddon]
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <IconButton
          onClick={onClose}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6"  component="h2" sx={{fontWeight:"400", fontFamily: "'Roboto','Helvetica','sans-serif'"}} color="GrayText">
          {selectedAddon}
        </Typography>
      {Addon?.covered ? <><Typography variant="h6" sx={{ fontSize: "0.9rem" }} color="primary">
                  COVERED
              </Typography><Typography sx={{ fontSize: "16px" }}>{Addon?.covered}</Typography></>:null}
        {Addon?.not_covered ? <><Typography variant="h6" sx={{fontSize:"0.9rem"}} color="primary">
          NOT COVERED
          </Typography><Typography sx={{ fontSize: "16px" }}>{Addon?.not_covered}</Typography></>:null}

      </Box>
    </Modal>
  );
};
export default DetailsModal;
