import React from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";

const primary = "#00557E";
const secondary = "#00AEEF";

const fontOverride = {
  fontFamily: `'PT Sans Narrow', 'Roboto', sans-serif`,
  fontWeight: 700,
  textTransform: "capitalize",
};

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      contrastText: "#fff",
    },
    secondary: {
      main: secondary,
      contrastText: "#fff",
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: `'PT Sans Narrow', 'Roboto', sans-serif`,
    h1: {
      ...fontOverride,
      fontSize: "3.5rem",
      lineHeight: "1.30357em",
      "@media (max-width:600px)": { fontSize: "2.5rem" },
    },
    h2: {
      ...fontOverride,
      fontSize: " 2.8125rem",
      lineHeight: "1.1333em",
    },
    h3: {
      ...fontOverride,
      fontSize: "2.125rem",
    },
    h4: { ...fontOverride },
    h5: {
      ...fontOverride,
      fontSize: "1.5rem",
      lineHeight: "1.35417em",
    },
    h6: { ...fontOverride },
    subtitle1: {
      ...fontOverride,
      fontSize: "1.3125rem",
      lineHeight: "1.166667em",
    },
    subtitle2: {
      ...fontOverride,
      fontSize: "0.875rem",
      lineHeight: "1.71429em",
    },
    button: { ...fontOverride, fontSize: "0.9375rem" },
    body1: {
      fontFamily: "'Roboto','Helvetica','sans-serif'",
      fontSize: "18px",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
  </MuiThemeProvider>
);

export default theme;
