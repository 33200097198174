import { reset } from 'redux-form';

import { reportError } from './app';
export const SEND_CONTACT = 'email/SEND_CONTACT';
export const SEND_ORDER_ALERT = 'email/SEND_ORDER_ALERT';
export const SEND_CLAIM_ALERT = 'email/SEND_CLAIM_ALERT';
export const REQUEST_QUOTE = 'email/REQUEST_QUOTE';

export const sendingEmail = (type) => ({
  type,
  payload: { loading: true },
});

export const emailSent = (type) => ({
  type,
  payload: { loading: false },
});

export const requestQuote = (promise) => (dispatch) => {
  dispatch(sendingEmail(REQUEST_QUOTE));

  return promise
    .then((data) => {
      dispatch(reset('homeowner-request-quote'));
      dispatch(emailSent(REQUEST_QUOTE, data));
    })
    .catch((err) => dispatch(reportError(`Error sending quote request email: ${err.message}`)));
};

export const sendContact = (promise) => (dispatch) => {
  dispatch(sendingEmail(SEND_CONTACT));

  return promise
    .then((data) => dispatch(emailSent(SEND_CONTACT, data)))
    .catch((err) => dispatch(reportError(`Error sending contact email: ${err.message}`)));
};

export const sendOrderAlert = (promise) => (dispatch) => {
  dispatch(sendingEmail(SEND_ORDER_ALERT));

  return promise
    .then((data) => dispatch(emailSent(SEND_ORDER_ALERT, data)))
    .catch((err) => dispatch(reportError(`Error sending order alert: ${err.message}`)));
};

export const sendClaimAlert = (promise) => (dispatch) => {
  dispatch(sendingEmail(SEND_CLAIM_ALERT));

  return promise
    .then((data) => dispatch(emailSent(SEND_CLAIM_ALERT, data)))
    .catch((err) => dispatch(reportError(`Error sending claim alert: ${err.message}`)));
};
