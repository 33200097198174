import styled from '@emotion/styled'
import { lighten, darken } from 'polished';

export const Footer = styled('footer')(({ theme }) => ({
  fontSize: 12,
  textTransform: 'uppercase',
  color: lighten(0.5, theme.palette.primary.main),
  background: darken(0.1, theme.palette.primary.main),
  padding: theme.spacing(3),

  '& .locale': {
    color: lighten(0.3, theme.palette.primary.main)
  },
  '& .credit': {
    color: lighten(0.3, theme.palette.primary.main),
    marginTop: 10
  },
  '& a': {
    textDecoration: 'none',
    color: lighten(0.4, theme.palette.primary.main)
  },

  '& .info': {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      padding: theme.spacing(2)
    }
  }
}));

export const FooterContent = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: theme.spacing(180),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  margin: '0 auto',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse'
  }
}));
