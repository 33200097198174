import styled from "@emotion/styled";
import MuiCard from "@mui/material/Card";
import MuiModal from "@mui/material/Modal";

export const Modal = styled(MuiModal)({}, ({ theme }) => ({
  "&&": {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "center",
  },
}));

export const ModalContent = styled(MuiCard)({}, ({ theme }) => ({
  width: "50%",
  alignSelf: "center",
  padding: 25,
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
