import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import { reset } from "redux-form";

import ConsumerService from "services/consumer";
import {
  loadPlanFeatures,
  loadPlanOptions,
  loadPlanAddons,
  setSelectedPlan,
  setServiceCallFee,
  setplantype,
} from "store/actions/consumer";
import {
  getConsumerPlanFeatures,
  getConsumerPlanOptions,
  getConsumerPlanAddons,
  getConsumerPlanTotal,
  getConsumerFeatureCount,
  getServiceCallFee,
  getConsumerOrder,
  getplantype,
  getRenewalPrice,
} from "store/reducers";
import Section from "components/common/section";

import Addons from "./addons";
import PlanTotal from "./plan-total";
import PolicyTotal from "./policy-total";

export class RenewPolicy extends React.Component {
  state = { houseSize: this.props.location.search.split("=")[1] };
  componentDidMount() {
    this.props.loadPlanFeatures(ConsumerService.getPlanFeatures());
    this.props.loadPlanOptions(ConsumerService.getPlanOptions());
    this.props.loadPlanAddons(ConsumerService.getPlanAddons());
    this.setState({ houseSize: this.props.location.search.split("=")[1] });
  }

  handlePlanSelect = (plan, index) => () => {
    this.props.setSelectedPlan(plan, index);

    if (plan === "kingkong") {
      this.props.reset("homeowner-custom-plan");
    }
  };
  gettype = (type) => {
    this.props.setplantype(type);
  };

  handleServiceCall = (price) => {
    this.props.setServiceCallFee(price);
  };

  handleCheckout = (annualPrice, monthlyPrice, policyInfo, addonsPrice) =>
    this.props.navigate("policyRenew/checkout", {
      state: {
        totalAmount:
          this.props.plantype === "monthly" ? monthlyPrice : annualPrice,
        policyInfo: policyInfo,
        paymentType: this.props.plantype,
        monthlyPrice,
        annualPrice,
        addonsPrice:
          this.props.plantype === "monthly"
            ? addonsPrice.monthly
            : addonsPrice.annual,
      },
    });

  render() {
    const { addons, featureCount, location, addonsPrice } = this.props;
    const policyInfo = location.state ? location.state.policyInfo : "";
    localStorage.setItem("policyID", policyInfo.id);
    const annualPrice = policyInfo.Total_Renewal_Price;
    const monthlyPrice =
      policyInfo.Monthly_Renewal_Price ||
      Number(Number(annualPrice) / 12).toFixed();
    return (
      <StyledSection>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} container spacing={2}>
            <Grid item xs={8}>
              <Addons items={addons} />
            </Grid>
            <Grid item xs={4}>
              <PlanTotal
                onSubmit={() =>
                  this.handleCheckout(
                    addonsPrice.annual + annualPrice,
                    Number(addonsPrice.monthly) + Number(monthlyPrice),
                    policyInfo,
                    addonsPrice
                  )
                }
                annualPrice={addonsPrice.annual + annualPrice}
                monthlyPrice={
                  Number(addonsPrice.monthly) + Number(monthlyPrice || 0)
                }
                disabled={featureCount < 10}
                subscriptionType={this.gettype}
              />
            </Grid>
            <PolicyTotal
              planTotalMonthly={monthlyPrice}
              planTotalAnnual={annualPrice}
              optionTotalMonthly={addonsPrice.monthly}
              optionTotalAnnual={addonsPrice.annual}
              planType={this.props.plantype}
            />
          </Grid>
        </Grid>
      </StyledSection>
    );
  }
}

const StyledSection = styled(Section)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  color: theme.palette.text.primary,
  overflowY: "scroll",
}));

const mapStateToProps = (state) => ({
  addonsPrice: getRenewalPrice(state),
  selectedPlan: getConsumerOrder(state).planName,
  plans: getConsumerPlanOptions(state),
  features: getConsumerPlanFeatures(state),
  addons: getConsumerPlanAddons(state),
  total: getConsumerPlanTotal(state),
  featureCount: getConsumerFeatureCount(state),
  serviceCallFee: getServiceCallFee(state),
  plantype: getplantype(state),
});

const mapDispatchToProps = {
  loadPlanFeatures,
  loadPlanOptions,
  loadPlanAddons,
  setSelectedPlan,
  setServiceCallFee,
  setplantype,
  reset,
};

export default compose(
  withTranslation("homeowner"),
  connect(mapStateToProps, mapDispatchToProps)
)(RenewPolicy);
