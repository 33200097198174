import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { withTranslation } from "react-i18next";
import { Link, Navigate } from "react-router-dom";

import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { register } from "store/actions/auth";
import { getIsSignedIn } from "store/reducers";
import AuthService from "services/auth";
import Button from "components/common/button";
import * as Validator from "helpers/validators";
import { renderTextField } from "helpers/form";
import * as Styled from "../styled";

class Register extends React.Component {
  handleRegistration = (promise) => {
    this.props.register(promise);
  };

  handleSubmit = ({ email, password }) => {
    this.handleRegistration(AuthService.signUp(email, password));
  };

  handleGoogleAuth = () => {
    this.handleRegistration(AuthService.googleSignIn());
  };

  handleFacebookAuth = () => {
    this.handleRegistration(AuthService.facebookSignIn());
  };

  render() {
    const { t, handleSubmit, isSignedIn, location } = this.props;

    if (isSignedIn) {
      //const referrer = location.state ? location.state.referrer : '/';
      // return <Redirect to={referrer} />;
      const referrer = location.state
        ? location.state.type === "orderplan"
          ? "/homeowner/order"
          : location.state.referrer
        : "/";
      return (
        <Navigate
          to={{ pathname: referrer, state: { redirect: "checkout" } }}
        />
      );
      // <Redirect to={{
      //   pathname: referrer,
      //   redirect:'checkout'
      // }} />
    }

    return (
      <Styled.Container>
        <Styled.Card>
          <CardHeader
            classes={{ root: "card-header" }}
            title={t("nameFull")}
            subheader={t("createNewAccount")}
          />
          <CardContent>
            <form onSubmit={handleSubmit(this.handleSubmit)}>
              <Grid container spacing={2} justify="space-around">
                <Grid item xs={12}>
                  <Field
                    name="email"
                    label={t("email")}
                    component={renderTextField}
                    validate={Validator.isRequired}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="password"
                    label={t("password")}
                    component={renderTextField}
                    validate={Validator.isRequired}
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="confirmPassword"
                    label={t("confirmPassword")}
                    component={renderTextField}
                    validate={[Validator.isRequired, Validator.matchPassword]}
                    type="password"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button type="submit" color="primary" fullWidth>
                    {t("register")}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body2" align="center">
                    {t("useExistingAccount")}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleGoogleAuth}
                    color="secondary"
                    fullWidth
                  >
                    Google
                  </Button>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Button
                    onClick={this.handleFacebookAuth}
                    color="secondary"
                    fullWidth
                  >
                    Facebook
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="caption" align="center">
                    {t("alreadyHaveAccount")}{" "}
                    <Link to="/auth/signin">{t("signIn")}</Link>
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Styled.Card>
      </Styled.Container>
    );
  }
}

const mapStateToProps = (state) => ({
  isSignedIn: getIsSignedIn(state),
});

export default compose(
  withTranslation(),
  reduxForm({
    form: "registration",
  }),
  connect(mapStateToProps, { register })
)(Register);
