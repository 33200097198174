import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Hide from "@mui/material/Hidden";
import { withTranslation } from "react-i18next";

import * as Styled from "./styled";

const PoliyTotal = ({
  t,
  planTotalMonthly,
  planTotalAnnual,
  optionTotalMonthly,
  optionTotalAnnual,
  planType,
}) => (
  <Styled.Wrapper>
    <Grid container spacing={2} style={{ margin: "-8px" }}>
      <Hide smDown>
        <Grid item sm={2} style={{ padding: "8px" }}>
          <Typography color="textSecondary" variant="h3">
            {`${t("totalDue")}:`}
          </Typography>
        </Grid>
      </Hide>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("policy")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${planType === "monthly" ? planTotalMonthly : planTotalAnnual}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={1} className="center" style={{ padding: "8px" }}>
        <Typography className="header" variant="h5" color="textSecondary">
          +
        </Typography>
      </Grid>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("options")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          ${planType === "monthly" ? optionTotalMonthly : optionTotalAnnual}
        </Typography>
      </Grid>
      <Grid item sm={1} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography className="header" variant="h5" color="textSecondary">
          =
        </Typography>
      </Grid>
      <Grid item sm={2} xs={3} className="center" style={{ padding: "8px" }}>
        <Typography
          className="header"
          variant="subtitle1"
          color="textSecondary"
        >
          {t("total")}
        </Typography>
        <Typography className="header" variant="h5" color="inherit">
          $
          {planType === "monthly"
            ? Number(
                Number(planTotalMonthly) + Number(optionTotalMonthly)
              ).toFixed()
            : Number(
                Number(planTotalAnnual) + Number(optionTotalAnnual)
              ).toFixed()}
        </Typography>
      </Grid>
    </Grid>
  </Styled.Wrapper>
);

export default withTranslation("common")(PoliyTotal);
