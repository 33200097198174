import { FUNCTION_API, AuthorizePaymentV3 } from "constants/firebase";

export default class PaymentService {
  static payment(paymentDetails, totalAmount) {
    return fetch(`${AuthorizePaymentV3}/pay`, {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/JSON",
        // "Authorization": `Zoho-oauthtoken ${access_token}`
      }),
      body: JSON.stringify({
        data: {
          payment_details: {
            card_number: paymentDetails.card_number,
            expiry_date: paymentDetails.expiry_date,
            card_code: paymentDetails.card_code,
            amount: totalAmount,
          },
          billing_address: {
            first_name: paymentDetails.first_name,
            last_name: paymentDetails.last_name,
            address: paymentDetails.address,
            city: paymentDetails.city,
            state: paymentDetails.state,
            country: "USA",
            zip: paymentDetails.zip,
          },
        },
      }),
    })
      .then((res) => res.json())
      .catch((e) => console.log(e));
  }
  static refund(details) {
    return fetch(`${AuthorizePaymentV3}/refund`, {
      method: "POST",
      headers: new Headers({ "Content-Type": "application/JSON" }),
      body: JSON.stringify(details),
    });
  }
}
